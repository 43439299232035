import http from "./httpService";

export function getCotizaciones() {
  return http.get("/cotizaciones");
}

export function getCotizacionUpt(id) {
  return http.get("/cotizaciones/" + id);
}

export function postCotizaciones(body) {
  return http.post("/cotizaciones", body);
}

export function updateCotizaciones(body) {
  return http.put("/cotizaciones", body);
}

import React, { Component, Fragment } from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import _ from "lodash";
import * as XLSX from "xlsx";
import auth from "../../services/authService";
import { trackPromise } from "react-promise-tracker";
//Importar Componentes
import NavBar from "./navBar";
import LoadingComp from "../tools/loadingComp";
import NewCall from "../forms/newCall";
import InfoCall from "../forms/infoCall";
import InfoTable from "../views/infoTable";
import InfoGrid from "../views/infoGrid";
import InfoOptions from "../views/infoOptions";
import InfoColor from "../views/infoColor";
import NewFrap from "../forms/newFrap";
//Importar Servicios
import {
  getCalls,
  updateCall,
  exportCalls,
} from "./../../services/llamadaService";
import { postFrap } from "../../services/frapService";

class Llamadas extends Component {
  state = {
    data: [],
    dataSumm: [],
    view: "table",
    newModal: false,
    infoModal: false,
    frapModal: false,
    infoType: "u",
    infoFrap: false,
    user: {},
    selectedCall: {},
    filters: {
      registros: 20,
      busqueda: "",
    },
    layout: [
      {
        name: "idLlamadas",
        label: "Llamada: #",
      },
      {
        name: "fecha",
        label: "",
      },
      { name: "NombreFinal", label: "Cliente: " },
      { name: "Tipo", label: "Tipo: " },
      { name: "idFrap", label: "Frap: " },
      { name: "hora", label: "Hora: " },
    ],
    headers: [
      {
        name: "idLlamadas",
        head: "Id",
        sort: "desc",
      },
      {
        name: "fecha",
        head: "Fecha",
        sort: "desc",
      },
      {
        name: "NombreFinal",
        head: "Cliente",
        sort: "desc",
      },
      {
        name: "Paciente",
        head: "Paciente",
        sort: "desc",
      },
      {
        name: "Tipo",
        head: "Tipo",
        sort: "desc",
      },
      {
        name: "idFrap",
        head: "Frap",
        sort: "desc",
      },
      {
        name: "hora",
        head: "Hora",
        sort: "desc",
      },
      {
        name: "Status",
        head: "Status",
        sort: "desc",
        content: (call) => (
          <span className={"label-" + call.Status.toLowerCase()}>
            {call.Status}
          </span>
        ),
      },
    ],
    sortBy: "",
  };

  componentDidMount = async () => {
    try {
      const { status, data } = await trackPromise(getCalls());
      const user = auth.getCurrentUser();
      if (status === 200) {
        const dataSumm = data.slice(0, this.state.filters.registros);
        this.setState({ data, dataSumm, user });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Verifica tu conexión a internet e intenta de nuevo",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  sortColumn = (name, sort, key) => {
    const { data, headers, filters } = this.state;
    const registros = data.slice(0, filters.registros);
    const order = sort === "desc" ? "asc" : "desc";
    const dataOrder = _.orderBy(registros, name, order);
    const updateHeader = [...headers];
    updateHeader[key].sort = order;
    this.setState({ dataSumm: dataOrder, headers: updateHeader });
  };

  changeFilter = ({ currentTarget: input }) => {
    const { data } = this.state;
    const filters = { ...this.state.filters };
    filters[input.name] = input.value;
    const dataSumm = filters.busqueda
      ? data.filter(
          (w) =>
            w.NombreFinal.toLowerCase().includes(
              filters.busqueda.toLowerCase()
            ) ||
            w.idLlamadas.toString().startsWith(filters.busqueda) ||
            w.idFrap?.toString().startsWith(filters.busqueda)
        )
      : data
      ? data.slice(0, filters.registros)
      : [];

    this.setState({ dataSumm, filters });
  };

  changeView = (view) => {
    this.setState({ view });
  };

  showModal = () => {
    const newModal = !this.state.newModal;
    this.setState({ newModal });
  };

  showFrapModal = () => {
    const frapModal = !this.state.frapModal;
    this.setState({ frapModal });
  };

  insertCall = (call) => {
    const { data, filters } = this.state;
    data.unshift(call);

    const dataSumm = data.slice(0, filters.registros);
    const selectedCall = (({
      idLlamadas,
      NombreFinal,
      idCliente,
      Tipo,
      Status,
    }) => ({
      idLlamadas,
      NombreFinal,
      idCliente,
      Tipo,
      Status,
    }))(call);
    this.setState({
      data,
      dataSumm,
      infoType: "n",
      selectedCall: selectedCall,
      infoModal: true,
      newModal: false,
    });
  };

  loadInfo = (data) => {
    this.showInfoModal();
    const infoType = data.newReg ? "n" : "u";
    const selectedCall = (({
      Caller,
      Tipo_Servicio,
      Paciente,
      Costo,
      EdadP,
      Peso,
      TelefonoC,
      Diagnostico,
      Lugar_Origen,
      Lugar_Destino,
      lugar_destino_otro,
      Area_Llegada,
      Equipo,
      Description,
      Suceso,
      Status,
      idLlamadas,
      NombreFinal,
      Tipo,
      idFrap,
      idCliente,
    }) => ({
      Caller,
      Tipo_Servicio,
      Paciente,
      Costo,
      EdadP,
      Peso,
      TelefonoC,
      Diagnostico,
      Lugar_Origen,
      Lugar_Destino,
      lugar_destino_otro,
      Area_Llegada,
      Equipo,
      Description,
      Suceso,
      Status,
      idLlamadas,
      NombreFinal,
      Tipo,
      idFrap,
      idCliente,
    }))(data);

    const { filters } = this.state;
    filters["busqueda"] = "";

    this.setState({ selectedCall, infoType, filters });
  };

  updateInfo = (info) => {
    const { data, filters } = this.state;
    const id = info.idLlamadas;
    const i = _.findIndex(data, { idLlamadas: id });
    info["idFrap"] = data[i].idFrap;
    info["fecha"] = data[i].fecha;
    info["hora"] = data[i].hora;
    info["idCliente"] = data[i].idCliente;

    if (info.Tipo === "PARTICULAR" && info.Paciente !== "") {
      info.NombreFinal = info.Paciente;
    }

    data[i] = info;

    const dataSumm = data.slice(0, filters.registros);

    this.setState({ data, dataSumm });
    this.showInfoModal();
  };

  registerFrap = (info) => {
    Swal.fire({
      title: "¿Quieres tomar esta llamada?",
      text: "Se le asignará un número de FRAP",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#10B981",
      cancelButtonColor: "#EF4444",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data, filters } = this.state;
          const id = info.idLlamadas;
          const i = _.findIndex(data, { idLlamadas: id });
          const fecha = data[i].fecha;
          const motivo =
            info["Diagnostico"] === "" ? info["Suceso"] : info["Diagnostico"];
          info["Fecha_Frap"] =
            fecha.substr(6, 4) +
            "-" +
            fecha.substr(3, 2) +
            "-" +
            fecha.substr(0, 2);
          info["Hora_Llamada"] = data[i].hora;
          info["idCliente"] = data[i].idCliente;
          info["id_Llamada"] = info["idLlamadas"];
          info["Lugar_Inicio"] =
            info["Area_Llegada"] !== ""
              ? info["Area_Llegada"]
              : info["Lugar_Origen"];
          delete info["idLlamadas"];
          delete info["Area_Llegada"];
          delete info["Lugar_Origen"];
          delete info["Diagnostico"];
          delete info["Suceso"];
          const sinCosto = [
            "AREA PROTEGIDA",
            "EVENTO",
            "AREA PROTEGIDA POR EVENTO",
            "MEMBRESIAS FAMILIARES",
          ];
          info["Status_Pago"] = sinCosto.includes(data[i].Tipo)
            ? "SIN COSTO"
            : "PTE PAGO";
          info["id_Empleado"] = 0;
          info["Motivo"] = motivo;
          info["Tipo_Cliente"] = "";
          info["Edad"] = info["EdadP"];
          delete info["EdadP"];

          const res = await trackPromise(postFrap(info));
          if (res.status === 200) {
            const nFrap = "A" + (parseInt(res.data.insertId) + 10000);
            const body = {
              idLlamadas: id,
              idFrap: nFrap,
              Status: "CERRADO",
            };
            await trackPromise(updateCall(body)); //Update call table

            data[i].idFrap = nFrap;
            data[i].Status = "CERRADO";
            const dataSumm = data.slice(0, filters.registros);

            this.setState({ data, dataSumm });
            this.showInfoModal();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Frap agregado con éxito",
              showConfirmButton: false,
              timer: 1800,
              toast: true,
            });
            info["Tipo_Cliente"] = data[i].Tipo;
            info["idTransaction"] = res.data.insertId;
            this.setState({ infoFrap: info });
            this.showFrapModal();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Ocurrió un error cargando los datos",
              showConfirmButton: false,
              timer: 1800,
              toast: true,
            });
          }
        } catch (err) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Ocurrió un error cargando los datos",
            showConfirmButton: false,
            timer: 1800,
            toast: true,
          });
        }
      }
    });
  };

  handleExport = async () => {
    try {
      if (this.state.user.tipo !== "Paramedico") {
        const { status, data } = await trackPromise(exportCalls());
        if (status === 200) {
          let wb = XLSX.utils.book_new();
          let ws = XLSX.utils.json_to_sheet(data);

          XLSX.utils.book_append_sheet(wb, ws, "Llamadas");
          XLSX.writeFile(wb, "Llamadas.csv");
        }
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  showInfoModal = () => {
    const infoModal = !this.state.infoModal;
    this.setState({ infoModal, selectedCall: {}, infoType: "u" });
  };

  render() {
    const {
      dataSumm,
      filters,
      headers,
      view,
      newModal,
      infoModal,
      frapModal,
      selectedCall,
      infoType,
      infoFrap,
      layout,
      user,
    } = this.state;

    return (
      <Fragment>
        {frapModal && user.tipo !== "Administracion" && (
          <NewFrap
            onCancel={this.showFrapModal}
            data={infoFrap}
            type={"c"}
            user={user}
          />
        )}
        {newModal && !["Administracion", "Paramedico"].includes(user.tipo) && (
          <NewCall
            onCancel={this.showModal}
            onInsert={this.insertCall}
            user={user}
          />
        )}
        {infoModal && (
          <InfoCall
            onCancel={this.showInfoModal}
            onUpdate={this.updateInfo}
            onFrap={this.registerFrap}
            info={selectedCall}
            type={infoType}
            user={user}
          />
        )}
        <LoadingComp />
        <NavBar page={this.props.match.path} />
        <div className="p-5 h-screen">
          <h1 className="block text-center text-3xl text-blue-700">
            Módulo de Llamadas
          </h1>
          <InfoColor
            view={view}
            colors={[
              { color: "green", label: "ACTIVO" },
              { color: "yellow", label: "CERRADO" },
              { color: "red", label: "CANCELADO" },
            ]}
          />

          <InfoOptions
            view={view}
            filters={filters}
            pholder={"Llamada, FRAP o cliente"}
            btnText={"Nueva Llamada"}
            btnColor={"btn-secondary"}
            showModal={this.showModal}
            changeFilter={this.changeFilter}
            changeView={this.changeView}
            handleExport={this.handleExport}
          />

          <InfoTable
            headColor={"bg-blue-100"}
            headers={headers}
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            sortColumn={this.sortColumn}
            view={view}
          />

          <InfoGrid
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            layout={layout}
            view={view}
          />
        </div>
      </Fragment>
    );
  }
}

export default Llamadas;

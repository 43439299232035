import React, { Component } from "react";
import TimePicker from "react-time-picker";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FormRules extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    //Validate before sending de information
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateField = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    if (!error) return null;
    return error.details[0].message;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateField(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    const dataT = input.getAttribute("data");
    const val =
      dataT === "number"
        ? parseInt(input.value)
        : dataT === "float"
        ? parseFloat(input.value)
        : input.value;
    data[input.name] = val;

    if (
      input.name === "tipo" ||
      (input.name === "Tipo_Cliente" && data["idCliente"] !== 0)
    ) {
      data["idCliente"] = 0;
    }

    const servicioUrgencia = ["1", "9", "15", "16"];
    if (
      input.name === "Tipo_Cliente" &&
      servicioUrgencia.includes(input.value)
    ) {
      data["Status_Pago"] = input.value === "15" ? "PTE PAGO" : "SIN COSTO";
      data["Status_Factura"] = "SIN FACTURA";
      data["Tipo_Servicio"] = "URGENCIA";
      data["Costo"] = 0;
    }

    if (input.name === "Tipo_Cliente" && input.value === "2") {
      data["Tipo_Servicio"] = "URGENCIA ESCOLAR";
    }

    if (
      input.name === "Traslado" &&
      (input.value === "NO" || input.value === "PROPIOS MEDIOS")
    ) {
      data["Lugar_Destino"] = "SE QUEDA EN EL LUGAR";
    }

    if (
      input.name === "Status_Pago" &&
      (input.value === "PTE PAGO" || input.value === "")
    ) {
      data["validadoger"] = 0;
      data["Validado"] = 0;
      data["Fecha_Admin"] = "";
      data["fecha_gerencia"] = "";
    }

    /*********************Tabluador*******************/
    const { prevInfo } = this.state;
    if (input.name === "tipoServicio" && this.state.action === "prec") {
      const precios = [...this.state.precios];
      const tipoSel = precios.filter((item) => {
        return item.servicio === input.value;
      });
      if (tipoSel.length > 0) {
        data["newVal"] = parseInt(tipoSel[0].costo);
      }
    }
    if (
      input.name === "Tipo_Cliente" &&
      input.value === "3" &&
      prevInfo !== undefined
    ) {
      const precio = this.handleTabulador(data["Tipo_Servicio"], 0);
      data["Costo"] = parseFloat(precio.toFixed(2));
    }

    if (input.name === "Tipo_Servicio" && input.value !== "") {
      const precio = this.handleTabulador(
        input.value,
        parseInt(
          prevInfo === undefined ? data["idCliente"] : prevInfo.idCliente
        )
      );
      data["Costo"] = parseFloat(precio.toFixed(2));
    }

    if (
      input.name === "idCliente" &&
      data["Tipo_Servicio"] !== "" &&
      data["Tipo_Servicio"] !== undefined
    ) {
      const precio = this.handleTabulador(
        data["Tipo_Servicio"],
        parseInt(input.value)
      );
      data["Costo"] = parseFloat(precio.toFixed(2));
    }

    if (
      data["idCliente"] !== 0 &&
      data["tipoCliente"] !== 0 &&
      data["tipoServicio"] !== "" &&
      this.state.action === "tab" &&
      !["disct", "tax", "fix"].includes(input.name)
    ) {
      const { listas, tabulador, allCust } = this.state;
      const tipoSel = listas.filter((item) => {
        return (
          item.lista === "Tipo_Cliente" &&
          item.val === parseInt(data.tipoCliente)
        );
      });
      const customer = listas.filter((item) => {
        return (
          item.lista === tipoSel[0].vis && item.val === parseInt(data.idCliente)
        );
      });
      const selTab = tabulador.filter((item) => {
        return (
          item.cliente === (allCust ? "ALL" : customer[0].vis) &&
          item.tipo === tipoSel[0].vis &&
          item.servicio === data["tipoServicio"]
        );
      });
      data["tabtc"] = tipoSel[0].vis;
      data["tabc"] = allCust ? "ALL" : customer[0].vis;
      if (selTab.length > 0) {
        data["disct"] = selTab[0].disct * 100;
        data["tax"] = selTab[0].tax;
        data["fix"] = selTab[0].fix;
        data["tabF"] = "E";
        data["tabid"] = selTab[0].id;
      } else {
        data["disct"] = 0;
        data["tax"] = 0;
        data["fix"] = 0;
        data["tabF"] = "C";
        data["tabid"] = 0;
      }
    }

    /*********************Tabluador*******************/

    if (
      input.name === "delVal" &&
      this.state.action === "ed" &&
      this.state.data.lista !== "clientes"
    ) {
      const vis = this.state.listas.filter(
        (item) =>
          item.lista === this.state.data.lista &&
          item.val === (isNaN(item.val) ? input.value : parseInt(input.value))
      );
      data["newVal"] = vis[0].vis;
    }

    if (
      input.name === "idCliente" &&
      this.state.action === "ed" &&
      this.state.data.lista === "clientes"
    ) {
      const tipo_cliente = this.state.listas.filter(
        (item) =>
          item.lista === "Tipo_Cliente" &&
          item.val === parseInt(this.state.data.delVal)
      );
      const vis = this.state.listas.filter(
        (item) =>
          item.lista === tipo_cliente[0].vis &&
          item.val === parseInt(input.value)
      );

      data["newVal"] = vis[0].vis;
    }

    this.setState({ data, errors });
  };

  handleTabulador = (servicio, cliente) => {
    const { precios, data, listas, tabulador, prevInfo } = this.state;
    const pr = precios.filter((sr) => sr.servicio === servicio);
    const tipo = prevInfo === undefined ? data["Tipo_Cliente"] : prevInfo.Tipo;
    let costo = pr.length === 1 ? pr[0].costo : 0;
    if (cliente > 0) {
      const tipoCliente =
        prevInfo === undefined
          ? listas.filter(
              (item) => item.lista === "Tipo_Cliente" && item.val === tipo
            )
          : listas.filter(
              (item) => item.lista === "Tipo_Cliente" && item.vis === tipo
            );
      const clienteName = listas.filter(
        (item) => item.lista === tipoCliente[0].vis && item.val === cliente
      );

      const adj = this.handleSearchLevels(
        tipoCliente[0].vis,
        clienteName[0].vis,
        servicio,
        tabulador
      );

      if (adj !== undefined) {
        if (adj[0].fix === 0 && (adj[0].tax > 0 || adj[0].disct > 0)) {
          costo = costo * (adj[0].tax > 0 ? adj[0].tax : 1);
          costo = costo * (adj[0].disct > 0 ? 1 - adj[0].disct : 1);
        } else {
          costo = adj[0].fix;
        }
      }
    }

    return costo;
  };

  handleSearchLevels = (tipo, cliente, servicio, tabulador) => {
    const lvl0 = tabulador.filter(
      (item) =>
        item.tipo === tipo &&
        item.cliente === cliente &&
        item.servicio === servicio
    );

    const lvl1 = tabulador.filter(
      (item) =>
        item.tipo === tipo &&
        item.cliente === cliente &&
        item.servicio === "ALL"
    );
    const lvl2 = tabulador.filter(
      (item) =>
        item.tipo === tipo &&
        item.cliente === "ALL" &&
        item.servicio === servicio
    );
    const lvl3 = tabulador.filter(
      (item) =>
        item.tipo === tipo && item.cliente === "ALL" && item.servicio === "ALL"
    );

    const arr = [lvl0, lvl1, lvl2, lvl3];
    const i = arr.find((x) => x.length === 1);
    //console.log(i);
    return i;
  };

  handleCheckBox = (arr, val) => {
    const data = { ...this.state.data };
    if (data[arr].includes(val)) {
      const index = data[arr].indexOf(val);
      data[arr].splice(index, 1);
    } else {
      data[arr].push(val);
    }

    this.setState({ data });
  };

  handleDateTime = (name, value) => {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  handleToggleReg = (name) => {
    const currVal = this.state.data[name];
    const data = { ...this.state.data };
    data[name] = currVal === 1 ? 0 : 1;
    this.setState({ data });
  };

  handleToggle = (name) => {
    const currVal = this.state.data[name];
    const data = { ...this.state.data };
    const totalPagado =
      (data["tblpagos"][0]["monto"] === ""
        ? 0
        : parseInt(data["tblpagos"][0]["monto"])) +
      (data["tblpagos"][1]["monto"] === ""
        ? 0
        : parseInt(data["tblpagos"][1]["monto"])) +
      (data["tblpagos"][2]["monto"] === ""
        ? 0
        : parseInt(data["tblpagos"][2]["monto"])) +
      (data["tblpagos"][3]["monto"] === ""
        ? 0
        : parseInt(data["tblpagos"][3]["monto"]));

    if (
      name === "validadoger" &&
      currVal === 0 &&
      this.state.user === "Gerencia"
    ) {
      data["Validado"] = 1;
    }

    if (
      name === "Validado" &&
      ["Gerencia", "Administracion"].includes(this.state.user)
    ) {
      data[name] = currVal === 1 ? 0 : 1;
    }

    if (name === "validadoger" && ["Gerencia"].includes(this.state.user)) {
      data[name] = currVal === 1 ? 0 : 1;
    }

    if (
      data["Status_Pago"] === "PTE PAGO" ||
      data["Status_Pago"] === "" ||
      data["Status_Factura"] === "POR FACTURAR" ||
      data["Status_Factura"] === "" ||
      (data["Status_Pago"] === "PAGADO" && totalPagado <= 0)
    ) {
      data["validadoger"] = 0;
      data["Validado"] = 0;
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Actualizar Estatus de Pago/ Estatus de Factura",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    const currDate = yyyy + "-" + mm + "-" + dd;

    data["Fecha_Admin"] = data["Validado"] === 1 ? currDate : "";
    data["fecha_gerencia"] = data["validadoger"] === 1 ? currDate : "";
    this.setState({ data });
  };

  setTime = (name) => {
    const data = { ...this.state.data };
    const now = new Date();
    const time = now.getHours() + ":" + now.getMinutes();

    data[name] = time;
    this.setState({ data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    //console.log(errors);
    if (errors) {
      return;
    }
    this.doSubmit();
  };

  handleSubmitPdf = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    //console.log(errors);
    if (errors) {
      return;
    }
    this.createPdf();
    this.doSubmit();
  };

  /* Basic error handlers */

  renderInput(
    name,
    label,
    type = "text",
    pholder = "",
    diss = this.state.diss,
    errorMsg = "Completar Campo"
  ) {
    const { data, errors } = this.state;
    return (
      <div>
        <label className="block mb-1 text-sm">{label}</label>
        <input
          className="ext-xs text-input"
          name={name}
          value={data[name]}
          placeholder={pholder}
          type={type}
          data={type}
          disabled={diss}
          onChange={this.handleChange}
        />
        {errors[name] ? (
          <div className="text-sm text-red-500">*{errorMsg}</div>
        ) : null}
      </div>
    );
  } //Fin de render Input Normal

  renderInputH(
    name,
    label,
    type = "text",
    pholder = "",
    wl = "1/6",
    wi = "5/6",
    mand = false,
    diss = this.state.user === "Facturacion" &&
    ["Factura"].includes(name) &&
    this.state.data.Validado !== 1 &&
    this.state.data.validadoger !== 1
      ? false
      : this.state.diss,
    errorMsg = "Completar Campo"
  ) {
    const { data, errors } = this.state;
    return (
      <div className="flex flex-wrap w-full">
        <label className={"self-center w-full text-sm pb-1 lg:w-" + wl}>
          {label}
        </label>
        <input
          className={
            "w-full text-xs text-input lg:w-" +
            wi +
            (mand ? " border border-red-400" : "")
          }
          name={name}
          value={
            type === "number"
              ? data[name] === 0
                ? name === "Costo"
                  ? "0"
                  : ""
                : Number(data[name]).toString()
              : data[name]
          }
          placeholder={pholder}
          type={type}
          data={type}
          disabled={diss}
          onChange={this.handleChange}
        />
        {errors[name] ? (
          <div className="text-sm text-red-500">*{errorMsg}</div>
        ) : null}
      </div>
    );
  } //Fin de render Input Horizontal

  renderTextArea(
    name,
    label,
    type = "text",
    pholder = "",
    wl = "1/6",
    wi = "5/6",
    rows = "4",
    diss = this.state.diss,
    errorMsg = "Completar Campo"
  ) {
    const { data, errors } = this.state;
    return (
      <div className="flex flex-wrap w-full">
        <label className={"self-center w-full text-sm pb-1 lg:w-" + wl}>
          {label}
        </label>
        <textarea
          className={"w-full text-xs text-input lg:w-" + wi}
          name={name}
          value={data[name]}
          placeholder={pholder}
          type={type}
          data={type}
          rows={rows}
          disabled={diss}
          onChange={this.handleChange}
        />
        {errors[name] ? (
          <div className="text-sm text-red-500">*{errorMsg}</div>
        ) : null}
      </div>
    );
  } //Fin de render Text Area

  renderRadio(name, options, wl = "1/4", errorMsg = "Completar Campo") {
    const { data, errors } = this.state;
    return (
      <div className="w-full flex flex-wrap justify-center">
        {options.map((e, i) => (
          <div key={i} className={"w-" + wl + " text-center"}>
            <label className="text-sm inline-flex items-center">
              <input
                type="radio"
                className="cursor-pointer"
                name={name}
                value={e.value}
                onChange={this.handleChange}
                checked={data[name] === e.value ? true : false}
              />
              <span className="ml-2">{e.label}</span>
            </label>
          </div>
        ))}
        {errors[name] ? (
          <div className="text-sm text-red-500">*{errorMsg}</div>
        ) : null}
      </div>
    );
  } //Fin de render Radio

  renderCheckBoxes(name, label, options, wl = "1/6", wi = "5/6") {
    const { data } = this.state;
    return (
      <div className="flex flex-wrap w-full">
        <label className={"self-center w-full text-sm pb-1 lg:w-" + wl}>
          {label}
        </label>
        <div className={"flex flex-wrap w-full lg:w-" + wi}>
          {options.map((item, i) => (
            <span className="w-1/3" key={i}>
              <FontAwesomeIcon
                className={"mr-2 cursor-pointer text-purple-500"}
                icon={[
                  "far",
                  data[name].includes(item) ? "check-square" : "square",
                ]}
                onClick={
                  this.state.diss ? null : () => this.handleCheckBox(name, item)
                }
              />
              <span className="text-sm">{item}</span>
            </span>
          ))}
        </div>
      </div>
    );
  } //Fin de render checkbox

  renderSelect(
    name,
    label,
    type,
    options,
    wl = "1/6",
    wi = "5/6",
    mand = false,
    diss = this.state.user === "Facturacion" &&
    ["Status_Pago", "Status_Factura"].includes(name) &&
    this.state.data.Validado !== 1 &&
    this.state.data.validadoger !== 1
      ? false
      : this.state.diss,
    errorMsg = "Completar Campo"
  ) {
    const { data, errors } = this.state;
    return (
      <div className="flex flex-wrap w-full">
        <label className={"self-center w-full text-sm pb-1 lg:w-" + wl}>
          {label}
        </label>
        <select
          name={name}
          data={type}
          disabled={diss}
          value={data[name]}
          className={
            "w-full text-xs text-input lg:w-" +
            wi +
            (mand ? " border border-red-400" : "")
          }
          onChange={this.handleChange}
        >
          <option value={""}></option>
          {options.map((e, i) => (
            <option key={i} value={e.val}>
              {e.vis}
            </option>
          ))}
        </select>

        {errors[name] ? (
          <div className="text-sm text-red-500">*{errorMsg}</div>
        ) : null}
      </div>
    );
  } //Fin de render Input Select

  renderTimePicker(
    name,
    label,
    wl = "1/6",
    wi = "5/6",
    tipo = "Regular",
    sysTime = true,
    errorMsg = "Completar Campo"
  ) {
    const { data, errors, diss } = this.state;
    return (
      <div className="flex flex-wrap w-full">
        <label
          className={
            "self-center w-full text-sm " +
            (sysTime && diss === false ? "text-purple-500" : "") +
            " pb-1 lg:w-" +
            wl
          }
        >
          <span
            className={sysTime ? "cursor-pointer underline" : ""}
            onClick={sysTime ? () => this.setTime(name) : null}
          >
            {label}
          </span>
        </label>
        <div className={"w-full text-xs text-input lg:w-" + wi}>
          <TimePicker
            className="w-full text-center"
            value={data[name] === "00:00:00" ? "" : data[name]}
            onChange={(value) => this.handleDateTime(name, value)}
            onKeyDown={
              tipo !== "Paramedico"
                ? (e) => {}
                : (e) => {
                    e.preventDefault();
                  }
            }
            disableClock={true}
            autoFocus={false}
            clearIcon={null}
            format={"HH:mm"}
          />
        </div>

        {errors[name] ? (
          <div className="text-sm text-red-500">*{errorMsg}</div>
        ) : null}
      </div>
    );
  }

  renderToggle(name, label) {
    const { data } = this.state;
    return (
      <div className="flex items-left justify-left w-full">
        <label className="flex items-left cursor-pointer text-sm">
          <div className="relative mt-1">
            <input type="checkbox" className="sr-only" />

            <div className="w-10 h-4 bg-gray-200 rounded-full shadow-inner"></div>

            <div
              className={
                "absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition toggle-" +
                (data[name] !== 1 ? "regular" : "active")
              }
              onClick={() =>
                name === "Factura"
                  ? this.handleToggleReg(name)
                  : this.handleToggle(name)
              }
            ></div>
          </div>

          <div className="ml-3">
            <span>{label}</span>
          </div>
        </label>
      </div>
    );
  }
}

export default FormRules;

import http from "./httpService";

export function getFraps() {
  return http.get("/fraps");
}

export function postFrap(data) {
  const body = { ...data };
  delete body.Tipo_Cliente;
  return http.post("/fraps", body);
}

export function validateFrap(frap) {
  return http.get("/fraps/" + frap);
}

export function pullFrap(frap) {
  return http.get("/fraps/pull/" + frap);
}

export function exportFraps() {
  return http.get("/fraps/export/file/excel");
}

export function updateFrap(data) {
  const body = { ...data };
  delete body.Tipo_Cliente;
  return http.put("/fraps", body);
}

export function validateFraps(data) {
  return http.put("/fraps/validate", data);
}

import React from "react";
import FormRules from "./formRules";
import SignatureCanvas from "react-signature-canvas";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Firmas extends FormRules {
  constructor(props) {
    super(props);
    this.state = {
      data: props.prevDetails
        ? props.prevDetails
        : {
            entrega_paciente: [],
            recibe_paciente: [],
            firma_paciente: [],
            firma_lugar: [],
            observaciones: "",
          },
      errors: {},
      diss: props.disabled,
      signChange: {
        ep: false,
        rp: false,
        fp: false,
        fl: false,
      },
      //listasTx: props.listasTx ? props.listasTx : [],
    };
  }

  schema = {
    entrega_paciente: Joi.optional(),
    recibe_paciente: Joi.optional(),
    firma_paciente: Joi.optional(),
    observaciones: Joi.optional(),
  };

  signEntrega = {};
  signRecibe = {};
  signPaciente = {};
  signLugar = {};

  componentDidMount = () => {
    const data = { ...this.state.data };
    this.signEntrega.fromDataURL(data["entrega_paciente"]);
    this.signRecibe.fromDataURL(data["recibe_paciente"]);
    this.signPaciente.fromDataURL(data["firma_paciente"]);
    this.signLugar.fromDataURL(data["firma_lugar"]);
  };

  changeSign = (name) => {
    const signChange = { ...this.state.signChange };
    signChange[name] = true;
    this.setState({ signChange });
  };

  sendData = () => {
    const data = { ...this.state.data };
    const signChange = { ...this.state.signChange };
    data["entrega_paciente"] = signChange["ep"]
      ? this.signEntrega.toDataURL()
      : data["entrega_paciente"];
    data["recibe_paciente"] = signChange["rp"]
      ? this.signRecibe.toDataURL()
      : data["recibe_paciente"];
    data["firma_paciente"] = signChange["fp"]
      ? this.signPaciente.toDataURL()
      : data["firma_paciente"];
    data["firma_lugar"] = signChange["fl"]
      ? this.signLugar.toDataURL()
      : data["firma_lugar"];
    this.props.onSubmit(data);
    this.props.onClose();
  };

  cleanSign = (name) => {
    const signChange = { ...this.state.signChange };
    const obj = {
      signEntrega: "ep",
      signRecibe: "rp",
      signPaciente: "fp",
      signLugar: "fl",
    };
    const letters = obj[name];
    signChange[letters] = true;
    this[name].clear();
    this.setState({ signChange });
  };

  render() {
    return (
      <div className="modal-back z-30">
        <div className="px-2">
          <div className="modal-box md:w-9/12 top-5">
            <span className="modal-title">
              <FontAwesomeIcon
                className={"mr-2 cursor-pointer text-green-500"}
                icon={["far", "save"]}
                onClick={this.sendData}
              />
              Firmas
              <FontAwesomeIcon
                className="ml-1 text-red-500 cursor-pointer"
                icon={["far", "times-circle"]}
                onClick={() => this.props.onClose()}
              />
            </span>
            <form className="flex flex-wrap py-3 text-sm">
              <div className="w-full flex flex-wrap p-2">
                <div className="w-full md:w-1/2 px-2">
                  <div className="w-full border border-purple-500 rounded cursor-pointer">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: window.innerWidth <= 750 ? 320 : 110,
                      }}
                      ref={(ref) => {
                        this.signEntrega = ref;
                      }}
                      onEnd={() => this.changeSign("ep")}
                    />
                  </div>
                  <div className="w-full text-center pb-3">
                    <span>
                      Entrega Paciente{" "}
                      <FontAwesomeIcon
                        className={"ml-1 cursor-pointer text-green-500"}
                        icon={["fas", "sync-alt"]}
                        onClick={() => this.cleanSign("signEntrega")}
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <div className="w-full border border-purple-500 rounded cursor-pointer">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: window.innerWidth <= 750 ? 320 : 110,
                      }}
                      ref={(ref) => {
                        this.signPaciente = ref;
                      }}
                      onEnd={() => this.changeSign("fp")}
                    />
                  </div>
                  <div className="w-full text-center">
                    <span>
                      Paciente{" "}
                      <FontAwesomeIcon
                        className={"ml-1 cursor-pointer text-green-500"}
                        icon={["fas", "sync-alt"]}
                        onClick={() => this.cleanSign("signPaciente")}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-wrap text-xs text-left px-5">
                <span>
                  *Se me ha informado del manejo y tratamiento necesario, así
                  como de los riesgos que esto conlleva y autorizo se realicen
                  todos los procedimientos necesarios para salvaguardar mi salud
                  e integridad física. Y entiendo que es un servicio de
                  emergencias prehospitalario de medios, no de resultados, por
                  lo cual, deslindo de responsabilidades al mismo y me
                  comprometo a seguir las indicaciones prescritas por el
                  paramédico. <br /> *Si es menor de edad, la persona
                  responsable deberá indicar nombre, firma y relación con el
                  paciente
                </span>
              </div>
              <div className="w-full flex flex-wrap p-2">
                <div className="w-full md:w-1/2 px-2">
                  <div className="w-full border border-purple-500 rounded cursor-pointer">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: window.innerWidth <= 750 ? 320 : 110,
                      }}
                      ref={(ref) => {
                        this.signRecibe = ref;
                      }}
                      onEnd={() => this.changeSign("rp")}
                    />
                  </div>
                  <div className="w-full text-center pb-3">
                    <span>
                      Recibe Paciente{" "}
                      <FontAwesomeIcon
                        className={"ml-1 cursor-pointer text-green-500"}
                        icon={["fas", "sync-alt"]}
                        onClick={() => this.cleanSign("signRecibe")}
                      />
                    </span>
                  </div>
                </div>

                <div className="w-full md:w-1/2 px-2">
                  <div className="w-full border border-purple-500 rounded cursor-pointer">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: window.innerWidth <= 750 ? 320 : 110,
                      }}
                      ref={(ref) => {
                        this.signLugar = ref;
                      }}
                      onEnd={() => this.changeSign("fl")}
                    />
                  </div>
                  <div className="w-full text-center">
                    <span>
                      Responsable del Lugar{" "}
                      <FontAwesomeIcon
                        className={"ml-1 cursor-pointer text-green-500"}
                        icon={["fas", "sync-alt"]}
                        onClick={() => this.cleanSign("signLugar")}
                      />
                    </span>
                  </div>
                </div>
              </div>
              {this.renderTextArea(
                "observaciones",
                "Observaciones",
                "text",
                "",
                "2/12",
                "10/12",
                4
              )}
              <div className="flex space-x-4 justify-center w-full pt-4">
                <div className="w-1/2 md:w-2/12">
                  <button
                    className="btn-success w-full"
                    type="button"
                    onClick={this.sendData}
                  >
                    Guardar
                  </button>
                </div>
                <div className="w-1/2 md:w-2/12">
                  <button
                    className="btn-red w-full"
                    type="button"
                    onClick={() => this.props.onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Firmas;

import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import _ from "lodash";

class EvPaciente extends FormRules {
  constructor(props) {
    super(props);
    this.state = {
      data: props.prevDetails
        ? props.prevDetails
        : {
            EvaluacionPX: "",
            agente_causal: "",
            origen_probable: "",
            especifique: "",
            pv_sub: "",
          },
      errors: {},
      listasTx: props.listasTx ? props.listasTx : [],
      diss: props.disabled,
    };
  }

  schema = {
    EvaluacionPX: Joi.optional().label("EvaluacionPX"),
    agente_causal: Joi.optional().label("agente_causal"),
    origen_probable: Joi.optional().label("origen_probable"),
    especifique: Joi.optional().label("especifique"),
    pv_sub: Joi.optional().label("pv_sub"),
  };

  sendData = () => {
    const data = { ...this.state.data };
    if (data["EvaluacionPX"] === "TRAUMÁTICA") {
      data["origen_probable"] = "";
      data["pv_sub"] = "";
    }

    if (data["EvaluacionPX"] === "CLÍNICA") {
      data["agente_causal"] = "";
    }
    this.props.onSubmit(data);
    this.props.onClose();
  };

  render() {
    const { listasTx, data } = this.state;
    const iw2 = "2/5"; //Input width for two in same row
    const lw2 = "3/5"; //Label width for two in same row
    return (
      <div className="modal-back z-30">
        <div className="px-2">
          <div className="modal-box md:w-5/12 top-20">
            <span className="modal-title">Evaluación del Paciente</span>
            <form className="pt-3 flex flex-wrap space-y-3">
              {this.renderSelect(
                "EvaluacionPX",
                "Causa",
                "text",
                _.orderBy(
                  _.filter(listasTx, { lista: "evaluacionpx" }),
                  "val",
                  "asc"
                ),
                iw2,
                lw2
              )}

              {data.EvaluacionPX === "TRAUMÁTICA"
                ? this.renderSelect(
                    "agente_causal",
                    "Agente Causal",
                    "text",
                    _.orderBy(
                      _.filter(listasTx, { lista: "agente_causal" }),
                      "val",
                      "asc"
                    ),
                    iw2,
                    lw2
                  )
                : null}
              {data.EvaluacionPX === "CLÍNICA"
                ? this.renderSelect(
                    "origen_probable",
                    "Origen Probable",
                    "text",
                    _.orderBy(
                      _.filter(listasTx, { lista: "origen_probable" }),
                      "val",
                      "asc"
                    ),
                    iw2,
                    lw2
                  )
                : null}
              {data.agente_causal === "OTRO" || data.origen_probable === "OTRO"
                ? this.renderTextArea(
                    "especifique",
                    "Especifique",
                    "text",
                    "",
                    iw2,
                    lw2,
                    3
                  )
                : null}
              {data.origen_probable === "OTRO" &&
              data.EvaluacionPX === "CLÍNICA"
                ? this.renderRadio(
                    "pv_sub",
                    [
                      { value: "p", label: "1ª vez" },
                      { value: "s", label: "Subsecuente" },
                    ],
                    "1/4"
                  )
                : null}
              <div className="flex space-x-4 justify-center w-full pt-2">
                <div className="w-1/2 md:w-3/12">
                  <button
                    className="btn-success w-full"
                    type="button"
                    onClick={this.sendData}
                  >
                    Guardar
                  </button>
                </div>
                <div className="w-1/2 md:w-3/12">
                  <button
                    className="btn-red w-full"
                    type="button"
                    onClick={() => this.props.onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EvPaciente;

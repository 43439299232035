import React, { Component } from "react";
import Pdf from "react-to-pdf";
import SignatureCanvas from "react-signature-canvas";
import { trackPromise } from "react-promise-tracker";
import _ from "lodash";
import Swal from "sweetalert2";
//Components
import LoadingComp from "../tools/loadingComp";
//Images
import bodyImage from "./../../assets/img/formas/bodyImage.JPG";
import logo from "./../../assets/img/logo.png";
//Services
import { pullFrap } from "../../services/frapService";
import { getLists } from "./../../services/listaService";

const ref = React.createRef();
const options = {
  unit: "cm",
  format: [38, parseInt(window.innerWidth * 0.0264) - 3],
};

class PdfFile extends Component {
  state = {
    data: {
      idFrap: "",
      id_Empleado: 0,
      id_Empleado2: 0,
      id_Empleado3: 0,
      Fecha_Frap: "",
      Hora_Llamada: "",
      Hora_Salida: "",
      Hora_Llegada: "",
      Hora_Traslado: "",
      Hora_Destino: "",
      Hora_Termino: "",
      Lugar_Inicio: "",
      Motivo: "",
      Lugar_Destino: "",
      Tipo_Cliente: 0,
      Tipo_Servicio: "",
      idCliente: 0,
      Paciente: "",
      Edad: 0,
      Estado_Civil: "",
      Servicio_Med: "",
      Genero: "",
      EvaluacionPX: "",
      Impresion: "",
      Manejo1: "",
      Manejo2: "",
      Manejo3: "",
      Manejo4: "",
      Indicaciones1: "",
      Indicaciones2: "",
      Indicaciones3: "",
      Indicaciones4: "",
      Traslado: "",
      Costo: 0,
      Status_Pago: "",
      Pago_Servicio: 0,
      Fecha_Recibo: "",
      Recibo: "",
      Tipo_Pago: "",
      Movimiento: "",
      Status_Factura: "",
      Factura: "",
      Fecha_Admin: "",
      Comentarios: "",
      Cobranza: "",
      Validado: 0,
      ambulancia: 0,
      //New Fields 23/11/2021
      agente_causal: "",
      origen_probable: "",
      especifique: "",
      pv_sub: "",
      direccion: "",
      colonia: "",
      ciudad: "",
      meses: 0,
      telefono: "",
      conciencia: "",
      vaerea: "",
      deglucion: "",
      observacion: "",
      auscultacion: "",
      hemotorax: "",
      sitio: "",
      pulsos: "",
      calidad: "",
      piel: "",
      caracteristicas: "",
      alergias: "",
      enfermedadesprev: "",
      medicamentos: "",
      uingesta: "",
      descripcionev: "",
      hora_inicioreg: "",
      hora_arrdest: "",
      hora_base: "",
      hora_despacho: "",
      lugar_destino_otro: "",
      validadoger: 0,
      fecha_gerencia: "",
      oxigeno: "",
      ltsmin: "",
      metodo: "",
      exfisica: "",
      dolor: "",
      esferas: [],
      escala_ocular: "",
      escala_verbal: "",
      escala_motora: "",
      escala_glasgow: "",
      codigo: "",
      fisico: [],
      opfisica: "",
      bvm: "",
      frecuencia: "",
      volument: "",
      pupilas: "",
      windowsize: 0,
      tblvital: [
        {
          hora: "",
          fr: "",
          fc: "",
          ta: "",
          sao2: "",
          temp: "",
          examen: "",
        },
        {
          hora: "",
          fr: "",
          fc: "",
          ta: "",
          sao2: "",
          temp: "",
          examen: "",
        },
        {
          hora: "",
          fr: "",
          fc: "",
          ta: "",
          sao2: "",
          temp: "",
          examen: "",
        },
      ],
      entrega_paciente: "",
      recibe_paciente: "",
      firma_paciente: "",
      firma_lugar: "",
      observaciones: "",
    },
    listas: [],
    acronimos: [
      {
        vis: "DEFORMIDADES",
        val: "D",
      },
      {
        vis: "CONTUSIONES",
        val: "CO",
      },
      {
        vis: "ABRASIONES",
        val: "A",
      },
      {
        vis: "PENETRACIONES",
        val: "P",
      },
      {
        vis: "MOVIMIENTO PARADÓJICO",
        val: "MP",
      },
      {
        vis: "CREPITACIÓN",
        val: "C",
      },
      {
        vis: "HERIDAS",
        val: "H",
      },
      {
        vis: "FRACTURAS",
        val: "F",
      },
      {
        vis: "ENFISEMA SUBCUTÁNEO",
        val: "ES",
      },
      {
        vis: "QUEMADURAS",
        val: "Q",
      },
      {
        vis: "LACERACIONES",
        val: "L",
      },
      {
        vis: "EDEMA",
        val: "E",
      },
      {
        vis: "ALTERACIÓN DE SENSIBILIDAD",
        val: "AS",
      },
      {
        vis: "ALTERACIÓN DE MOVILIDAD",
        val: "AM",
      },
      {
        vis: "DOLOR",
        val: "DO",
      },
    ],
  };

  signEntrega = {};
  signRecibe = {};
  signPaciente = {};
  signLugar = {};

  componentDidMount = async () => {
    try {
      const frap = this.props.match.params.id;
      const res = await trackPromise(pullFrap(frap));
      const { status, data: listas } = await trackPromise(getLists());
      if (res.status === 200 && status === 200 && res.data.length === 1) {
        const data = { ...res.data[0] };
        data["esferas"] =
          data["esferas"] === "" ? [] : JSON.parse(data["esferas"]);
        data["fisico"] =
          data["fisico"] === "" ? [] : JSON.parse(data["fisico"]);
        data["tblvital"] =
          data["tblvital"] === ""
            ? [
                {
                  hora: "",
                  fr: "",
                  fc: "",
                  ta: "",
                  sao2: "",
                  temp: "",
                  examen: "",
                },
                {
                  hora: "",
                  fr: "",
                  fc: "",
                  ta: "",
                  sao2: "",
                  temp: "",
                  examen: "",
                },
                {
                  hora: "",
                  fr: "",
                  fc: "",
                  ta: "",
                  sao2: "",
                  temp: "",
                  examen: "",
                },
              ]
            : JSON.parse(data["tblvital"]);
        data["entrega_paciente"] =
          data["entrega_paciente"] === ""
            ? []
            : JSON.parse(data["entrega_paciente"]);
        data["recibe_paciente"] =
          data["recibe_paciente"] === ""
            ? []
            : JSON.parse(data["recibe_paciente"]);
        data["firma_paciente"] =
          data["firma_paciente"] === ""
            ? []
            : JSON.parse(data["firma_paciente"]);
        data["firma_lugar"] =
          data["firma_lugar"] === "" ? [] : JSON.parse(data["firma_lugar"]);
        //JSON Parse Array Variables
        this.signEntrega.fromData(data["entrega_paciente"]);
        this.signRecibe.fromData(data["recibe_paciente"]);
        this.signPaciente.fromData(data["firma_paciente"]);
        this.signLugar.fromData(data["firma_lugar"]);
        this.setState({ data, listas });
        document.getElementById("pdfGenerator").click();
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const { data, listas, acronimos } = this.state;
    const listval = {
      operador: _.filter(listas, {
        lista: "Operador-all",
        val: data.id_Empleado,
      }),
      paramedico: _.filter(listas, {
        lista: "Paramedico-all",
        val: data.id_Empleado2,
      }),
      tipoCliente: _.filter(listas, {
        lista: "Tipo_Cliente",
        val: data.Tipo_Cliente,
      }),
    };
    return (
      <React.Fragment>
        <div className="w-full flex justify-center py-2">
          <LoadingComp />
          <Pdf
            targetRef={ref}
            filename={"FRAP_" + data.idFrap + ".pdf"}
            options={options}
            x={0}
            y={0.5}
            scale={0.9}
          >
            {({ toPdf }) => (
              <button
                id="pdfGenerator"
                className="btn-secondary md:w-1/5"
                onClick={toPdf}
              >
                Generar PDF
              </button>
            )}
          </Pdf>
        </div>
        <div ref={ref} className="px-3">
          <div className="p-2 w-full flex flex-wrap text-4xl text-blue-800">
            <div className="w-full flex flex-wrap md:w-1/3 items-center justify-center">
              {<img src={logo} alt="Logo_LifeSupport" />}
            </div>
            <div className="w-full flex flex-wrap md:w-2/3 px-3">
              <span className="w-full font-bold">
                <i>SERVICIO MEDICO DE EMERGENCIAS</i>
              </span>
              <span className="w-full text-xl">
                CORREO MAYOR N° 2274, COL. POSTAL CERRITOS, C.P. 25019,
                SALTILLO, COAHUILA.
              </span>
              <span className="w-full text-xl">TEL. (44) 439 35 35</span>
              <span className="w-full md:w-1/2 text-xl">TUM 24-7 SC</span>
              <span className="w-full md:w-1/2 text-xl">
                {"Folio: " + data.idFrap}
              </span>
            </div>
          </div>
          <div className="px-2 flex flex-wrap text-sm">
            <div className="w-full md:w-1/2 px-4 py-2 space-y-2">
              <div className="w-full flex flex-wrap border rounded px-2 py-1">
                <span className="w-1/2">{"Fecha: " + data.Fecha_Frap}</span>
                <span className="w-1/2">{"Unidad: " + data.ambulancia}</span>
                <span className="w-full">
                  {"Operador: " +
                    (listval.operador.length > 0
                      ? listval.operador[0].vis
                      : "")}
                </span>
                <span className="w-full">
                  {"TUM: " +
                    (listval.paramedico.length > 0
                      ? listval.paramedico[0].vis
                      : "")}
                </span>
                <div className="w-full flex flex-wrap p-1">
                  <div className="w-full flex flex-wrap border border-blue-200 rounded text-xs p-1">
                    <span className="w-full text-center pb-1 font-semibold">
                      Horas Críticas
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Llamada: " +
                        (data.Hora_Llamada === "00:00:00"
                          ? ""
                          : data.Hora_Llamada)}
                    </span>
                    {/*<span className="w-full md:w-1/4">
                      {"Despacho: " +
                        (data.hora_despacho === "00:00:00"
                          ? ""
                          : data.hora_despacho)}
                    </span>*/}
                    <span className="w-full md:w-1/3">
                      {"Salida: " +
                        (data.Hora_Salida === "00:00:00"
                          ? ""
                          : data.Hora_Salida)}
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Arribo Lugar: " +
                        (data.Hora_Llegada === "00:00:00"
                          ? ""
                          : data.Hora_Llegada)}
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Inicio Traslado: " +
                        (data.Hora_Traslado === "00:00:00"
                          ? ""
                          : data.Hora_Traslado)}
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Arribo Destino: " +
                        (data.Hora_Destino === "00:00:00"
                          ? ""
                          : data.Hora_Destino)}
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Inicio Regreso: " +
                        (data.hora_inicioreg === "00:00:00"
                          ? ""
                          : data.hora_inicioreg)}
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Arribo Destino Final: " +
                        (data.hora_arrdest === "00:00:00"
                          ? ""
                          : data.hora_arrdest)}
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Término Servicio: " +
                        (data.Hora_Termino === "00:00:00"
                          ? ""
                          : data.Hora_Termino)}
                    </span>
                    <span className="w-full md:w-1/3">
                      {"Arribo Base: " +
                        (data.hora_base === "00:00:00" ? "" : data.hora_base)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-wrap border rounded px-2 py-1 space-y-1">
                <span className="w-full pb-1 font-semibold text-blue-800 text-center">
                  Datos del Servicio
                </span>
                <span className="w-full">
                  {"Lugar de Atención: " + data.Lugar_Inicio}
                </span>
                <span className="w-full">{"Motivo: " + data.Motivo}</span>
                <span className="w-full">
                  {"Traslado: "}
                  <span className="text-xl ml-2">
                    {data.Traslado === "SI" ? "⊠" : "▢"}
                  </span>
                  Si
                  <span className="text-xl ml-2">
                    {data.Traslado === "NO" ? "⊠" : "▢"}
                  </span>
                  No
                  <span className="text-xl ml-2">
                    {data.Traslado === "PROPIOS MEDIOS" ? "⊠" : "▢"}
                  </span>
                  Propios Medios
                </span>
                <span className="w-full">
                  {"Lugar Destino: " + data.Lugar_Destino}
                </span>
                <span className="w-full">
                  {"Tipo de Cliente: " +
                    (listval.tipoCliente.length > 0
                      ? listval.tipoCliente[0].vis
                      : "")}
                </span>
                <span className="w-full">
                  {"Tipo de Servicio: " + data.Tipo_Servicio}
                </span>
              </div>
              <div className="w-full flex flex-wrap border rounded px-2 py-1 space-y-1">
                <span className="w-full pb-1 font-semibold text-blue-800 text-center">
                  Datos del Paciente
                </span>
                <span className="w-full">{"Nombre: " + data.Paciente}</span>
                <span className="w-full">{"Dirección: " + data.direccion}</span>
                <span className="w-full md:w-1/3">{"Edad: " + data.Edad}</span>
                <span className="w-full md:w-1/3">
                  {"Género: " + data.Genero}
                </span>
                <span className="w-full md:w-1/3">
                  {"Estado Civil: " + data.Estado_Civil}
                </span>
                <span className="w-full md:w-1/2">
                  {"Servicio Médico: " + data.Servicio_Med}
                </span>
                <span className="w-full md:w-1/2">
                  {"Teléfono: " + data.telefono}
                </span>
              </div>
              <div className="w-full flex flex-wrap border rounded px-2 py-1 space-y-1">
                <span className="w-full pb-1 font-semibold text-blue-800 text-center">
                  Evaluación del Paciente
                </span>
                <span className="w-full">
                  {"Causa:"}
                  <span className="text-xl ml-2">
                    {data.EvaluacionPX === "TRAUMÁTICA" ? "⊠" : "▢"}
                  </span>
                  Traumática
                  <span className="text-xl ml-2">
                    {data.EvaluacionPX === "CLÍNICA" ? "⊠" : "▢"}
                  </span>
                  Clínica
                  <span className="text-xl ml-2">
                    {data.EvaluacionPX === "OBSTÉNTRICA" ? "⊠" : "▢"}
                  </span>
                  Obstétrica
                </span>
                <span className="w-full">
                  {data.EvaluacionPX === "CLÍNICA"
                    ? "Origen Probable: " + data.origen_probable
                    : data.EvaluacionPX === "TRAUMÁTICA"
                    ? "Agente Causal: " + data.agente_causal
                    : null}
                </span>
                <span className="w-full">
                  {data.especifique !== ""
                    ? "Especifique: " + data.especifique
                    : null}
                  {data.especifique !== "" &&
                  data.EvaluacionPX === "CLÍNICA" ? (
                    <React.Fragment>
                      {" "}
                      <span className="text-xl ml-2">
                        {data.pv_sub === "p" ? "⊠" : "▢"}
                      </span>
                      {" 1ª vez "}
                      <span className="text-xl ml-2">
                        {data.pv_sub === "s" ? "⊠" : "▢"}
                      </span>
                      {" Subsecuente "}
                    </React.Fragment>
                  ) : null}
                </span>
              </div>
              <div className="w-full flex flex-wrap border rounded px-2 py-1 space-y-1">
                <span className="w-full pb-1 font-semibold text-blue-800 text-center">
                  Evaluación Inicial
                </span>
                <span className="w-full">
                  {"Nivel de Conciencia: " + data.conciencia}
                </span>
                <span className="w-full md:w-1/2">
                  {"Vía Aérea: " + data.vaerea}
                </span>
                <span className="w-full md:w-1/2">
                  {"Reflejo de Deglución: " + data.deglucion}
                </span>
                <span className="w-full font-semibold">{"Ventilación"}</span>
                <span className="w-full">
                  {"Observación: " + data.observacion}
                </span>
                <span className="w-full">
                  {"Auscultación: " + data.auscultacion}
                </span>
                <span className="w-full md:w-1/2">
                  {"Hemitórax: " + data.hemotorax}
                </span>
                <span className="w-full md:w-1/2">
                  {"Sitio: " + data.sitio}
                </span>
                <span className="w-full font-semibold">{"Circulación"}</span>
                <span className="w-full">
                  {"Presencia de Pulsos: " + data.pulsos}
                </span>
                <span className="w-full">{"Calidad: " + data.calidad}</span>
                <span className="w-full md:w-1/2">{"Piel: " + data.piel}</span>
                <span className="w-full md:w-1/2">
                  {"Características: " + data.caracteristicas}
                </span>
              </div>
              {/* Firmas Ajustadas*/}
              <div className="px-5 w-full flex flex-wrap text-center">
                <div className="w-full flex flex-wrap md:w-1/2 px-2">
                  <div className="w-full flex justify-center">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 80,
                      }}
                      ref={(ref) => {
                        this.signEntrega = ref;
                      }}
                    />
                  </div>
                  <span className="text-center w-full">
                    _________________________________
                  </span>
                  <span className="w-full text-center">Entrega Paciente</span>
                </div>
                <div className="w-full flex flex-wrap md:w-1/2 px-2">
                  <div className="w-full flex justify-center">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 80,
                      }}
                      ref={(ref) => {
                        this.signRecibe = ref;
                      }}
                    />
                  </div>
                  <span className="text-center w-full">
                    _________________________________
                  </span>
                  <span className="w-full text-center">Recibe Paciente</span>
                </div>
                <div className="w-full flex flex-wrap text-left px-5 py-2">
                  <span className="text-justify">
                    *Se me ha informado del manejo y tratamiento necesario, así
                    como de los riesgos que esto conlleva y autorizo se realicen
                    todos los procedimientos necesarios para salvaguardar mi
                    salud e integridad física. Y entiendo que es un servicio de
                    emergencias prehospitalario de medios, no de resultados, por
                    lo cual, deslindo de responsabilidades al mismo y me
                    comprometo a seguir las indicaciones prescritas por el
                    paramédico. <br /> *Si es menor de edad, la persona
                    responsable deberá indicar nombre, firma y relación con el
                    paciente
                  </span>
                </div>
                <div className="w-full flex flex-wrap md:w-1/2 px-2">
                  <div className="w-full flex justify-center">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 80,
                      }}
                      ref={(ref) => {
                        this.signPaciente = ref;
                      }}
                    />
                  </div>

                  <span className="text-center w-full">
                    _________________________________
                  </span>
                  <span className="w-full text-center">Paciente</span>
                </div>
                <div className="w-full flex flex-wrap md:w-1/2 px-2">
                  <div className="w-full flex justify-center">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 500,
                        height: 80,
                      }}
                      ref={(ref) => {
                        this.signLugar = ref;
                      }}
                    />
                  </div>
                  <span className="text-center w-full">
                    _________________________________
                  </span>
                  <span className="w-full text-center">
                    Responsable del Lugar
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 py-2 space-y-2">
              <div className="w-full flex flex-wrap border rounded px-2 py-1 space-y-1">
                <span className="w-full pb-1 font-semibold text-blue-800 text-center">
                  Evaluación Secundaria
                </span>
                <div className="w-full flex flex-wrap justify-center">
                  <div className="w-full md:w-4/12 p-2">
                    <table
                      className="table-fixed shadow-md w-full"
                      style={{ fontSize: "9px", lineHeight: "0.8rem" }}
                    >
                      <tbody className="">
                        {acronimos.map((i, k) => (
                          <tr
                            key={k}
                            className={
                              "border-b " + (k % 2 === 0 ? "bg-blue-50" : "")
                            }
                            style={{ height: "8px" }}
                          >
                            <td>
                              <div className="w-full flex flew-wrap">
                                <span className="w-1/12 text-center">
                                  {k + 1}
                                </span>
                                <span className="w-9/12">{i.vis}</span>
                                <span className="w-2/12 text-center">
                                  {"(" + i.val + ")"}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full md:w-6/12 py-2">
                    <img
                      src={bodyImage}
                      alt="BodyImage"
                      className="cursor-pointer rounded-md"
                    />
                  </div>
                </div>
                <span className="w-full">{"Pupilas: " + data.pupilas}</span>
                <span className="w-full md:w-1/3">
                  {"Oxigeno: " + data.oxigeno}
                </span>
                <span className="w-full md:w-1/3">
                  {"Lts./Min:" + data.ltsmin}
                </span>
                <span className="w-full md:w-1/3">
                  {"Método:" + data.metodo}
                </span>
                <span className="w-full font-semibold">
                  {"Signos Vitales y Monitoreo"}
                </span>
                <div className="w-full flex py-1">
                  <table className="table-fixed text-xs shadow-md w-full">
                    <thead>
                      <tr>
                        <th className="w-1/12 border p-1">HORA</th>
                        <th className="w-1/12 border p-1">FR</th>
                        <th className="w-1/12 border p-1">FC</th>
                        <th className="w-1/12 border p-1">T/A</th>
                        <th className="w-1/12 border p-1">Sa02</th>
                        <th className="w-1/12 border p-1">TEMP</th>
                        <th className="w-3/12 border p-1">
                          MINI EXAMEN NEUROLÓGICO
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border p-1">{data.tblvital[0].hora}</td>
                        <td className="border p-1">{data.tblvital[0].fr}</td>
                        <td className="border p-1">{data.tblvital[0].fc}</td>
                        <td className="border p-1">{data.tblvital[0].ta}</td>
                        <td className="border p-1">{data.tblvital[0].sao2}</td>
                        <td className="border p-1">{data.tblvital[0].temp}</td>
                        <td className="border p-1 space-x-6 text-center">
                          <span
                            className={
                              data.tblvital[0].examen.includes("A")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            A
                          </span>
                          <span
                            className={
                              data.tblvital[0].examen.includes("V")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            V
                          </span>
                          <span
                            className={
                              data.tblvital[0].examen.includes("D")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            D
                          </span>
                          <span
                            className={
                              data.tblvital[0].examen.includes("I")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            I
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-1">{data.tblvital[1].hora}</td>
                        <td className="border p-1">{data.tblvital[1].fr}</td>
                        <td className="border p-1">{data.tblvital[1].fc}</td>
                        <td className="border p-1">{data.tblvital[1].ta}</td>
                        <td className="border p-1">{data.tblvital[1].sao2}</td>
                        <td className="border p-1">{data.tblvital[1].temp}</td>
                        <td className="border p-1 space-x-6 text-center">
                          <span
                            className={
                              data.tblvital[1].examen.includes("A")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            A
                          </span>
                          <span
                            className={
                              data.tblvital[1].examen.includes("V")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            V
                          </span>
                          <span
                            className={
                              data.tblvital[1].examen.includes("D")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            D
                          </span>
                          <span
                            className={
                              data.tblvital[1].examen.includes("I")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            I
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-1">{data.tblvital[2].hora}</td>
                        <td className="border p-1">{data.tblvital[2].fr}</td>
                        <td className="border p-1">{data.tblvital[2].fc}</td>
                        <td className="border p-1">{data.tblvital[2].ta}</td>
                        <td className="border p-1">{data.tblvital[2].sao2}</td>
                        <td className="border p-1">{data.tblvital[2].temp}</td>
                        <td className="border p-1 space-x-6 text-center">
                          <span
                            className={
                              data.tblvital[2].examen.includes("A")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            A
                          </span>
                          <span
                            className={
                              data.tblvital[2].examen.includes("V")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            V
                          </span>
                          <span
                            className={
                              data.tblvital[2].examen.includes("D")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            D
                          </span>
                          <span
                            className={
                              data.tblvital[2].examen.includes("I")
                                ? "font-bold underline text-blue-800"
                                : ""
                            }
                          >
                            I
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <span className="w-full font-semibold">
                  {"Asistencia Ventilatoria"}
                </span>
                <span className="w-full">{"Ventilador BVM: " + data.bvm}</span>
                <span className="w-full">
                  {"Frecuencia: " + data.frecuencia}
                </span>
                <span className="w-full">
                  {"Volumen Tidal: " + data.volument}
                </span>
                <span className="w-full">
                  {"Exploración Física: " + data.exfisica}
                </span>
                <span className="w-full md:w-3/12">
                  {"Escala del Dolor: " + data.dolor}
                </span>
                <span className="w-full md:w-9/12">
                  {"Esferas de Conciencia: "}
                  <span className="text-l ml-2">
                    {data.esferas.includes("PERSONA") ? "⊠" : "▢"}
                  </span>
                  Persona
                  <span className="text-l ml-2">
                    {data.esferas.includes("LUGAR") ? "⊠" : "▢"}
                  </span>
                  Lugar
                  <span className="text-l ml-2">
                    {data.esferas.includes("TIEMPO") ? "⊠" : "▢"}
                  </span>
                  Tiempo
                </span>

                <span className="w-full font-semibold">
                  {"Escala de Coma de Glasgow"}
                </span>
                <span className="w-full md:w-1/4">
                  {"Ocular: " + data.escala_ocular}
                </span>
                <span className="w-full md:w-1/4">
                  {"Verbal: " + data.escala_verbal}
                </span>
                <span className="w-full md:w-1/4">
                  {"Motora: " + data.escala_motora}
                </span>
                <span className="w-full md:w-1/4">
                  {"Glasgow: " + data.escala_glasgow}
                </span>
                <span className="w-full">{"Código: " + data.codigo}</span>
              </div>
              <div className="w-full flex flex-wrap border rounded px-2 py-1 space-y-1">
                <span className="w-full pb-1 font-semibold text-blue-800 text-center">
                  Historia Clínica
                </span>
                <span className="w-full">{"Alergias: " + data.alergias}</span>
                <span className="w-full">
                  {"Enf. Previas: " + data.enfermedadesprev}
                </span>
                <span className="w-full">
                  {"Medicamentos: " + data.medicamentos}
                </span>
                <span className="w-full">
                  {"Última Ingesta: " + data.uingesta}
                </span>
                <span className="w-full">
                  {"Descripción del Evento: " + data.descripcionev}
                </span>
                <span className="w-full">
                  {"Impresión Diagnóstica: " + data.Impresion}
                </span>
                <span className="w-full">{"Manejo: " + data.Manejo1}</span>
                <span className="w-full">
                  {"Indicaciones: " + data.Indicaciones1}
                </span>
              </div>
              <div className="w-full flex flex-wrap border rounded px-2 py-1 space-y-1">
                <span className="w-full">
                  {"Observaciones: " + data.observaciones}
                </span>
                <span className="w-full md:w-1/3">
                  {"Costo: $" + data.Costo}
                </span>
                <span className="w-full md:w-1/3">
                  {"No. Recibo: " + data.Recibo}
                </span>
                <span className="w-full md:w-1/3">
                  {"No. Factura: " + data.Factura}
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PdfFile;

import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import _ from "lodash";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Images
import bodyImage from "./../../assets/img/formas/body.JPG";
import eyesImage from "./../../assets/img/formas/eyes.JPG";

class EvSecundaria extends FormRules {
  constructor(props) {
    super(props);
    this.state = {
      data: props.prevDetails
        ? props.prevDetails
        : {
            oxigeno: "",
            ltsmin: "",
            metodo: "",
            exfisica: "",
            dolor: "",
            esferas: [],
            escala_ocular: "",
            escala_verbal: "",
            escala_motora: "",
            escala_glasgow: "",
            codigo: "",
            fisico: [],
            opfisica: "",
            bvm: "",
            frecuencia: "",
            volument: "",
            pupilas: 0,
            windowsize: 0,
            tblvital: [
              {
                hora: "",
                minutos: "",
                fr: "",
                fc: "",
                ta: "",
                sao2: "",
                temp: "",
                gluc: "",
                examen: "",
              },
              {
                hora: "",
                minutos: "",
                fr: "",
                fc: "",
                ta: "",
                sao2: "",
                temp: "",
                gluc: "",
                examen: "",
              },
              {
                hora: "",
                minutos: "",
                fr: "",
                fc: "",
                ta: "",
                sao2: "",
                temp: "",
                gluc: "",
                examen: "",
              },
            ],
          },
      errors: {},
      diss: props.disabled,
      boundClientX: 0,
      boundClientY: 0,
      coords: false,
      fisicoflag: false,
      acronimos: [
        {
          vis: "DEFORMIDADES",
          val: "D",
        },
        {
          vis: "CONTUSIONES",
          val: "CO",
        },
        {
          vis: "ABRASIONES",
          val: "A",
        },
        {
          vis: "PENETRACIONES",
          val: "P",
        },
        {
          vis: "MOVIMIENTO PARADÓJICO",
          val: "MP",
        },
        {
          vis: "CREPITACIÓN",
          val: "C",
        },
        {
          vis: "HERIDAS",
          val: "H",
        },
        {
          vis: "FRACTURAS",
          val: "F",
        },
        {
          vis: "ENFISEMA SUBCUTÁNEO",
          val: "ES",
        },
        {
          vis: "QUEMADURAS",
          val: "Q",
        },
        {
          vis: "LACERACIONES",
          val: "L",
        },
        {
          vis: "EDEMA",
          val: "E",
        },
        {
          vis: "ALTERACIÓN DE SENSIBILIDAD",
          val: "AS",
        },
        {
          vis: "ALTERACIÓN DE MOVILIDAD",
          val: "AM",
        },
        {
          vis: "DOLOR",
          val: "DO",
        },
      ],
      //listasTx: props.listasTx ? props.listasTx : [],
      //*Agregar lista en caso de que sea necesario desde la BD.
      //Eliminar opfisica despues de usarlo
    };
  }

  schema = {
    oxigeno: Joi.optional(),
    ltsmin: Joi.optional(),
    metodo: Joi.optional(),
    exfisica: Joi.optional(),
    dolor: Joi.optional(),
    esferas: Joi.optional(),
    escala_ocular: Joi.optional(),
    escala_verbal: Joi.optional(),
    escala_motora: Joi.optional(),
    escala_glasgow: Joi.optional(),
    codigo: Joi.optional(),
    fisico: Joi.optional(),
    opfisica: Joi.optional(),
    bvm: Joi.optional(),
    frecuencia: Joi.optional(),
    volument: Joi.optional(),
    pupilas: Joi.optional(),
    tblvital: Joi.optional(),
    windowsize: Joi.optional(),
  };

  modifyBody = (evt) => {
    //code to calculate % of image click
    const { data, coords } = this.state;

    if (!coords) {
      let e = evt.target;
      let dim = e.getBoundingClientRect();
      let newCoords = {
        x: Math.floor(evt.clientX - dim.left),
        y: Math.floor(evt.clientY - dim.top),
        value: "New",
      };

      data["fisico"].push(newCoords);
      this.setState({
        coords: newCoords,
        boundClientX: dim.left,
        boundClientY: dim.top,
      });
    }
  };

  setBound = (evt) => {
    let e = evt.target;
    let dim = e.getBoundingClientRect();
    const boundClientX = dim.left;
    const boundClientY = dim.top;
    this.setState({ boundClientX, boundClientY });
  };

  setFisicoValue = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    if (data.opfisica !== "") {
      const arr = data.fisico;
      const i = _.findIndex(arr, { value: "New" });
      data["fisico"][i].value = data.opfisica;
      data["opfisica"] = "";
      this.setState({ data, coords: false, fisicoflag: true });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Selecciona una opción",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  deleteFisicoValue = () => {
    const data = { ...this.state.data };
    const arr = data.fisico;
    const i = _.findIndex(arr, { value: "New" });
    data["fisico"].splice(i, 1);
    data["opfisica"] = "";
    this.setState({ data, coords: false });
  };

  cleanFisicoData = () => {
    const data = { ...this.state.data };
    if (data["fisico"].length > 0) {
      data["fisico"].pop();
      this.setState({ data });
    }
  };

  handleTableChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    let name = input.name;
    const index = parseInt(name.charAt(name.length - 1));
    name = name.slice(0, -1);
    data["tblvital"][index][name] = input.value;
    this.setState({ data });
  };

  setExamenValue = (letter, index) => {
    const data = { ...this.state.data };
    let value = data["tblvital"][index]["examen"];
    if (value.includes(letter)) {
      value = value.replace(letter, "");
    } else {
      //value = value + letter;
      value = letter;
    }
    data["tblvital"][index]["examen"] = value;
    this.setState({ data });
  };

  sendData = () => {
    const data = { ...this.state.data };
    const newIndex = data.fisico
      .map(function (e) {
        return e.value;
      })
      .indexOf("New");
    if (newIndex > -1) {
      data.fisico.splice(newIndex, 1);
    }
    data["windowsize"] = this.state.fisicoflag ? window.innerWidth : 0;
    this.props.onSubmit(data);
    this.props.onClose();
  };

  render() {
    const lw2 = "4/12"; //Label width for two in same row
    const iw2 = "8/12"; //Input width for two in same row
    const { data, boundClientX, boundClientY, coords, acronimos, diss } =
      this.state;
    const tblArr = [...Array(3).keys()];
    const tblV = _.keys(data.tblvital[0]);
    const examOpt = ["A", "V", "D", "I"];
    return (
      <React.Fragment>
        <div
          className="modal-back z-30"
          ref={(divElement) => {
            this.divElement = divElement;
          }}
        >
          <div className="px-2">
            <div className="modal-box md:w-11/12 top-5">
              <span className="modal-title">
                <FontAwesomeIcon
                  className={"mr-2 cursor-pointer text-green-500"}
                  icon={["far", "save"]}
                  onClick={this.sendData}
                />
                Evaluación Secundaria{" "}
                <FontAwesomeIcon
                  className="ml-1 text-red-500 cursor-pointer"
                  icon={["far", "times-circle"]}
                  onClick={() => this.props.onClose()}
                />{" "}
              </span>
              <form className="pt-1 flex flex-wrap">
                <div className="w-full md:w-1/2 py-1 px-3 space-y-2">
                  <div className="w-full text-center">
                    <span className="">Signos Vitales y Monitoreo </span>
                  </div>
                  <div className="w-full flex py-1">
                    <table className="table-fixed text-xs shadow-md w-full">
                      <thead>
                        <tr>
                          <th className="w-1/12 border p-1">HORA</th>
                          <th className="w-1/12 border p-1">MIN</th>
                          <th className="w-1/12 border p-1">FR</th>
                          <th className="w-1/12 border p-1">FC</th>
                          <th className="w-1/12 border p-1">T/A</th>
                          <th className="w-1/12 border p-1">Sa02</th>
                          <th className="w-1/12 border p-1">TEMP</th>
                          <th className="w-1/12 border p-1">GLUC</th>
                          <th className="w-2/12 border p-1">
                            MINI EXAMEN NEUROLÓGICO
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tblArr.map((index, i) => (
                          <tr key={i}>
                            {tblV.map((name, i) =>
                              name !== "examen" ? (
                                <td className="border p-1" key={name + i}>
                                  <div className="flex flex-wrap w-full">
                                    <input
                                      className="w-full text-xs text-input"
                                      type="text"
                                      name={name + index}
                                      value={data["tblvital"][index][name]}
                                      onChange={this.handleTableChange}
                                      disabled={diss}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td className="border p-1" key={name + i}>
                                  <div className="flex flex-wrap w-full justify-center font-bold space-x-3">
                                    {examOpt.map((letter, i) => (
                                      <span
                                        className={
                                          "cursor-pointer " +
                                          (data["tblvital"][index][
                                            "examen"
                                          ].includes(letter)
                                            ? "text-blue-500"
                                            : null)
                                        }
                                        key={letter + i}
                                        onClick={
                                          diss
                                            ? null
                                            : () =>
                                                this.setExamenValue(
                                                  letter,
                                                  index
                                                )
                                        }
                                      >
                                        {letter}
                                      </span>
                                    ))}
                                  </div>
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full text-center ">
                    <span className="">Asistencia Ventilatoria</span>
                  </div>
                  {this.renderInputH("bvm", "BVM", "text", "", lw2, iw2)}
                  <div className="w-full text-center py-1">
                    <span className="">Ventilador</span>
                  </div>
                  <div className="w-full flex flex-wrap">
                    <div className="w-full md:w-1/2 px-2">
                      {this.renderInputH(
                        "frecuencia",
                        "Frecuencia",
                        "text",
                        "",
                        "6/12",
                        "6/12"
                      )}
                    </div>
                    <div className="w-full md:w-1/2 px-2">
                      {this.renderInputH(
                        "volument",
                        "Volumen Tidal",
                        "text",
                        "",
                        "6/12",
                        "6/12"
                      )}
                    </div>
                  </div>
                  {this.renderCheckBoxes(
                    "esferas",
                    "Esferas de conciencia",
                    ["PERSONA", "LUGAR", "TIEMPO"],
                    lw2,
                    iw2
                  )}
                  <div className="w-full text-center py-1">
                    <span className="">Escala de coma de Glasgow</span>
                  </div>
                  <div className="w-full flex flex-wrap">
                    <div className="w-full md:w-1/4 px-1">
                      {this.renderSelect(
                        "escala_ocular",
                        "Ocular",
                        "text",
                        [
                          {
                            vis: "1",
                            val: "1",
                          },
                          {
                            vis: "2",
                            val: "2",
                          },
                          {
                            vis: "3",
                            val: "3",
                          },
                          {
                            vis: "4",
                            val: "4",
                          },
                        ],

                        "6/12",
                        "6/12"
                      )}
                    </div>
                    <div className="w-full md:w-1/4 px-1">
                      {this.renderSelect(
                        "escala_verbal",
                        "Verbal",
                        "text",
                        [
                          {
                            vis: "1",
                            val: "1",
                          },
                          {
                            vis: "2",
                            val: "2",
                          },
                          {
                            vis: "3",
                            val: "3",
                          },
                          {
                            vis: "4",
                            val: "4",
                          },
                          {
                            vis: "5",
                            val: "5",
                          },
                        ],

                        "6/12",
                        "6/12"
                      )}
                    </div>
                    <div className="w-full md:w-1/4 px-1">
                      {this.renderSelect(
                        "escala_motora",
                        "Motora",
                        "text",
                        [
                          {
                            vis: "1",
                            val: "1",
                          },
                          {
                            vis: "2",
                            val: "2",
                          },
                          {
                            vis: "3",
                            val: "3",
                          },
                          {
                            vis: "4",
                            val: "4",
                          },
                          {
                            vis: "5",
                            val: "5",
                          },
                          {
                            vis: "6",
                            val: "6",
                          },
                        ],

                        "6/12",
                        "6/12"
                      )}
                    </div>
                    <div className="w-full md:w-1/4 px-1">
                      {this.renderSelect(
                        "escala_glasgow",
                        "Glasg",
                        "text",
                        [
                          {
                            vis: "1",
                            val: "1",
                          },
                          {
                            vis: "2",
                            val: "2",
                          },
                          {
                            vis: "3",
                            val: "3",
                          },
                          {
                            vis: "4",
                            val: "4",
                          },
                          {
                            vis: "5",
                            val: "5",
                          },
                          {
                            vis: "6",
                            val: "6",
                          },
                          {
                            vis: "7",
                            val: "7",
                          },
                          {
                            vis: "8",
                            val: "8",
                          },
                          {
                            vis: "9",
                            val: "9",
                          },
                          {
                            vis: "10",
                            val: "10",
                          },
                          {
                            vis: "11",
                            val: "11",
                          },
                          {
                            vis: "12",
                            val: "12",
                          },
                          {
                            vis: "13",
                            val: "13",
                          },
                          {
                            vis: "14",
                            val: "14",
                          },
                          {
                            vis: "15",
                            val: "15",
                          },
                        ],

                        "6/12",
                        "6/12"
                      )}
                    </div>
                  </div>
                  {this.renderSelect(
                    "codigo",
                    "Código",
                    "text",
                    [
                      {
                        vis: "VERDE",
                        val: "VERDE",
                      },
                      {
                        vis: "AMARILLO",
                        val: "AMARILLO",
                      },
                      {
                        vis: "ROJO",
                        val: "ROJO",
                      },
                      {
                        vis: "NEGRO",
                        val: "NEGRO",
                      },
                    ],

                    lw2,
                    iw2
                  )}
                </div>
                <div className="w-full md:w-1/2 py-1 px-3 space-y-1">
                  <div className="w-full text-center">
                    <span className="">
                      Exploración Física{" "}
                      <FontAwesomeIcon
                        className={"ml-2 cursor-pointer text-green-500"}
                        icon={["fas", "undo"]}
                        onClick={diss ? null : this.cleanFisicoData}
                      />
                    </span>
                  </div>

                  <div className="w-full flex flex-wrap justify-center">
                    <div className="w-full md:w-5/12 p-2">
                      <table
                        className="table-fixed shadow-md w-full"
                        style={{ fontSize: "9px" }}
                      >
                        <tbody className="py-2  border-2 border-blue-500">
                          {acronimos.map((i, k) => (
                            <tr
                              key={k}
                              className={
                                "border-b " + (k % 2 === 0 ? "bg-blue-50" : "")
                              }
                            >
                              <td>
                                <div className="w-full flex flew-wrap">
                                  <span className="w-1/12 text-center">
                                    {k + 1}
                                  </span>
                                  <span className="w-9/12">{i.vis}</span>
                                  <span className="w-2/12 text-center">
                                    {"(" + i.val + ")"}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="w-full md:w-5/12 py-2">
                      <div>
                        {data["fisico"].map((e, i) => (
                          <span
                            key={i}
                            className="absolute z-40 text-red-500 font-bold text-lg"
                            style={{
                              left:
                                (window.innerWidth <= 500
                                  ? e.x +
                                    25 +
                                    (this.props.prevDetails.windowsize >= 1100
                                      ? 55
                                      : 0)
                                  : this.props.prevDetails.windowsize <= 500 &&
                                    this.props.prevDetails.windowsize > 0
                                  ? e.x / 1.4 + 790
                                  : boundClientX + e.x - 65) + "px",
                              top:
                                (window.innerWidth <= 500
                                  ? e.y + 1180
                                  : this.props.prevDetails.windowsize <= 500 &&
                                    this.props.prevDetails.windowsize > 0
                                  ? e.y / 1.1 + 68
                                  : boundClientY + e.y - 35) + "px",
                            }}
                          >
                            {e.value === "New" ? "x" : e.value}
                          </span>
                        ))}
                        <img
                          src={bodyImage}
                          alt="BodyImage"
                          className="cursor-pointer rounded-md"
                          onLoad={this.setBound}
                          onClick={diss ? null : this.modifyBody}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-2/12 py-2">
                      <img src={eyesImage} alt="EyesImage" />
                    </div>
                  </div>
                  {coords ? (
                    <React.Fragment>
                      <div className="w-full flex flex-wrap">
                        <div className="w-full">
                          {this.renderSelect(
                            "opfisica",
                            "Selecciona:",
                            "text",
                            acronimos,
                            "4/12",
                            "8/12"
                          )}
                        </div>
                      </div>
                      <div className="w-full flex flex-wrap justify-center space-x-2">
                        <button
                          type="button"
                          className="btn-purple md:w-1/4"
                          onClick={this.setFisicoValue}
                        >
                          Agregar
                        </button>
                        <button
                          className="btn-red md:w-1/4"
                          onClick={this.deleteFisicoValue}
                        >
                          Cerrar
                        </button>
                      </div>
                    </React.Fragment>
                  ) : null}
                  <div className="w-full text-center text-xs">
                    <span className="">
                      *Da click en la imagen para seleccionar una opción
                    </span>
                  </div>
                  {this.renderSelect(
                    "pupilas",
                    "Pupilas",
                    "number",
                    [
                      {
                        vis: "1",
                        val: 1,
                      },
                      {
                        vis: "2",
                        val: 2,
                      },
                      {
                        vis: "3",
                        val: 3,
                      },
                      {
                        vis: "4",
                        val: 4,
                      },
                      {
                        vis: "5",
                        val: 5,
                      },
                    ],

                    lw2,
                    iw2
                  )}
                  {this.renderSelect(
                    "oxigeno",
                    "Oxígeno",
                    "text",
                    [
                      {
                        vis: "SI",
                        val: "SI",
                      },
                      {
                        vis: "NO",
                        val: "NO",
                      },
                    ],

                    lw2,
                    iw2
                  )}
                  {this.renderInputH("ltsmin", "LTS/MIN", "text", "", lw2, iw2)}
                  {this.renderInputH("metodo", "Método", "text", "", lw2, iw2)}
                  {this.renderTextArea(
                    "exfisica",
                    "Exploración Física",
                    "text",
                    "",
                    lw2,
                    iw2,
                    "4"
                  )}
                  {this.renderSelect(
                    "dolor",
                    "Escala de dolor",
                    "text",
                    [
                      {
                        vis: "1",
                        val: "1",
                      },
                      {
                        vis: "2",
                        val: "2",
                      },
                      {
                        vis: "3",
                        val: "3",
                      },
                      {
                        vis: "4",
                        val: "4",
                      },
                      {
                        vis: "5",
                        val: "5",
                      },
                      {
                        vis: "6",
                        val: "6",
                      },
                      {
                        vis: "7",
                        val: "7",
                      },
                      {
                        vis: "8",
                        val: "8",
                      },
                      {
                        vis: "9",
                        val: "9",
                      },
                      {
                        vis: "10",
                        val: "10",
                      },
                    ],

                    lw2,
                    iw2
                  )}
                </div>
                <div className="flex space-x-4 justify-center w-full pt-3">
                  <div className="w-1/2 md:w-2/12">
                    <button
                      className="btn-success w-full"
                      type="button"
                      onClick={this.sendData}
                    >
                      Guardar
                    </button>
                  </div>
                  <div className="w-1/2 md:w-2/12">
                    <button
                      className="btn-red w-full"
                      type="button"
                      onClick={() => this.props.onClose()}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EvSecundaria;

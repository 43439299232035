import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="invisible md:visible sm:fixed inset-x-0 bottom-0 h-8 text-center z-10">
        <span className="w-full align-middle text-xs text-gray-600 font-semibold">
          © {new Date().getFullYear()}, FrijolitoDev. Todos los derechos
          reservados.
        </span>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

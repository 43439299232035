import React from "react";
import FormRules from "./formRules";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import Joi from "joi-browser";
import _ from "lodash";
//Importar Componentes
import LoadingComp from "../tools/loadingComp";
//Importar Servicios
import { getLists } from "./../../services/listaService";
import { postCall } from "../../services/llamadaService";

class NewCall extends FormRules {
  state = {
    data: { idOperador: 1, tipo: 1, idCliente: 0 },
    errors: {},
    listas: [],
  };

  schema = {
    idOperador: Joi.number().min(1).required().label("Operador"),
    tipo: Joi.number().min(1).optional().label("Tipo"),
    idCliente: Joi.number().optional().label("idCliente"),
  };

  componentDidMount = async () => {
    try {
      const { status, data } = await trackPromise(getLists());
      if (status === 200) {
        const info = { ...this.state.data };
        const operador = _.filter(data, { lista: "Radio operador" });
        const id =
          this.props.user?.tipo === "Gerencia"
            ? 0
            : this.props.user?.tipo === "Coordinador"
            ? 2
            : this.props.user?.tipo === "Radioperaciones"
            ? 3
            : 1;
        info["idOperador"] = operador[id]["val"];
        this.setState({ listas: data, data: info });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {}
  };

  doSubmit = async () => {
    const { data, listas } = this.state;
    if (data.tipo !== 3 && data.idCliente === 0) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Selecciona un cliente",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    } else {
      try {
        const res = await trackPromise(postCall(data));
        if (res.status === 200) {
          const idLlamadas = res.data.insertId;
          const Tipo = _.filter(listas, {
            lista: "Tipo_Cliente",
            val: data.tipo,
          })[0].vis;
          const NombreFinal =
            Tipo === "PARTICULAR"
              ? "CLIENTE PARTICULAR"
              : _.filter(listas, { lista: Tipo, val: data.idCliente })[0].vis;
          const idCliente = data.idCliente;
          const now = new Date();
          const fecha =
            (String(now.getDate()).length === 1
              ? "0" + now.getDate()
              : now.getDate()) +
            "/" +
            (String(now.getMonth() + 1).length === 1
              ? "0" + (now.getMonth() + 1)
              : now.getMonth() + 1) +
            "/" +
            now.getFullYear();
          const hora =
            now.getHours() +
            ":" +
            (String(now.getMinutes()).length === 1
              ? "0" + now.getMinutes()
              : now.getMinutes()) +
            ":" +
            now.getSeconds();
          const Status = "ACTIVO";
          const idFrap = "";

          const call = {
            idLlamadas,
            Tipo,
            NombreFinal,
            idCliente,
            fecha,
            hora,
            EdadP: 0,
            Status,
            idFrap,
            newReg: true,
          };
          this.props.onInsert(call);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Llamada agregada con éxito",
            showConfirmButton: false,
            timer: 1800,
            toast: true,
          });
        }
      } catch (err) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    }
  };

  closeModal = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  render() {
    const { listas, data } = this.state;
    const tipoSel = listas
      ? _.filter(listas, {
          lista: "Tipo_Cliente",
          val: data.tipo,
        })
      : [];

    return (
      <React.Fragment>
        <div className="modal-back z-20">
          <LoadingComp />
          <div className="px-2">
            <div className="modal-box">
              <span className="modal-title">Captura de Llamadas</span>
              <form className="pt-5 space-y-3">
                {this.renderSelect(
                  "idOperador",
                  "Radioperador:",
                  "number",
                  _.orderBy(
                    _.filter(listas, { lista: "Radio operador" }),
                    "val",
                    "asc"
                  ),
                  "1/4",
                  "3/4"
                )}

                {this.renderSelect(
                  "tipo",
                  "Tipo cliente:",
                  "number",
                  _.orderBy(
                    _.filter(listas, { lista: "Tipo_Cliente" }),
                    "vis",
                    "asc"
                  ),
                  "1/4",
                  "3/4"
                )}

                {data.tipo !== 3
                  ? this.renderSelect(
                      "idCliente",
                      "Cliente:",
                      "number",
                      _.orderBy(
                        _.filter(listas, {
                          lista:
                            tipoSel.length === 1 ? tipoSel[0].vis : "EMPTY",
                        }),
                        "vis",
                        "asc"
                      ),
                      "1/4",
                      "3/4"
                    )
                  : null}

                <div className="flex flex-wrap w-full pt-3 space-x-2 justify-center">
                  <button
                    className="btn-success w-1/4"
                    onClick={this.handleSubmit}
                  >
                    Registrar
                  </button>
                  <button className="btn-red w-1/4" onClick={this.closeModal}>
                    Cerrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewCall;

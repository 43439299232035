import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EvInicial extends FormRules {
  constructor(props) {
    super(props);
    this.state = {
      data: props.prevDetails
        ? props.prevDetails
        : {
            conciencia: "",
            vaerea: "",
            deglucion: "",
            ventilacion: "",
            observacion: "",
            auscultacion: "",
            hemotorax: "",
            sitio: "",
            circulacion: "",
            pulsos: "",
            calidad: "",
            piel: "",
            caracteristicas: "",
          },
      errors: {},
      diss: props.disabled,
      //listasTx: props.listasTx ? props.listasTx : [],
      //*Agregar lista en caso de que sea necesario desde la BD.
    };
  }

  schema = {
    conciencia: Joi.optional(),
    vaerea: Joi.optional(),
    deglucion: Joi.optional(),
    ventilacion: Joi.optional(),
    observacion: Joi.optional(),
    auscultacion: Joi.optional(),
    hemotorax: Joi.optional(),
    sitio: Joi.optional(),
    circulacion: Joi.optional(),
    pulsos: Joi.optional(),
    calidad: Joi.optional(),
    piel: Joi.optional(),
    caracteristicas: Joi.optional(),
  };

  sendData = () => {
    const data = { ...this.state.data };
    this.props.onSubmit(data);
    this.props.onClose();
  };

  render() {
    const lw2 = "5/12"; //Label width for two in same row
    const iw2 = "7/12"; //Input width for two in same row
    return (
      <div className="modal-back z-30">
        <div className="px-2">
          <div className="modal-box md:w-10/12 top-10">
            <span className="modal-title">
              <FontAwesomeIcon
                className={"mr-2 cursor-pointer text-green-500"}
                icon={["far", "save"]}
                onClick={this.sendData}
              />
              Evaluación Inicial
              <FontAwesomeIcon
                className="ml-2 text-red-500 cursor-pointer"
                icon={["far", "times-circle"]}
                onClick={() => this.props.onClose()}
              />
            </span>
            <form className="pt-3 flex flex-wrap">
              <div className="w-full md:w-1/3 py-1 px-3 space-y-3">
                <div className="w-full text-center">
                  <span className="">General</span>
                </div>
                {this.renderSelect(
                  "conciencia",
                  "Nivel de conciencia",
                  "text",
                  [
                    {
                      vis: "CONSCIENTE",
                      val: "CONSCIENTE",
                    },
                    {
                      vis: "RESPUESTA A ESTÍMULO VERBAL",
                      val: "RESPUESTA A ESTÍMULO VERBAL",
                    },
                    {
                      vis: "RESPUESTA A ESTÍMULO DOLOROSO",
                      val: "RESPUESTA A ESTÍMULO DOLOROSO",
                    },
                    {
                      vis: "INCONSCIENTE",
                      val: "INCONSCIENTE",
                    },
                  ],

                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "vaerea",
                  "Via aérea",
                  "text",
                  [
                    {
                      vis: "PERMEABLE",
                      val: "PERMEABLE",
                    },
                    {
                      vis: "COMPROMETIDA",
                      val: "COMPROMETIDA",
                    },
                  ],

                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "deglucion",
                  "Reflejo de deglución",
                  "text",
                  [
                    {
                      vis: "AUSENTE",
                      val: "AUSENTE",
                    },
                    {
                      vis: "PRESENTE",
                      val: "PRESENTE",
                    },
                  ],

                  lw2,
                  iw2
                )}
              </div>
              <div className="w-full md:w-1/3 py-1 px-3 space-y-3">
                <div className="w-full text-center">
                  <span className="">Ventilación</span>
                </div>
                {this.renderSelect(
                  "observacion",
                  "Observación",
                  "text",
                  [
                    {
                      vis: "AUTOMATISMO REGULAR",
                      val: "AUTOMATISMO REGULAR",
                    },
                    {
                      vis: "AUTOMATISMO IRREGULAR",
                      val: "AUTOMATISMO IRREGULAR",
                    },
                    {
                      vis: "VENTILACIÓN RÁPIDA",
                      val: "VENTILACIÓN RÁPIDA",
                    },
                    {
                      vis: "VENTILACIÓN SUPERFICIAL",
                      val: "VENTILACIÓN SUPERFICIAL",
                    },
                    {
                      vis: "APNEA",
                      val: "APNEA",
                    },
                  ],
                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "auscultacion",
                  "Auscultación",
                  "text",
                  [
                    {
                      vis: "RUIDOS RESPIRATORIOS NORMALES",
                      val: "RUIDOS RESPIRATORIOS NORMALES",
                    },
                    {
                      vis: "RUIDOS RESPIRATORIOS DISMINUIDOS",
                      val: "RUIDOS RESPIRATORIOS DISMINUIDOS",
                    },
                    {
                      vis: "RUIDOS RESPIRATORIOS AUSENTES",
                      val: "RUIDOS RESPIRATORIOS AUSENTES",
                    },
                  ],
                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "hemotorax",
                  "Hemitórax",
                  "text",
                  [
                    {
                      vis: "DERECHO",
                      val: "DERECHO",
                    },
                    {
                      vis: "IZQUIERDO",
                      val: "IZQUIERDO",
                    },
                  ],
                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "sitio",
                  "Sitio",
                  "text",
                  [
                    {
                      vis: "APICAL",
                      val: "APICAL",
                    },
                    {
                      vis: "BASE",
                      val: "BASE",
                    },
                  ],
                  lw2,
                  iw2
                )}
              </div>
              <div className="w-full md:w-1/3 py-1 px-3 space-y-3">
                <div className="w-full text-center">
                  <span className="">Circulación</span>
                </div>
                {this.renderSelect(
                  "pulsos",
                  "Presencia de pulsos",
                  "text",
                  [
                    {
                      vis: "CAROTÍDEO",
                      val: "CAROTÍDEO",
                    },
                    {
                      vis: "RADIAL",
                      val: "RADIAL",
                    },
                    {
                      vis: "PARO CARDIORESPIRATORIO",
                      val: "PARO CARDIORESPIRATORIO",
                    },
                  ],
                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "calidad",
                  "Calidad",
                  "text",
                  [
                    {
                      vis: "RÁPIDO",
                      val: "RÁPIDO",
                    },
                    {
                      vis: "LENTO",
                      val: "LENTO",
                    },
                    {
                      vis: "RÍTMICO",
                      val: "RÍTMICO",
                    },
                    {
                      vis: "ARRÍTMICO",
                      val: "ARRÍTMICO",
                    },
                  ],
                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "piel",
                  "Piel",
                  "text",
                  [
                    {
                      vis: "NORMAL",
                      val: "NORMAL",
                    },
                    {
                      vis: "PÁLIDA",
                      val: "PÁLIDA",
                    },
                    {
                      vis: "CIANÓTICA",
                      val: "CIANÓTICA",
                    },
                  ],
                  lw2,
                  iw2
                )}
                {this.renderSelect(
                  "caracteristicas",
                  "Características",
                  "text",
                  [
                    {
                      vis: "CALIENTE",
                      val: "CALIENTE",
                    },
                    {
                      vis: "FRÍA",
                      val: "FRÍA",
                    },
                    {
                      vis: "DIAFORESIS",
                      val: "DIAFORESIS",
                    },
                  ],
                  lw2,
                  iw2
                )}
              </div>
              <div className="flex space-x-4 justify-center w-full pt-3">
                <div className="w-1/2 md:w-2/12">
                  <button
                    className="btn-success w-full"
                    type="button"
                    onClick={this.sendData}
                  >
                    Guardar
                  </button>
                </div>
                <div className="w-1/2 md:w-2/12">
                  <button
                    className="btn-red w-full"
                    type="button"
                    onClick={() => this.props.onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EvInicial;

import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import _ from "lodash";
import fileDownload from "js-file-download";
//Importar Componentes
import LoadingComp from "../tools/loadingComp";
import EvPaciente from "./evPaciente";
import EvInicial from "./evInicial";
import EvSecundaria from "./evSecundaria";
import HistClinica from "./histClinica";
import Firmas from "./firmas";
//Importar Servicios
import { getLists, getListsTx } from "./../../services/listaService";
import { postFrap, updateFrap } from "../../services/frapService";
import { getPdf, createPdf } from "./../../services/createPdf";
import { getPrices, getTab } from "../../services/tabulador";

class NewFrap extends FormRules {
  state = {
    data: {
      idFrap: "",
      id_Empleado: 0,
      id_Empleado2: 0,
      id_Empleado3: 0,
      Fecha_Frap: "",
      Hora_Llamada: "",
      Hora_Salida: "",
      Hora_Llegada: "",
      Hora_Traslado: "",
      Hora_Destino: "",
      Hora_Termino: "",
      Lugar_Inicio: "",
      Motivo: "",
      Lugar_Destino: "",
      Tipo_Cliente: 0,
      Tipo_Servicio: "",
      idCliente: 0,
      Paciente: "",
      Edad: 0,
      Estado_Civil: "",
      Servicio_Med: "",
      Genero: "",
      EvaluacionPX: "",
      Impresion: "",
      Manejo1: "",
      Manejo2: "",
      Manejo3: "",
      Manejo4: "",
      Indicaciones1: "",
      Indicaciones2: "",
      Indicaciones3: "",
      Indicaciones4: "",
      Traslado: "",
      Costo: 0,
      Status_Pago: "",
      Pago_Servicio: 0,
      Fecha_Recibo: "",
      Recibo: "",
      Tipo_Pago: "",
      Movimiento: "",
      Status_Factura: "",
      Factura: "",
      Fecha_Admin: "",
      Comentarios: "",
      Cobranza: "",
      Validado: 0,
      ambulancia: 0,
      //New Fields 23/11/2021
      agente_causal: "",
      origen_probable: "",
      especifique: "",
      pv_sub: "",
      direccion: "",
      colonia: "",
      ciudad: "",
      meses: 0,
      telefono: "",
      conciencia: "",
      vaerea: "",
      deglucion: "",
      observacion: "",
      auscultacion: "",
      hemotorax: "",
      sitio: "",
      pulsos: "",
      calidad: "",
      piel: "",
      caracteristicas: "",
      alergias: "",
      enfermedadesprev: "",
      medicamentos: "",
      uingesta: "",
      descripcionev: "",
      hora_inicioreg: "",
      hora_arrdest: "",
      hora_base: "",
      hora_despacho: "",
      lugar_destino_otro: "",
      validadoger: 0,
      fecha_gerencia: "",
      oxigeno: "",
      ltsmin: "",
      metodo: "",
      exfisica: "",
      dolor: "",
      esferas: [],
      escala_ocular: "",
      escala_verbal: "",
      escala_motora: "",
      escala_glasgow: "",
      codigo: "",
      fisico: [],
      opfisica: "",
      bvm: "",
      frecuencia: "",
      volument: "",
      pupilas: 0,
      windowsize: 0,
      tblvital: [
        {
          hora: "",
          minutos: "",
          fr: "",
          fc: "",
          ta: "",
          sao2: "",
          temp: "",
          gluc: "",
          examen: "",
        },
        {
          hora: "",
          minutos: "",
          fr: "",
          fc: "",
          ta: "",
          sao2: "",
          temp: "",
          gluc: "",
          examen: "",
        },
        {
          hora: "",
          minutos: "",
          fr: "",
          fc: "",
          ta: "",
          sao2: "",
          temp: "",
          gluc: "",
          examen: "",
        },
      ],
      entrega_paciente: "",
      recibe_paciente: "",
      firma_paciente: "",
      firma_lugar: "",
      observaciones: "",
      tblpagos: [
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
      ],
    },
    errors: {},
    listas: [],
    listasTx: [],
    precios: [],
    tabulador: [],
    dw: { label: "1/4", input: "3/4" },
    details: "",
    updateId: 0,
    statusCliente: "ACTIVO",
    NombreFinal: "",
    prevIdCliente: 0,
    evInicial: false,
    evSecundaria: false,
    evPaciente: false,
    histClinica: false,
    firmas: false,
    diss: false,
    user: "",
  };

  schema = {
    idFrap: Joi.optional().label("IdFrap"),
    id_Empleado: Joi.number().min(1).required().label("Operador"),
    id_Empleado2: Joi.number().min(1).required().label("Paramedico"),
    id_Empleado3: Joi.optional().label("Paramedico Extra"),
    Fecha_Frap: Joi.string().required().label("Fecha Frap"),
    ambulancia: Joi.number().required().label("Ambulancia"),
    Hora_Llamada: Joi.optional().label("Hora Llamada"),
    Hora_Salida: Joi.optional().label("Hora Salida"),
    Hora_Llegada: Joi.optional().label("Hora Llegada"),
    Hora_Traslado: Joi.optional().label("Hora Traslado"),
    Hora_Destino: Joi.optional().label("Hora Destino"),
    Hora_Termino: Joi.optional().label("Hora Termino"),
    Lugar_Inicio: Joi.string().required().label("Lugar Atención"),
    Motivo: Joi.string().required().label("Motivo"),
    Lugar_Destino: Joi.string().required().label("Lugar Destino"),
    Tipo_Cliente: Joi.number().min(1).required().label("Tipo_Cliente"),
    Tipo_Servicio: Joi.string().required().label("Servicio"),
    idCliente: Joi.number().required().label("Cliente"),
    Paciente: Joi.string().required().label("Paciente"),
    Edad: Joi.optional().label("Edad"),
    Estado_Civil: Joi.optional().label("Estado Civil"),
    Servicio_Med: Joi.optional().label("Servicio Med"),
    Genero: Joi.optional().label("Genero"),
    EvaluacionPX: Joi.optional().label("Evaluación PX"),
    Impresion: Joi.optional().label("Impresión"),
    Manejo1: Joi.optional().label("Manejo #1"),
    Manejo2: Joi.optional().label("Manejo #2"),
    Manejo3: Joi.optional().label("Manejo #3"),
    Manejo4: Joi.optional().label("Manejo #4"),
    Indicaciones1: Joi.optional().label("Indicaciones #1"),
    Indicaciones2: Joi.optional().label("Indicaciones #2"),
    Indicaciones3: Joi.optional().label("Indicaciones #3"),
    Indicaciones4: Joi.optional().label("Indicaciones #4"),
    Traslado: Joi.string().required().label("Traslado"),
    Costo: Joi.number().min(0).required().label("Costo"),
    Status_Pago: Joi.string().required().label("Status Pago"),
    Pago_Servicio: Joi.optional().label("Pago de Servicio"),
    Fecha_Recibo: Joi.optional().label("Fecha de Recibo"),
    Recibo: Joi.optional().label("Recibo"),
    Tipo_Pago: Joi.optional().label("Forma Pago"),
    Movimiento: Joi.optional().label("Movimiento"),
    Status_Factura: Joi.optional().label("Status de Factura"),
    Factura: Joi.optional().label("Factura"),
    Fecha_Admin: Joi.optional().label("Fecha Admin"),
    Comentarios: Joi.optional().label("Comentarios"),
    Cobranza: Joi.optional().label("Cobranza"),
    Validado: Joi.optional().label("Validado"),
    //New Fileds 23/11/2021
    agente_causal: Joi.optional().label("agente_causal"),
    origen_probable: Joi.optional().label("origen_probable"),
    especifique: Joi.optional().label("especifique"),
    pv_sub: Joi.optional().label("pv_sub"),
    direccion: Joi.optional().label("direccion"),
    colonia: Joi.optional().label("colonia"),
    ciudad: Joi.optional().label("ciudad"),
    meses: Joi.optional().label("meses"),
    telefono: Joi.optional().label("telefono"),
    conciencia: Joi.optional(),
    vaerea: Joi.optional(),
    deglucion: Joi.optional(),
    observacion: Joi.optional(),
    auscultacion: Joi.optional(),
    hemotorax: Joi.optional(),
    sitio: Joi.optional(),
    pulsos: Joi.optional(),
    calidad: Joi.optional(),
    piel: Joi.optional(),
    caracteristicas: Joi.optional(),
    alergias: Joi.optional(),
    enfermedadesprev: Joi.optional(),
    medicamentos: Joi.optional(),
    uingesta: Joi.optional(),
    descripcionev: Joi.optional(),
    hora_inicioreg: Joi.optional(),
    hora_arrdest: Joi.optional(),
    hora_base: Joi.optional(),
    hora_despacho: Joi.optional(),
    lugar_destino_otro: Joi.optional(),
    validadoger: Joi.optional(),
    fecha_gerencia: Joi.optional(),
    oxigeno: Joi.optional(),
    ltsmin: Joi.optional(),
    metodo: Joi.optional(),
    exfisica: Joi.optional(),
    dolor: Joi.optional(),
    esferas: Joi.optional(),
    escala_ocular: Joi.optional(),
    escala_verbal: Joi.optional(),
    escala_motora: Joi.optional(),
    escala_glasgow: Joi.optional(),
    codigo: Joi.optional(),
    fisico: Joi.optional(),
    opfisica: Joi.optional(), //delete
    bvm: Joi.optional(),
    frecuencia: Joi.optional(),
    volument: Joi.optional(),
    pupilas: Joi.optional(),
    tblvital: Joi.optional(),
    windowsize: Joi.optional(),
    entrega_paciente: Joi.optional(),
    recibe_paciente: Joi.optional(),
    firma_paciente: Joi.optional(),
    firma_lugar: Joi.optional(),
    observaciones: Joi.optional(),
    tblpagos: Joi.optional(),
    Tipo_Cliente_Nombre: Joi.optional(), //delete
  };

  signEntrega = {};

  componentDidMount = async () => {
    try {
      const { status, data: listas } = await trackPromise(getLists());
      const { status: statusTx, data: listasTx } = await trackPromise(
        getListsTx()
      );
      const { status: statusPrice, data: precios } = await trackPromise(
        getPrices()
      );
      const { status: statusTab, data: tabulador } = await trackPromise(
        getTab()
      );
      if (
        status === 200 &&
        statusTx === 200 &&
        statusPrice === 200 &&
        statusTab === 200
      ) {
        if (this.props.type === "u") {
          const data = { ...this.props.data };
          const {
            idTransaction: updateId,
            Status_Cliente: statusCliente,
            NombreFinal,
            idCliente: prevIdCliente,
          } = data;
          /*Mostar en listas los clientes o servicios inactivos*/
          if (statusCliente === "INACTIVO") {
            listas.push({
              val: prevIdCliente,
              vis: NombreFinal,
              lista: data["Tipo_Cliente_Nombre"],
            });
          }

          const checkServicio = _.filter(listasTx, {
            lista: "tipo_servicio",
            val: data["Tipo_Servicio"],
          });
          if (checkServicio.length < 1) {
            const inactiveService = _.filter(listasTx, {
              lista: "tipo_servicio_inct",
              val: data["Tipo_Servicio"],
            });
            const obj = { ...inactiveService[0] };
            obj["lista"] = "tipo_servicio";
            listasTx.push(obj);
          }
          /*Mostar en listas los clientes o servicios inactivos*/

          delete data.idTransaction;
          delete data.Status_Cliente;
          delete data.NombreFinal;
          data["Fecha_Frap"] =
            data.Fecha_Frap === ""
              ? ""
              : data.Fecha_Frap.substr(6, 4) +
                "-" +
                data.Fecha_Frap.substr(3, 2) +
                "-" +
                data.Fecha_Frap.substr(0, 2);
          data["Fecha_Admin"] =
            data.Fecha_Admin === ""
              ? ""
              : data.Fecha_Admin.substr(6, 4) +
                "-" +
                data.Fecha_Admin.substr(3, 2) +
                "-" +
                data.Fecha_Admin.substr(0, 2);
          data["fecha_gerencia"] =
            data.fecha_gerencia === ""
              ? ""
              : data.fecha_gerencia.substr(6, 4) +
                "-" +
                data.fecha_gerencia.substr(3, 2) +
                "-" +
                data.fecha_gerencia.substr(0, 2);
          //JSON Parse Array Variables and Add opfisica
          data["opfisica"] = "";
          data["esferas"] =
            data["esferas"] === "" ? [] : JSON.parse(data["esferas"]);
          data["fisico"] =
            data["fisico"] === "" ? [] : JSON.parse(data["fisico"]);
          data["tblvital"] =
            data["tblvital"] === "" || data["tblvital"] === null
              ? [
                  {
                    hora: "",
                    minutos: "",
                    fr: "",
                    fc: "",
                    ta: "",
                    sao2: "",
                    temp: "",
                    gluc: "",
                    examen: "",
                  },
                  {
                    hora: "",
                    minutos: "",
                    fr: "",
                    fc: "",
                    ta: "",
                    sao2: "",
                    temp: "",
                    gluc: "",
                    examen: "",
                  },
                  {
                    hora: "",
                    minutos: "",
                    fr: "",
                    fc: "",
                    ta: "",
                    sao2: "",
                    temp: "",
                    gluc: "",
                    examen: "",
                  },
                ]
              : JSON.parse(data["tblvital"]);
          /*data["entrega_paciente"] =
            data["entrega_paciente"] === ""
              ? []
              : JSON.parse(data["entrega_paciente"]);
          data["recibe_paciente"] =
            data["recibe_paciente"] === ""
              ? []
              : JSON.parse(data["recibe_paciente"]);
          data["firma_paciente"] =
            data["firma_paciente"] === ""
              ? []
              : JSON.parse(data["firma_paciente"]);
          data["firma_lugar"] =
            data["firma_lugar"] === "" ? [] : JSON.parse(data["firma_lugar"]);*/
          //No need to parse JSON since we use DataURL
          data["tblpagos"] =
            data["tblpagos"] === "" || data["tblpagos"] === null
              ? [
                  {
                    fecha: "",
                    monto: 0,
                    movimiento: "",
                    forma: "",
                  },
                  {
                    fecha: "",
                    monto: 0,
                    movimiento: "",
                    forma: "",
                  },
                  {
                    fecha: "",
                    monto: 0,
                    movimiento: "",
                    forma: "",
                  },
                  {
                    fecha: "",
                    monto: 0,
                    movimiento: "",
                    forma: "",
                  },
                ]
              : JSON.parse(data["tblpagos"]);
          //JSON Parse Array Variables and Add opfisica
          const servicioUrgencia = [1, 9, 15, 16];
          if (servicioUrgencia.includes(data["Tipo_Cliente"])) {
            data["Status_Factura"] =
              data["Status_Factura"] === ""
                ? "SIN FACTURA"
                : data["Status_Factura"];
            data["Tipo_Servicio"] =
              data["Tipo_Servicio"] === "" ? "URGENCIA" : data["Tipo_Servicio"];
          }
          if (data["Tipo_Cliente"] === 2) {
            data["Tipo_Servicio"] =
              data["Tipo_Servicio"] === ""
                ? "URGENCIA ESCOLAR"
                : data["Tipo_Servicio"];
          }
          //Carga default de SIN COSTO y SIN FACTURA
          /*Revisar si ya se encuentra validado y que solo lo pueda modificar Regular*/
          const tipoAllAcc = ["Admin", "Gerencia"];
          const blockUsers = ["Administracion", "Facturacion"];
          const diss =
            !tipoAllAcc.includes(this.props.user?.tipo) &&
            (data["Validado"] === 1 || data["validadorger"] === 1)
              ? true
              : blockUsers.includes(this.props.user?.tipo)
              ? true
              : false;

          this.setState({
            listas,
            listasTx,
            precios,
            tabulador,
            data,
            updateId,
            statusCliente,
            NombreFinal,
            prevIdCliente,
            diss,
            user: this.props.user?.tipo,
          });
        } else if (this.props.type === "c") {
          const callInfo = { ...this.props.data };
          const data = { ...this.state.data };
          data["Costo"] = callInfo["Costo"];
          data["Fecha_Frap"] = callInfo["Fecha_Frap"];
          data["Hora_Llamada"] = callInfo["Hora_Llamada"];
          data["Lugar_Inicio"] = callInfo["Lugar_Inicio"];
          data["Lugar_Destino"] = callInfo["Lugar_Destino"];
          data["Motivo"] = callInfo["Motivo"];
          data["Paciente"] = callInfo["Paciente"];
          data["Edad"] = callInfo["Edad"];
          data["Status_Pago"] = callInfo["Status_Pago"];
          data["Tipo_Servicio"] = callInfo["Tipo_Servicio"];
          data["Tipo_Cliente"] = _.filter(listas, {
            lista: "Tipo_Cliente",
            vis: callInfo["Tipo_Cliente"],
          })[0].val;
          data["idCliente"] = callInfo["idCliente"];
          data["idFrap"] = "A" + (parseInt(callInfo["idTransaction"]) + 10000);
          const updateId = callInfo["idTransaction"];

          const servicioUrgencia = [1, 9, 15, 16];
          if (servicioUrgencia.includes(data["Tipo_Cliente"])) {
            data["Status_Factura"] = "SIN FACTURA";
            data["Tipo_Servicio"] =
              data["Tipo_Servicio"] === undefined
                ? "URGENCIA"
                : data["Tipo_Servicio"];
          }
          if (data["Tipo_Cliente"] === 2) {
            data["Tipo_Servicio"] =
              data["Tipo_Servicio"] === undefined
                ? "URGENCIA ESCOLAR"
                : data["Tipo_Servicio"];
          }

          this.setState({
            listas,
            listasTx,
            precios,
            tabulador,
            data,
            updateId,
          });
        } else {
          this.setState({ listas, listasTx, precios, tabulador });
        }
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
      //console.log(err);
    }
  };

  showDetails = (details) => {
    this.setState({ details });
  };

  updateDetails = (details) => {
    const keys = _.keys(details);
    const data = { ...this.state.data };
    keys.forEach((name) => {
      data[name] = details[name];
    });
    this.setState({ data });
    //this.showDetails("");
  };

  handleTableChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    let name = input.name;
    const index = parseInt(name.charAt(name.length - 1));
    name = name.slice(0, -1);
    data["tblpagos"][index][name] = input.value;
    this.setState({ data });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  modalEvInicial = () => {
    const evInicial = !this.state.evInicial;
    this.setState({ evInicial });
  };

  modalEvSecundaria = () => {
    const evSecundaria = !this.state.evSecundaria;
    this.setState({ evSecundaria });
  };

  modalEvPaciente = () => {
    const evPaciente = !this.state.evPaciente;
    this.setState({ evPaciente });
  };

  modalHistClinica = () => {
    const histClinica = !this.state.histClinica;
    this.setState({ histClinica });
  };

  modalFirmas = () => {
    const firmas = !this.state.firmas;
    this.setState({ firmas });
  };

  formatData = (data, id, action) => {
    const nFrap = { ...data };
    const {
      listas,
      prevIdCliente,
      statusCliente,
      NombreFinal: prevName,
    } = this.state;
    const Tipo = _.filter(listas, {
      lista: "Tipo_Cliente",
      val: data.Tipo_Cliente,
    })[0].vis;
    const NombreFinal =
      statusCliente === "INACTIVO" && prevIdCliente === data.idCliente
        ? prevName
        : Tipo === "PARTICULAR"
        ? nFrap["Paciente"] === ""
          ? "CLIENTE PARTICULAR"
          : nFrap["Paciente"]
        : _.filter(listas, {
            lista: Tipo,
            val: data.idCliente,
          })[0].vis;
    nFrap["idTransaction"] = id;
    nFrap["idFrap"] =
      action === "I" ? "A" + (parseInt(id) + 10000) : nFrap["idFrap"];
    nFrap["ValidadoTx"] = nFrap["Validado"] < 1 ? "INCOMPLETO" : "COMPLETO";
    nFrap["Status_Cliente"] =
      statusCliente === "INACTIVO" && prevIdCliente === data.idCliente
        ? "INACTIVO"
        : "ACTIVO";
    nFrap["NombreFinal"] = NombreFinal;
    nFrap["Tipo_Cliente_Nombre"] = Tipo;
    nFrap["Status"] =
      data.Status_Pago === "PAGADO"
        ? "ACTIVO"
        : data.Status_Pago === "PTE PAGO"
        ? "CANCELADO"
        : "CERRADO";
    nFrap["Fecha_Frap"] =
      data.Fecha_Frap === "" ? "" : this.formatDate(data.Fecha_Frap);
    nFrap["Fecha_Admin"] =
      data.Fecha_Admin === "" ? "" : this.formatDate(data.Fecha_Admin);
    nFrap["fecha_gerencia"] =
      data.fecha_gerencia === "" ? "" : this.formatDate(data.fecha_gerencia);
    return nFrap;
  };

  formatDate = (fecha) => {
    return (
      fecha.substr(8, 2) + "/" + fecha.substr(5, 2) + "/" + fecha.substr(0, 4)
    );
  };

  createPdf = async () => {
    try {
      const { data, listas } = this.state;
      const listval = {
        operador: _.filter(listas, {
          lista: "Operador-all",
          val: data.id_Empleado,
        }),
        paramedico: _.filter(listas, {
          lista: "Paramedico-all",
          val: data.id_Empleado2,
        }),
        paramedico_extra: _.filter(listas, {
          lista: "Paramedico-all",
          val: data.id_Empleado3,
        }),
        tipoCliente: _.filter(listas, {
          lista: "Tipo_Cliente",
          val: data.Tipo_Cliente,
        }),
      };
      let body = { ...this.state.data };
      body["operador"] = listval.operador[0].vis;
      body["paramedico"] = listval.paramedico[0].vis;
      body["paramedico_extra"] =
        data.id_Empleado3 > 0 ? listval.paramedico_extra[0].vis : "";
      body["tipoCliente"] = listval.tipoCliente[0].vis;
      body["nombreFinal"] = this.state.NombreFinal;
      const res = await trackPromise(createPdf(body));
      if (res.status === 200) {
        const resFile = await trackPromise(getPdf());
        if (res.status === 200) {
          //console.log(resFile.data);
          const pdfBlob = new Blob([resFile.data], {
            type: "application/pdf;charset=utf-8",
          });
          fileDownload(pdfBlob, body["idFrap"] + ".pdf");
        }
      }
    } catch (err) {
      console.log("ERROR AL DESCARGAR PDF");
      //console.log(err);
    }
  };

  validateTime = (arr, tipo) => {
    let flag = true;
    const limit = tipo === "h" ? 24 : 60;
    for (let i = 0; i < arr.length; i++) {
      const value = arr[i].value !== "" ? parseInt(arr[i].value) : 0;
      if (value >= limit) {
        flag = false;
      }
    }
    return flag;
  };

  doSubmit = async () => {
    const data = { ...this.state.data };
    const procType = this.props.type;
    const hours = document.getElementsByClassName(
      "react-time-picker__inputGroup__input react-time-picker__inputGroup__hour"
    );
    const minutes = document.getElementsByClassName(
      "react-time-picker__inputGroup__input react-time-picker__inputGroup__minute"
    );
    const valH = this.validateTime(hours, "h");
    const valM = this.validateTime(minutes, "m");
    if (data.Tipo_Cliente !== 3 && data.idCliente === 0) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Selecciona un cliente",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    } else if (
      data.Status_Factura === "FACTURADO" &&
      (data.Factura.trim() === "" || data.Factura === "0")
    ) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Capturar Factura",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    } else if (!valH || !valM) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Verificar Horas",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    } else {
      try {
        //JSON Stringify Array Variables and Delete opfisica and Tipo_Cliente_Nombre
        delete data.opfisica;
        delete data.Tipo_Cliente_Nombre;
        data["esferas"] = JSON.stringify(data["esferas"]);
        data["fisico"] = JSON.stringify(data["fisico"]);
        data["tblvital"] = JSON.stringify(data["tblvital"]);
        /*data["entrega_paciente"] = JSON.stringify(data["entrega_paciente"]);
            data["recibe_paciente"] = JSON.stringify(data["recibe_paciente"]);
            data["firma_paciente"] = JSON.stringify(data["firma_paciente"]);
            data["firma_lugar"] = JSON.stringify(data["firma_lugar"]);*/
        data["tblpagos"] = JSON.stringify(data["tblpagos"]);
        //JSON Stringify Array Variables and Delete opfisica
        if (procType === "n") {
          //const frapVal = await trackPromise(validateFrap(data.idFrap));
          //if (frapVal.status === 200 && frapVal.data.length < 1) {
          data["idFrap"] = "NewFrap";
          const res = await trackPromise(postFrap(data));
          if (res.status === 200) {
            //Insert on table information
            const nFrap = this.formatData(data, res.data.insertId, "I");
            this.props.onInsert(nFrap);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Frap agregado con éxito",
              showConfirmButton: false,
              timer: 1800,
              toast: true,
            });
          }
          /*} else {
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "El FRAP ya se encuentra registrado",
                  showConfirmButton: false,
                  timer: 2500,
                  toast: true,
                });
              }*/
        } else {
          //Update de FRAP
          data["idTransaction"] = this.state.updateId;
          const res = await trackPromise(updateFrap(data));
          if (res.status === 200) {
            if (procType === "u") {
              const nFrap = this.formatData(data, data["idTransaction"], "U");
              this.props.onUpdate(nFrap);
            } else {
              this.props.onCancel();
            }
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Frap actualizado con éxito",
              showConfirmButton: false,
              timer: 1800,
              toast: true,
            });
          }
        }
      } catch (err) {
        //console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    }
  };

  render() {
    const {
      dw,
      listas,
      listasTx,
      data,
      evInicial,
      evSecundaria,
      evPaciente,
      histClinica,
      firmas,
      diss,
      //updateId,
    } = this.state;
    const lw2 = "6/12"; //Input width for two in same row
    const iw2 = "6/12"; //Label width for two in same row
    const tblArr = [...Array(4).keys()]; //Para la tabla de pagos
    const tblP = _.keys(data.tblpagos[0]); //Sacamos las llaves para crear la tabla
    const tipoSel = listas
      ? _.filter(listas, {
          lista: "Tipo_Cliente",
          val: data.Tipo_Cliente,
        })
      : []; //Tipo de cliente seleccionado
    const formaPago = _.orderBy(
      _.filter(listasTx, { lista: "forma_pago" }),
      "val",
      "asc"
    ); //Para sacar todas las formas de pago

    //Opciones de operador, paramedico y paramedico extra
    let operadores = _.orderBy(
      _.filter(listas, { lista: "Operador" }),
      "vis",
      "asc"
    );
    let paramedicos = _.orderBy(
      _.filter(listas, { lista: "Paramedico" }),
      "vis",
      "asc"
    );

    let paramedicosExtra = _.orderBy(
      _.filter(listas, { lista: "Paramedico" }),
      "vis",
      "asc"
    );

    if (
      data.id_Empleado > 0 &&
      _.filter(listas, { lista: "Operador", val: data.id_Empleado }).length <= 0
    ) {
      const inactiveOp = _.filter(listas, {
        lista: "Operador-all",
        val: data.id_Empleado,
      })[0];

      operadores.push(inactiveOp);
    }

    if (
      data.id_Empleado2 > 0 &&
      _.filter(listas, { lista: "Paramedico", val: data.id_Empleado2 })
        .length <= 0
    ) {
      const inactivePar = _.filter(listas, {
        lista: "Paramedico-all",
        val: data.id_Empleado2,
      })[0];

      paramedicos.push(inactivePar);
    }

    if (
      data.id_Empleado3 > 0 &&
      _.filter(listas, { lista: "Paramedico", val: data.id_Empleado3 })
        .length <= 0
    ) {
      const inactivePar = _.filter(listas, {
        lista: "Paramedico-all",
        val: data.id_Empleado3,
      })[0];

      paramedicosExtra.push(inactivePar);
    }

    //Opciones de operador, paramedico y paramedico extra

    //Filter de Indicaciones y Manejos

    const histClinicaDetails = (({
      Impresion,
      Manejo1,
      Indicaciones1,
      alergias,
      enfermedadesprev,
      medicamentos,
      uingesta,
      descripcionev,
    }) => ({
      Impresion,
      Manejo1,
      Indicaciones1,
      alergias,
      enfermedadesprev,
      medicamentos,
      uingesta,
      descripcionev,
    }))(data);

    const evPacienteDetails = (({
      EvaluacionPX,
      agente_causal,
      origen_probable,
      especifique,
      pv_sub,
    }) => ({
      EvaluacionPX,
      agente_causal,
      origen_probable,
      especifique,
      pv_sub,
    }))(data);

    const evInicialDetails = (({
      conciencia,
      vaerea,
      deglucion,
      observacion,
      auscultacion,
      hemotorax,
      sitio,
      pulsos,
      calidad,
      piel,
      caracteristicas,
    }) => ({
      conciencia,
      vaerea,
      deglucion,
      observacion,
      auscultacion,
      hemotorax,
      sitio,
      pulsos,
      calidad,
      piel,
      caracteristicas,
    }))(data);

    const evSecundariaDetails = (({
      oxigeno,
      ltsmin,
      metodo,
      exfisica,
      dolor,
      esferas,
      escala_ocular,
      escala_verbal,
      escala_motora,
      escala_glasgow,
      codigo,
      fisico,
      opfisica,
      bvm,
      frecuencia,
      volument,
      pupilas,
      tblvital,
      windowsize,
    }) => ({
      oxigeno,
      ltsmin,
      metodo,
      exfisica,
      dolor,
      esferas,
      escala_ocular,
      escala_verbal,
      escala_motora,
      escala_glasgow,
      codigo,
      fisico,
      opfisica,
      bvm,
      frecuencia,
      volument,
      pupilas,
      tblvital,
      windowsize,
    }))(data);

    const firmasDetails = (({
      entrega_paciente,
      recibe_paciente,
      firma_paciente,
      firma_lugar,
      observaciones,
    }) => ({
      entrega_paciente,
      recibe_paciente,
      firma_paciente,
      firma_lugar,
      observaciones,
    }))(data);

    return (
      <React.Fragment>
        <div className="modal-back z-20">
          <LoadingComp />

          {evPaciente ? (
            <EvPaciente
              listasTx={listasTx}
              prevDetails={evPacienteDetails}
              onClose={this.modalEvPaciente}
              onSubmit={this.updateDetails}
              disabled={diss}
            />
          ) : null}
          {evInicial ? (
            <EvInicial
              prevDetails={evInicialDetails}
              onClose={this.modalEvInicial}
              onSubmit={this.updateDetails}
              disabled={diss}
            />
          ) : null}
          {evSecundaria ? (
            <EvSecundaria
              prevDetails={evSecundariaDetails}
              onClose={this.modalEvSecundaria}
              onSubmit={this.updateDetails}
              disabled={diss}
            />
          ) : null}
          {histClinica ? (
            <HistClinica
              prevDetails={histClinicaDetails}
              onClose={this.modalHistClinica}
              onSubmit={this.updateDetails}
              disabled={diss}
            />
          ) : null}
          {firmas ? (
            <Firmas
              prevDetails={firmasDetails}
              onClose={this.modalFirmas}
              onSubmit={this.updateDetails}
              disabled={diss}
            />
          ) : null}
          <div className="px-2">
            <div className="modal-box md:w-11/12 top-2 mb-5">
              <span className="modal-title">
                <FontAwesomeIcon
                  className={
                    "mr-2 cursor-pointer " +
                    (this.props.type === "n"
                      ? "text-green-500"
                      : "text-purple-500")
                  }
                  icon={["far", "save"]}
                  onClick={this.handleSubmit}
                />
                {this.props.type === "u" || data.idFrap !== "" ? (
                  /*<a
                    href={"/fraps/pdf/" + updateId}
                    target="_blank"
                    rel="noreferrer"
                  >*/
                  <FontAwesomeIcon
                    className={"mr-2 cursor-pointer text-yellow-500"}
                    icon={["far", "file-pdf"]}
                    onClick={this.handleSubmitPdf}
                  />
                ) : //</a>
                null}
                Captura de Fraps{" "}
                <FontAwesomeIcon
                  className="ml-1 text-red-500 cursor-pointer"
                  icon={["far", "times-circle"]}
                  onClick={this.closeModal}
                />
              </span>
              <form className="pt-3 flex flex-wrap">
                <div className="w-full md:w-1/2 py-1 px-3 space-y-3">
                  {this.props.type === "u" || this.props.type === "c"
                    ? this.renderInputH(
                        "idFrap",
                        "Número de Frap",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        false,
                        true
                      )
                    : null}
                  <div className="flex flex-wrap w-full gap-y-3">
                    <div className="w-full md:w-1/2">
                      {this.renderInputH(
                        "Fecha_Frap",
                        "Fecha",
                        "date",
                        "",
                        "1/3",
                        "2/3",
                        true
                      )}
                    </div>
                    <div className="w-full md:w-1/2 md:pl-3">
                      {this.renderSelect(
                        "ambulancia",
                        "Unidad",
                        "number",
                        _.orderBy(
                          _.filter(listas, { lista: "Ambulancia" }),
                          "val",
                          "asc"
                        ),
                        dw.label,
                        dw.input,
                        true
                      )}
                    </div>
                  </div>

                  {this.renderSelect(
                    "id_Empleado",
                    "Operador",
                    "number",
                    operadores,
                    dw.label,
                    dw.input,
                    true
                  )}
                  {/*data.id_Empleado > 0 &&
                  _.filter(listas, { lista: "Operador", val: data.id_Empleado })
                    .length <= 0 ? (
                    <div className="flex flex-wrap w-full">
                      <span className="text-red-400 text-xs">
                        <span>
                          Este FRAP tiene asignado un operador INACTIVO, puedes
                          asignar uno nuevo
                        </span>
                      </span>
                    </div>
                    ) : null*/}
                  {this.renderSelect(
                    "id_Empleado2",
                    "Paramédico",
                    "number",
                    paramedicos,
                    dw.label,
                    dw.input,
                    true
                  )}
                  {/*data.id_Empleado2 > 0 &&
                  _.filter(listas, {
                    lista: "Paramedico",
                    val: data.id_Empleado2,
                  }).length <= 0 ? (
                    <div className="flex flex-wrap w-full">
                      <span className="text-red-400 text-xs">
                        <span>
                          Este FRAP tiene asignado un paramédico INACTIVO,
                          puedes asignar uno nuevo
                        </span>
                      </span>
                    </div>
                  ) : null*/}
                  {this.renderSelect(
                    "id_Empleado3",
                    "Paramédico Ex",
                    "number",
                    paramedicosExtra,
                    dw.label,
                    dw.input
                  )}

                  <div className="flex flex-wrap w-full border-2 border-gray-100 rounded">
                    <div className="w-full text-center">
                      <span className="text-sm font-semibold">
                        Horas Críticas
                      </span>
                    </div>
                    <div className="w-full flex flex-row p-2">
                      <table className="w-full text-xs">
                        <tbody>
                          <tr className="flex flex-col flex-nowrap sm:table-row border">
                            <td className="border p-1">Llamada</td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "Hora_Llamada",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo,
                                false
                              )}
                            </td>
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss
                                  ? null
                                  : () => this.setTime("hora_despacho")
                              }
                            >
                              Despacho
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "hora_despacho",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss ? null : () => this.setTime("Hora_Salida")
                              }
                            >
                              Salida
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "Hora_Salida",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                          </tr>
                          <tr className="flex flex-col flex-nowrap sm:table-row border">
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss ? null : () => this.setTime("Hora_Llegada")
                              }
                            >
                              Arribo Lugar
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "Hora_Llegada",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss
                                  ? null
                                  : () => this.setTime("Hora_Traslado")
                              }
                            >
                              Inicio Traslado
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "Hora_Traslado",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss ? null : () => this.setTime("Hora_Destino")
                              }
                            >
                              Arribo Destino
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "Hora_Destino",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                          </tr>
                          <tr className="flex flex-col flex-nowrap sm:table-row border">
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss
                                  ? null
                                  : () => this.setTime("hora_inicioreg")
                              }
                            >
                              Inicio Regreso
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "hora_inicioreg",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss ? null : () => this.setTime("hora_arrdest")
                              }
                            >
                              Destino Final
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "hora_arrdest",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss ? null : () => this.setTime("Hora_Termino")
                              }
                            >
                              Término Servicio
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "Hora_Termino",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                          </tr>
                          <tr className="flex flex-col flex-nowrap sm:table-row border">
                            <td
                              className="border p-1 text-purple-500 cursor-pointer"
                              onClick={
                                diss ? null : () => this.setTime("hora_base")
                              }
                            >
                              Arribo Base
                            </td>
                            <td className="border p-1">
                              {this.renderTimePicker(
                                "hora_base",
                                "",
                                "0",
                                "12/12",
                                this.props.user?.tipo
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="w-full text-center py-1">
                      <span className="text-xs">
                        *Da clic en los nombres para capturar la hora actual
                      </span>
                    </div>
                  </div>
                  <div className="border-2 border-gray-100 rounded space-y-3 pb-4 pt-1 px-3">
                    <div className="w-full text-center">
                      <span className="text-sm font-semibold">
                        Datos del Servicio
                      </span>
                    </div>
                    {this.renderInputH(
                      "Lugar_Inicio",
                      "Lugar Atención",
                      "text",
                      "",
                      dw.label,
                      dw.input,
                      true
                    )}
                    {this.renderInputH(
                      "Motivo",
                      "Motivo",
                      "text",
                      "",
                      dw.label,
                      dw.input,
                      true
                    )}
                    {this.renderSelect(
                      "Traslado",
                      "Traslado",
                      "text",
                      [
                        {
                          vis: "SI",
                          val: "SI",
                        },
                        {
                          vis: "NO",
                          val: "NO",
                        },
                        {
                          vis: "PROPIOS MEDIOS",
                          val: "PROPIOS MEDIOS",
                        },
                      ],
                      dw.label,
                      dw.input,
                      true
                    )}
                    {this.renderSelect(
                      "Lugar_Destino",
                      "Lugar Destino",
                      "text",
                      _.orderBy(
                        _.filter(listasTx, { lista: "lugar_destino" }),
                        "val",
                        "asc"
                      ),
                      dw.label,
                      dw.input,
                      true
                    )}
                    {this.renderTextArea(
                      "lugar_destino_otro",
                      "Detalle Destino: ",
                      "text",
                      "",
                      dw.label,
                      dw.input,
                      "2"
                    )}
                    {this.renderSelect(
                      "Tipo_Cliente",
                      "Tipo de Cliente",
                      "number",
                      _.orderBy(
                        _.filter(listas, { lista: "Tipo_Cliente" }),
                        "vis",
                        "asc"
                      ),
                      dw.label,
                      dw.input,
                      true
                    )}
                    {this.renderSelect(
                      "Tipo_Servicio",
                      "Tipo de Servicio",
                      "text",
                      _.orderBy(
                        _.filter(listasTx, { lista: "tipo_servicio" }),
                        "val",
                        "asc"
                      ),
                      dw.label,
                      dw.input,
                      true
                    )}

                    {data.Tipo_Cliente !== 3
                      ? this.renderSelect(
                          "idCliente",
                          "Cliente",
                          "number",
                          _.orderBy(
                            _.filter(listas, {
                              lista:
                                tipoSel.length === 1 ? tipoSel[0].vis : "EMPTY",
                            }),
                            "vis",
                            "asc"
                          ),
                          dw.label,
                          dw.input,
                          true
                        )
                      : null}
                    {/*statusCliente === "INACTIVO" ? (
                      <div className="flex flex-wrap w-full">
                        <span className="text-red-400 text-xs">
                          {`Este FRAP pertenece a un cliente INACTIVO (${NombreFinal}), en caso de querer cambiarlo, selecciona un nuevo cliente.`}
                        </span>
                      </div>
                    ) : null*/}
                  </div>
                </div>
                <div className="w-full md:w-1/2 py-1 px-3 space-y-3">
                  <div className="border-2 border-gray-100 rounded space-y-3 pb-4 pt-1 px-3">
                    <div className="w-full text-center">
                      <span className="text-sm font-semibold">
                        Datos del Paciente
                      </span>
                    </div>
                    {this.renderInputH(
                      "Paciente",
                      "Nombre",
                      "text",
                      "",
                      "1/5",
                      "4/5",
                      true
                    )}
                    {this.renderInputH(
                      "direccion",
                      "Dirección",
                      "text",
                      "Calle y numero",
                      "1/5",
                      "4/5"
                    )}

                    <div className="flex flex-wrap w-full gap-y-3">
                      <div className="w-full md:w-1/2">
                        {this.renderInputH(
                          "colonia",
                          "Colonia",
                          "text",
                          "",
                          dw.label,
                          dw.input
                        )}
                      </div>
                      <div className="w-full md:w-1/2 md:pl-3">
                        {this.renderInputH(
                          "ciudad",
                          "Ciudad",
                          "text",
                          "",
                          dw.label,
                          dw.input
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap w-full gap-y-3">
                      <div className="w-full md:w-3/12">
                        {this.renderInputH(
                          "Edad",
                          "Edad",
                          "number",
                          "",
                          "2/6",
                          "4/6"
                        )}
                      </div>
                      <div className="w-full md:w-4/12 md:pl-3">
                        {this.renderInputH(
                          "meses",
                          "Meses",
                          "number",
                          "",
                          "5/12",
                          "7/12"
                        )}
                      </div>
                      <div className="w-full md:w-5/12 md:pl-3 ">
                        {this.renderSelect(
                          "Genero",
                          "Genero",
                          "text",
                          [
                            {
                              vis: "FEMENINO",
                              val: "FEMENINO",
                            },
                            {
                              vis: "MASCULINO",
                              val: "MASCULINO",
                            },
                            {
                              vis: "CANCELADO",
                              val: "CANCELADO",
                            },
                          ],
                          "2/6",
                          "4/6"
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap w-full gap-y-3">
                      <div className="w-full md:w-1/2  ">
                        {this.renderSelect(
                          "Estado_Civil",
                          "Edo. Civil",
                          "text",
                          _.orderBy(
                            _.filter(listasTx, { lista: "estado_civil" }),
                            "val",
                            "asc"
                          ),
                          lw2,
                          iw2
                        )}
                      </div>
                      <div className="w-full md:w-1/2 md:pl-3  ">
                        {this.renderSelect(
                          "Servicio_Med",
                          "Servicio Médico",
                          "text",
                          _.orderBy(
                            _.filter(listasTx, { lista: "servicio_medico" }),
                            "val",
                            "asc"
                          ),
                          lw2,
                          iw2
                        )}
                      </div>
                    </div>
                    {this.renderInputH(
                      "telefono",
                      "Teléfono",
                      "text",
                      "",
                      dw.label,
                      dw.input
                    )}
                  </div>
                  <div className="flex w-full ">
                    <div className="w-1/2 px-2">
                      <button
                        className="btn-purple w-full"
                        onClick={() => this.modalEvPaciente()}
                        type="button"
                      >
                        Evaluación del Paciente{" "}
                      </button>
                    </div>
                    <div className="w-1/2 px-2">
                      <button
                        className="btn-purple w-full"
                        onClick={() => this.modalEvInicial()}
                        type="button"
                      >
                        Evaluación Inicial{" "}
                      </button>
                    </div>
                  </div>
                  <div className="flex w-full ">
                    <div className="w-1/2 px-2">
                      <button
                        className="btn-purple w-full"
                        onClick={() => this.modalEvSecundaria()}
                        type="button"
                      >
                        Evaluación Secundaria{" "}
                      </button>
                    </div>
                    <div className="w-1/2 px-2">
                      <button
                        className="btn-purple w-full"
                        onClick={() => this.modalHistClinica()}
                        type="button"
                      >
                        Historia Clínica{" "}
                      </button>
                    </div>
                  </div>
                  <div className="w-full text-center py-1">
                    <button
                      className="btn-purple w-1/2"
                      onClick={() => this.modalFirmas()}
                      type="button"
                    >
                      Firmas FRAP
                    </button>
                  </div>
                  <div className="flex flex-wrap w-full gap-y-3">
                    <div className="w-full md:w-1/3 ">
                      {this.renderInputH(
                        "Costo",
                        "Costo",
                        "number",
                        "",
                        lw2,
                        lw2,
                        true,
                        this.props.user?.tipo === "Paramedico" ? true : false
                      )}
                    </div>
                    <div className="w-full md:w-2/3 md:pl-3 ">
                      {this.renderSelect(
                        "Status_Pago",
                        "Estatus Pago",
                        "text",
                        _.orderBy(
                          _.filter(listasTx, { lista: "estatus_pago" }),
                          "val",
                          "asc"
                        ),
                        "4/12",
                        "8/12",
                        true
                      )}
                      {/*this.renderInputH(
                        "Recibo",
                        "Recibo",
                        "text",
                        "",
                        lw2,
                        iw2
                      )*/}
                    </div>
                    {/*<div className="w-full md:w-1/2 md:pl-3 ">
                      {this.renderInputH(
                        "Pago_Servicio",
                        "Pago Servicio",
                        "number",
                        "",
                        lw2,
                        iw2
                      )}
                      </div>*/}
                  </div>
                  <div className="w-full flex py-1">
                    <table className="table-fixed text-xs shadow-md w-full">
                      <thead>
                        <tr>
                          <th className="w-3/12 border p-1">Fecha Pago</th>
                          <th className="w-3/12 border p-1">Monto</th>
                          <th className="w-3/12 border p-1">Recibo/Mov</th>
                          <th className="w-3/12 border p-1">Forma Pago</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tblArr.map((index, i) => (
                          <tr key={i}>
                            {tblP.map((name, i) => (
                              <td className="border p-1" key={name + i}>
                                <div className="flex flex-wrap w-full">
                                  {name === "forma" ? (
                                    <select
                                      className="w-full text-xs text-input"
                                      name={name + index}
                                      value={data["tblpagos"][index][name]}
                                      onChange={this.handleTableChange}
                                      disabled={
                                        data["Validado"] === 1 &&
                                        data["validadoger"] === 1
                                          ? true
                                          : this.props.user?.tipo ===
                                            "Administracion"
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value=""></option>
                                      {formaPago.map((forma, i) => (
                                        <option
                                          key={forma.val + i}
                                          value={forma.val}
                                        >
                                          {forma.vis}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <input
                                      className="w-full text-xs text-input"
                                      type={
                                        name === "movimiento"
                                          ? "text"
                                          : name === "monto"
                                          ? "number"
                                          : "date"
                                      }
                                      name={name + index}
                                      value={
                                        name === "monto" &&
                                        data["tblpagos"][index][name] === 0
                                          ? ""
                                          : data["tblpagos"][index][name]
                                      }
                                      onChange={this.handleTableChange}
                                      disabled={
                                        data["Validado"] === 1 &&
                                        data["validadoger"] === 1
                                          ? true
                                          : this.props.user?.tipo ===
                                            "Administracion"
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                </div>
                              </td>
                            ))}
                          </tr>
                        ))}
                        <tr className="bg-gray-100">
                          <td
                            className="border p-1 text-center font-semibold"
                            colSpan="4"
                          >
                            {"Total Pagado: $" +
                              ((data["tblpagos"][0]["monto"] === ""
                                ? 0
                                : parseInt(data["tblpagos"][0]["monto"])) +
                                (data["tblpagos"][1]["monto"] === ""
                                  ? 0
                                  : parseInt(data["tblpagos"][1]["monto"])) +
                                (data["tblpagos"][2]["monto"] === ""
                                  ? 0
                                  : parseInt(data["tblpagos"][2]["monto"])) +
                                (data["tblpagos"][3]["monto"] === ""
                                  ? 0
                                  : parseInt(data["tblpagos"][3]["monto"])))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*{this.renderInputH(
                    "Fecha_Recibo",
                    "Fecha Pago(s)",
                    "text",
                    "",
                    dw.label,
                    dw.input
                  )}
                  <div className="flex flex-wrap w-full gap-y-3">
                    <div className="w-full md:w-1/2">
                      {this.renderInputH(
                        "Movimiento",
                        "Movimiento(s)",
                        "text",
                        "",
                        lw2,
                        iw2
                      )}
                    </div>
                    <div className="w-full md:w-1/2 md:pl-3 ">
                      {this.renderSelect(
                        "Tipo_Pago",
                        "Forma de Pago",
                        "text",
                        _.orderBy(
                          _.filter(listasTx, { lista: "forma_pago" }),
                          "val",
                          "asc"
                        ),
                        lw2,
                        lw2,
                        true
                      )}
                    </div>
                        </div>*/}
                  {this.props.user?.tipo === "Paramedico" ||
                  this.props.user?.tipo === "Coordinador" ? null : (
                    <React.Fragment>
                      <div className="flex flex-wrap w-full gap-y-3">
                        <div className="w-full md:w-1/2  ">
                          {this.renderSelect(
                            "Status_Factura",
                            "Estatus Factura",
                            "text",
                            [
                              {
                                vis: "FACTURADO",
                                val: "FACTURADO",
                              },
                              {
                                vis: "POR FACTURAR",
                                val: "POR FACTURAR",
                              },
                              {
                                vis: "SIN FACTURA",
                                val: "SIN FACTURA",
                              },
                            ],
                            lw2,
                            iw2
                          )}
                        </div>
                        <div className="w-full md:w-1/2 md:pl-3 ">
                          {this.renderInputH(
                            "Factura",
                            "Factura",
                            "text",
                            "",
                            lw2,
                            iw2
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap w-full gap-y-3">
                        <div className="w-full md:w-1/2  ">
                          {this.renderInputH(
                            "Fecha_Admin",
                            "Fecha Admin",
                            "date",
                            "",
                            lw2,
                            iw2
                          )}
                        </div>
                        <div className="w-full md:w-1/2 md:pl-3 ">
                          {this.renderToggle(
                            "Validado",
                            "Validación Administración"
                          )}
                        </div>
                      </div>
                      <div className="flex flex-wrap w-full gap-y-3">
                        <div className="w-full md:w-1/2  ">
                          {this.renderInputH(
                            "fecha_gerencia",
                            "Fecha Gerencia",
                            "date",
                            "",
                            lw2,
                            iw2
                          )}
                        </div>
                        <div className="w-full md:w-1/2 md:pl-3 ">
                          {this.renderToggle(
                            "validadoger",
                            "Validación Gerencia"
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.renderTextArea(
                    "Comentarios",
                    "Comentarios",
                    "text",
                    "",
                    "1/5",
                    "4/5",
                    "3"
                  )}
                  {this.props.user?.tipo === "Paramedico" ||
                  this.props.user?.tipo === "Coordinador"
                    ? null
                    : this.renderTextArea(
                        "Cobranza",
                        "Cobranza",
                        "text",
                        "",
                        "1/5",
                        "4/5",
                        "3",
                        this.props.user?.tipo === "Radioperaciones"
                          ? true
                          : this.props.user?.tipo === "Facturacion"
                          ? false
                          : this.state.diss
                      )}
                </div>

                <div className="flex space-x-4 justify-center w-full pt-3">
                  <div className="w-1/2 md:w-1/6">
                    {this.props.type === "n" ? (
                      <button
                        className="btn-success w-full"
                        type={"button"}
                        onClick={this.handleSubmit}
                      >
                        Guardar
                      </button>
                    ) : (
                      <button
                        className="btn-purple w-full"
                        type={"button"}
                        onClick={this.handleSubmit}
                        disabled={false}
                      >
                        Actualizar
                      </button>
                    )}
                  </div>
                  <div className="w-1/2 md:w-1/6">
                    <button
                      className="btn-red w-full"
                      type="button"
                      onClick={this.closeModal}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewFrap;

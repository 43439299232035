import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import logo from "./../../assets/img/logo.png";
import auth from "./../../services/authService";

class Login extends FormRules {
  state = {
    data: { usuario: "", password: "" },
    errors: {},
  };

  schema = {
    usuario: Joi.string().required().label("Usuario"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.usuario, data.password);
      window.location = "/index";
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Usuario o Contraseña invalida",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg-gradient-to-r from-red-100 to-blue-100 ">
          <div className="container mx-auto">
            <div className="flex h-screen justify-center">
              <div className="md:w-1/4 md:w-2/4 sm:3/4 w-5/6 h-auto py-2 m-auto">
                <div className="content-center py-2">
                  <img
                    src={logo}
                    className="mx-auto w-1/2"
                    alt="lifesupport-logo"
                  />
                </div>

                <div className="card bg-gray-100">
                  <div className="block px-5 text-center py-1">
                    <span className="w-full text-lg font-semibold">
                      Iniciar Sesión
                    </span>
                  </div>
                  <form className="space-y-2" onSubmit={this.handleSubmit}>
                    {this.renderInput(
                      "usuario",
                      "Usuario",
                      "text",
                      "Ej. LifeSupport1"
                    )}
                    {this.renderInput(
                      "password",
                      "Contraseña",
                      "password",
                      "Ej. 12345"
                    )}
                    <div className="p-2 text-center">
                      <button className="btn-success">Entrar</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;

import React from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import _ from "lodash";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from "react-promise-tracker";
import FormRules from "./formRules";
//Importar Servicios
import {
  getLists,
  getListsTx,
  getListsValues,
  getListsInactive,
  getListsInactiveTx,
  modifyGeneralList,
  modifyClientList,
  exportLists,
} from "./../../services/listaService";
import {
  getTab,
  getPrices,
  getTabExport,
  createTab,
  updateTab,
} from "./../../services/tabulador";

class ListHandler extends FormRules {
  state = {
    data: {
      lista: "",
      newVal: "",
      delVal: "",
      idCliente: 0,
      tipoCliente: 0,
      tipoServicio: "",
      disct: 0,
      tax: 0,
      fix: 0,
      tabF: "C",
      tabtc: "",
      tabc: "",
      tabid: 0,
    },
    errors: {},
    dw: { label: "1/4", input: "3/4" },
    step: 1,
    action: "",
    allCust: false,
    allService: false,
    tabuladores: [],
    precios: [],
    listas: [],
    listasVal: [],
    listasInactive: [],
  };

  schema = {
    lista: Joi.optional(),
    newVal: Joi.optional(),
    delVal: Joi.optional(),
    idCliente: Joi.optional(),
    tipoCliente: Joi.optional(),
    tipoServicio: Joi.optional(),
    disct: Joi.optional(),
    tax: Joi.optional(),
    fix: Joi.optional(),
    tabF: Joi.optional(),
    tabtc: Joi.optional(),
    tabc: Joi.optional(),
    tabid: Joi.optional(),
  };

  componentDidMount = async () => {
    try {
      const { status, data: listas } = await trackPromise(getLists());
      const { status: statusTx, data: listasTx } = await trackPromise(
        getListsTx()
      );
      const { status: statusVal, data: listasVal } = await trackPromise(
        getListsValues()
      );
      const { status: statusInactive, data: listasInactive } =
        await trackPromise(getListsInactive());
      const { status: statusInactiveTx, data: listasInactiveTx } =
        await trackPromise(getListsInactiveTx());
      const { status: statusTab, data: tabulador } = await trackPromise(
        getTab()
      );
      const { status: statusPrices, data: precios } = await trackPromise(
        getPrices()
      );
      if (
        status === 200 &&
        statusTx === 200 &&
        statusVal === 200 &&
        statusInactive === 200 &&
        statusInactiveTx === 200 &&
        statusTab === 200 &&
        statusPrices === 200
      ) {
        const finalLista = listas.concat(listasTx);
        const finalListaInc = listasInactive.concat(listasInactiveTx);
        this.setState({
          listas: finalLista,
          listasVal,
          listasInactive: finalListaInc,
          tabulador,
          precios,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  handleStep = (val, action) => {
    const { data } = this.state;
    if (data.lista !== "" || action === "tab" || action === "prec") {
      let step = this.state.step;
      step += val;
      data["newVal"] = val === -1 ? "" : data["newVal"];
      if (action === "tab") {
        data.lista = "clientes";
      } else if (action === "prec") {
        data.lista = "tipo_servicio";
      }
      this.setState({ step, action, data });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Selecciona una lista",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  handleExport = async () => {
    try {
      const { status, data } = await trackPromise(exportLists());
      const { status: statusPrice, data: precios } = await trackPromise(
        getPrices()
      );
      const { status: statusTab, data: tabulador } = await trackPromise(
        getTabExport()
      );
      if (status === 200 && statusPrice === 200 && statusTab === 200) {
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.json_to_sheet(data);
        let ws1 = XLSX.utils.json_to_sheet(precios);
        let ws2 = XLSX.utils.json_to_sheet(tabulador);
        XLSX.utils.book_append_sheet(wb, ws, "Listas");
        XLSX.utils.book_append_sheet(wb, ws1, "Precios");
        XLSX.utils.book_append_sheet(wb, ws2, "Tabulador");
        XLSX.writeFile(wb, "Listas.xlsx");
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  fullList = (tipo) => {
    let { allCust, allService } = this.state;
    const data = { ...this.state.data };
    if (tipo === "servicios") {
      data["tipoServicio"] = allService ? "" : "ALL";
      allService = allService ? false : true;
    }
    if (tipo === "clientes") {
      data["idCliente"] = allCust ? 0 : -1;
      data["tipoCliente"] = 0;
      allCust = allCust ? false : true;
    }

    this.setState({ allCust, allService, data });
  };

  doSubmit = async () => {
    try {
      const { data, action, listasVal, listas } = this.state;
      if (
        (action === "a" && data.newVal !== "") ||
        ((action === "e" || action === "re" || action === "ed") &&
          data.delVal !== "") ||
        (action === "prec" && data.newVal !== "")
      ) {
        const tabla = _.filter(listasVal, { val: data.lista });
        data.action = action;
        data.tabla = tabla[0].tabla;
        data.campo = tabla[0].campo;
        data.identificador = tabla[0].identificador;
        data.tipo = tabla[0].tipo;
        Swal.fire({
          title:
            "¿Quieres actualizar el valor de la lista: " +
            (action === "prec" ? "Precios" : tabla[0].vis) +
            "?",
          text: "Se actualizará en la base de datos",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#8B5CF6",
          cancelButtonColor: "#EF4444",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cerrar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (data.lista === "clientes") {
              //proceso para trabajar los servicios de clientes
              if (
                data.tipoCliente !== 0 ||
                ((action === "e" || action === "re" || action === "ed") &&
                  data.idCliente !== 0)
              ) {
                const tipoCliente = _.filter(listas, {
                  lista: "Tipo_Cliente",
                  val:
                    action === "a" ? data.tipoCliente : parseInt(data.delVal),
                });
                data.tipoClienteTx = tipoCliente[0].vis;
                const res = await modifyClientList(data);
                if (res.status === 200) {
                  Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Lista modificada con éxito",
                    showConfirmButton: false,
                    timer: 1800,
                    toast: true,
                  });
                  this.props.onSettings();
                }
              } else {
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Seleccionar datos en todos los campos",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
              }
            } else {
              //proceso para trabajar todo lo demas
              const res = await modifyGeneralList(data);
              if (res.status === 200) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Lista modificada con éxito",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
                this.props.onSettings();
              }
            }
          }
        });
      } else if (
        action === "tab" &&
        data["idCliente"] !== 0 &&
        data["tipoCliente"] !== 0 &&
        data["tipoServicio"] !== ""
      ) {
        const tabBody = (({
          tipoServicio,
          tipoCliente,
          disct,
          tax,
          fix,
          tabF,
          tabtc,
          tabc,
          tabid,
        }) => ({
          tipoServicio,
          tipoCliente,
          disct,
          tax,
          fix,
          tabF,
          tabtc,
          tabc,
          tabid,
        }))(data);
        if (tabBody.tabF === "E") {
          const body = (({ disct, tax, fix, tabid }) => ({
            disct,
            tax,
            fix,
            tabid,
          }))(tabBody);
          Swal.fire({
            title: "¿Quieres actualizar el tabulador seleccionado?",
            text: "Se actualizará en la base de datos",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#8B5CF6",
            cancelButtonColor: "#EF4444",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cerrar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const res = await updateTab(body);
              if (res.status === 200) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Tabulador modificado con éxito",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
                this.props.onSettings();
              }
            }
          });
        } else {
          const body = (({
            disct,
            tax,
            fix,
            tipoCliente,
            tipoServicio,
            tabtc,
            tabc,
          }) => ({
            disct,
            tax,
            fix,
            tipoCliente,
            tipoServicio,
            tabtc,
            tabc,
          }))(tabBody);
          Swal.fire({
            title: "¿Quieres actualizar el tabulador seleccionado?",
            text: "Se actualizará en la base de datos",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#8B5CF6",
            cancelButtonColor: "#EF4444",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cerrar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const res = await createTab(body);
              if (res.status === 200) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Tabulador creado con éxito",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
                this.props.onSettings();
              }
            }
          });
        }
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const {
      data,
      dw,
      step,
      action,
      listasVal,
      listas,
      listasInactive,
      allCust,
      allService,
    } = this.state;
    const tipoSel =
      listas &&
      data.lista === "clientes" &&
      (action === "e" || action === "re" || action === "ed" || action === "tab")
        ? _.filter(listas, {
            lista: "Tipo_Cliente",
            val:
              action === "tab"
                ? parseInt(data.tipoCliente)
                : parseInt(data.delVal),
          })
        : []; //Tipo de cliente seleccionado
    return (
      <React.Fragment>
        <div className="modal-back z-20">
          <div className="px-2">
            <div className="modal-box md:w-6/12 top-10 mb-5">
              <span className="modal-title">
                <FontAwesomeIcon
                  className="mr-2 text-green-500 cursor-pointer"
                  icon={["fas", "download"]}
                  onClick={() => this.handleExport()}
                />
                Modificación de Listas
                <FontAwesomeIcon
                  className="ml-2 text-red-500 cursor-pointer"
                  icon={["far", "times-circle"]}
                  onClick={() => this.props.onSettings()}
                />
              </span>
              <form className="pt-3 flex flex-wrap gap-y-3">
                {action === ""
                  ? this.renderSelect(
                      "lista",
                      "Seleccionar Lista:",
                      "text",
                      _.orderBy(listasVal, "val", "asc"),
                      dw.label,
                      dw.input
                    )
                  : null}
                {step === 1 ? (
                  <div className="flex flex-wrap gap-y-1 gap-x-4 justify-center w-full pt-3">
                    <div className="w-full md:w-3/12">
                      <button
                        className="btn-success w-full"
                        type="button"
                        onClick={() => this.handleStep(1, "a")}
                      >
                        Agregar
                      </button>
                    </div>
                    <div className="w-full md:w-3/12">
                      <button
                        className="btn-red w-full"
                        type="button"
                        onClick={() => this.handleStep(1, "e")}
                      >
                        Inactivar
                      </button>
                    </div>
                    <div className="w-full md:w-3/12">
                      <button
                        className="btn-purple w-full"
                        type="button"
                        onClick={() => this.handleStep(1, "re")}
                      >
                        Reactivar
                      </button>
                    </div>
                    <div className="w-full md:w-3/12">
                      <button
                        className="btn-orange w-full"
                        type="button"
                        onClick={() => this.handleStep(1, "ed")}
                      >
                        Editar
                      </button>
                    </div>
                    <div className="w-full md:w-3/12">
                      <button
                        className="btn-gray w-full"
                        type="button"
                        onClick={() => this.handleStep(1, "prec")}
                      >
                        Precios
                      </button>
                    </div>
                    <div className="w-full md:w-3/12">
                      <button
                        className="btn-gray w-full"
                        type="button"
                        onClick={() => this.handleStep(1, "tab")}
                      >
                        Tabulador
                      </button>
                    </div>
                  </div>
                ) : null}
                {action === "a" ? (
                  data.lista === "clientes" ? (
                    <React.Fragment>
                      {this.renderSelect(
                        "tipoCliente",
                        "Tipo de Cliente:",
                        "number",
                        _.orderBy(
                          _.filter(listas, {
                            lista: "Tipo_Cliente",
                          }),
                          "val",
                          "asc"
                        ),
                        dw.label,
                        dw.input
                      )}
                      {this.renderInputH(
                        "newVal",
                        "Nombre Cliente:",
                        "text",
                        "",
                        dw.label,
                        dw.input
                      )}
                    </React.Fragment>
                  ) : (
                    this.renderInputH(
                      "newVal",
                      "Nuevo Valor:",
                      "text",
                      "",
                      dw.label,
                      dw.input
                    )
                  )
                ) : null}
                {action !== "a" &&
                action !== "tab" &&
                action !== "prec" &&
                action !== ""
                  ? this.renderSelect(
                      "delVal",
                      data.lista !== "clientes"
                        ? action === "e"
                          ? "Inactivar:"
                          : action === "re"
                          ? "Reactivar:"
                          : "Editar:"
                        : "Tipo de Cliente:",
                      "text",
                      _.orderBy(
                        _.filter(
                          action === "e" ||
                            action === "ed" ||
                            (action === "re" && data.lista === "clientes")
                            ? listas
                            : listasInactive,
                          {
                            lista:
                              data.lista !== "clientes"
                                ? data.lista
                                : "Tipo_Cliente",
                          }
                        ),
                        "val",
                        "asc"
                      ),
                      dw.label,
                      dw.input
                    )
                  : null}
                {parseInt(data.delVal) !== 3 &&
                data.lista === "clientes" &&
                action !== "a" &&
                action !== "tab" &&
                action !== ""
                  ? this.renderSelect(
                      "idCliente",
                      "Cliente",
                      "number",
                      _.orderBy(
                        _.filter(
                          action === "e" || action === "ed"
                            ? listas
                            : listasInactive,
                          {
                            lista:
                              tipoSel.length === 1 ? tipoSel[0].vis : "EMPTY",
                          }
                        ),
                        "vis",
                        "asc"
                      ),
                      dw.label,
                      dw.input
                    )
                  : null}
                {action === "ed" ? (
                  <React.Fragment>
                    <div className="flex flex-wrap w-full">
                      <label
                        className={
                          "self-center w-full text-sm pb-1 lg:w-" + dw.label
                        }
                      >
                        Nuevo Nombre
                      </label>
                      <input
                        className={"w-full text-xs text-input lg:w-" + dw.input}
                        name="newVal"
                        value={data["newVal"]}
                        type="text"
                        data="text"
                        onChange={this.handleChange}
                      />
                    </div>
                  </React.Fragment>
                ) : null}
                {action === "prec" ? (
                  <React.Fragment>
                    {this.renderSelect(
                      "tipoServicio",
                      "Tipo de Servicio:",
                      "text",
                      _.orderBy(
                        _.filter(listas, {
                          lista: "tipo_servicio",
                        }),
                        "val",
                        "asc"
                      ),
                      dw.label,
                      dw.input
                    )}
                    {data.newVal !== ""
                      ? this.renderInputH(
                          "newVal",
                          "Costo:",
                          "number",
                          "",
                          dw.label,
                          dw.input
                        )
                      : null}
                  </React.Fragment>
                ) : null}
                {action === "tab" ? (
                  <React.Fragment>
                    {this.renderSelect(
                      "tipoServicio",
                      "Tipo de Servicio:",
                      "text",
                      _.orderBy(
                        _.filter(listas, {
                          lista: "tipo_servicio",
                        }),
                        "val",
                        "asc"
                      ),
                      dw.label,
                      dw.input,
                      false,
                      allService ? true : false
                    )}
                    {this.renderSelect(
                      "tipoCliente",
                      "Tipo de Cliente:",
                      "number",
                      _.orderBy(
                        _.filter(listas, {
                          lista: "Tipo_Cliente",
                        }),
                        "val",
                        "asc"
                      ),
                      dw.label,
                      dw.input
                    )}
                    {this.renderSelect(
                      "idCliente",
                      "Cliente",
                      "number",
                      _.orderBy(
                        _.filter(listas, {
                          lista:
                            tipoSel.length === 1 ? tipoSel[0].vis : "EMPTY",
                        }),
                        "vis",
                        "asc"
                      ),
                      dw.label,
                      dw.input,
                      false,
                      allCust ? true : false
                    )}

                    <div className="flex flex-wrap w-full gap-y-1">
                      <span
                        className={
                          "w-full md:w-6/12 cursor-pointer text-center underline " +
                          (allService ? "text-blue-500" : "")
                        }
                        onClick={() => this.fullList("servicios")}
                      >
                        TODOS LOS SERVICIOS
                      </span>
                      <span
                        className={
                          "w-full md:w-6/12 cursor-pointer text-center underline " +
                          (allCust ? "text-blue-500" : "")
                        }
                        onClick={() => this.fullList("clientes")}
                      >
                        TODOS LOS CLIENTES
                      </span>
                    </div>
                    {data["idCliente"] !== 0 &&
                    data["tipoCliente"] !== 0 &&
                    data["tipoServicio"] !== "" ? (
                      <React.Fragment>
                        <div className="flex flex-wrap w-full gap-y-1 gap-x-2">
                          <div className="w-full md:w-4/12">
                            {this.renderInputH(
                              "disct",
                              "Descuento:",
                              "float",
                              "",
                              "2/4",
                              "2/4"
                            )}
                          </div>
                          <div className="w-full md:w-3/12">
                            {this.renderSelect(
                              "tax",
                              "IVA",
                              "float",
                              [
                                {
                                  val: 1.16,
                                  vis: "SI",
                                },
                                {
                                  val: 0,
                                  vis: "NO",
                                },
                              ],
                              "1/4",
                              "3/4"
                            )}
                          </div>
                          <div className="w-full md:w-4/12">
                            {this.renderInputH(
                              "fix",
                              "Precio fijo:",
                              "float",
                              "",
                              "2/4",
                              "2/4"
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}
                {action !== "" ? (
                  <React.Fragment>
                    <div className="flex gap-x-4 justify-center w-full pt-3">
                      <div className="w-full md:w-4/12">
                        <button
                          className="btn-purple w-full"
                          type="button"
                          onClick={() => this.handleStep(-1, "")}
                        >
                          Regresar
                        </button>
                      </div>
                      <div className="w-full md:w-4/12">
                        <button
                          className={
                            "w-full " +
                            (action === "a" ||
                            (action === "tab" && data.tabF === "C")
                              ? "btn-success"
                              : action === "e"
                              ? "btn-red"
                              : action === "re"
                              ? "btn-success"
                              : "btn-orange")
                          }
                          type="button"
                          onClick={this.handleSubmit}
                        >
                          {action === "a" ||
                          (action === "tab" && data.tabF === "C")
                            ? "Agregar"
                            : action === "e"
                            ? "Inactivar"
                            : action === "re"
                            ? "Reactivar"
                            : "Editar"}
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListHandler;

import http from "./httpService";

export function postUser(body) {
  return http.post("/users", body);
}

export function updateUser(body) {
  return http.put("/users", body);
}

export function getUsers() {
  return http.get("/users");
}

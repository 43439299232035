import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import _ from "lodash";
import { trackPromise } from "react-promise-tracker";
//Importar Servicios
import { getLists } from "./../../services/listaService";
import { updateEvento } from "./../../services/eventosService";

class Assignation extends FormRules {
  state = {
    data: {
      Unidad: 0,
      Emp1: 0,
      Emp2: 0,
      Comentarios: "",
      Inicio_G: "",
      Fin_G: "",
      Comentario_G: "",
    },
    errors: {},
    dw: { label: "1/4", input: "3/4" },
    listas: [],
    updateId: 0,
    prev1: 0,
    prev2: 0,
  };

  schema = {
    Unidad: Joi.number().optional().label("Unidad"),
    Emp1: Joi.number().optional().label("Paramedico"),
    Emp2: Joi.number().optional().label("Paramedico"),
    Comentarios: Joi.optional(),
    Inicio_G: Joi.optional(),
    Fin_G: Joi.optional(),
    Comentario_G: Joi.optional(),
  };

  componentDidMount = async () => {
    try {
      const { status, data: listas } = await trackPromise(getLists());
      if (status === 200) {
        const data = { ...this.props.data };
        const updateId = data["idEventos"];
        const prev1 = data["Emp1"];
        const prev2 = data["Emp2"];
        delete data.idEventos;
        this.setState({ listas, data, updateId, prev1, prev2 });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  doSubmit = async () => {
    try {
      const { data, updateId, prev1, prev2, listas } = this.state;
      data["id"] = updateId;
      const res = await trackPromise(updateEvento(data));
      if (res.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Actualización Completa",
          showConfirmButton: false,
          timer: 2200,
          toast: true,
        });
        data["Emp1N"] =
          prev1 === 0
            ? ""
            : _.filter(listas, {
                lista: "Paramedico-all",
                val: data["Emp1"],
              })[0].vis;
        data["Emp2N"] =
          prev2 === 0
            ? ""
            : _.filter(listas, {
                lista: "Paramedico-all",
                val: data["Emp2"],
              })[0].vis;

        this.props.onUpdate(data);
      }
    } catch (e) {
      //console.log(e);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const { listas, dw, data } = this.state;
    let paramedicos = _.orderBy(
      _.filter(listas, { lista: "Paramedico" }),
      "vis",
      "asc"
    );
    if (
      data.Emp1 > 0 &&
      _.filter(listas, { lista: "Paramedico", val: data.Emp1 }).length <= 0
    ) {
      const inactivePar = _.filter(listas, {
        lista: "Paramedico-all",
        val: data.Emp1,
      })[0];

      paramedicos.push(inactivePar);
    }
    if (
      data.Emp2 > 0 &&
      _.filter(listas, { lista: "Paramedico", val: data.Emp2 }).length <= 0
    ) {
      const inactivePar = _.filter(listas, {
        lista: "Paramedico-all",
        val: data.Emp2,
      })[0];

      paramedicos.push(inactivePar);
    }

    return (
      <React.Fragment>
        <div className="modal-back z-20">
          <div className="px-2">
            <div className="modal-box top-2 mb-5">
              <span className="modal-title">Asignación y GPS</span>
              <form className="pt-3 space-y-3">
                {this.renderSelect(
                  "Unidad",
                  "Unidad",
                  "number",
                  _.orderBy(
                    _.filter(listas, { lista: "Ambulancia" }),
                    "val",
                    "asc"
                  ),
                  dw.label,
                  dw.input
                )}
                {this.renderSelect(
                  "Emp1",
                  "Operador",
                  "number",
                  paramedicos,
                  dw.label,
                  dw.input
                )}
                {this.renderSelect(
                  "Emp2",
                  "Paramédico",
                  "number",
                  paramedicos,
                  dw.label,
                  dw.input
                )}
                {this.renderTextArea(
                  "Comentarios",
                  "Observación: ",
                  "text",
                  "",
                  dw.label,
                  dw.input,
                  "3"
                )}
                <div className="flex flex-wrap w-full gap-y-3">
                  <div className="w-full md:w-1/2">
                    {this.renderTimePicker(
                      "Inicio_G",
                      "Inicio GPS",
                      "2/6",
                      "4/6",
                      this.props.user === "Coordinador"
                        ? "Paramedico"
                        : "Regular"
                    )}
                  </div>
                  <div className="w-full md:w-1/2 md:pl-3">
                    {this.renderTimePicker(
                      "Fin_G",
                      "Fin GPS",
                      "2/6",
                      "4/6",
                      this.props.user === "Coordinador"
                        ? "Paramedico"
                        : "Regular"
                    )}
                  </div>
                </div>
                {this.renderTextArea(
                  "Comentario_G",
                  "Comentarios GPS: ",
                  "text",
                  "",
                  dw.label,
                  dw.input,
                  "3"
                )}
                <div className="flex space-x-4 justify-center w-full pt-3">
                  <div className="w-1/2 md:w-1/6">
                    <button
                      className="btn-success w-full"
                      type={"button"}
                      onClick={this.handleSubmit}
                    >
                      Guardar
                    </button>
                  </div>
                  <div className="w-1/2 md:w-1/6">
                    <button
                      className="btn-red w-full"
                      type="button"
                      onClick={() => this.props.closeModal()}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Assignation;

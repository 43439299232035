import http from "./httpService";

export function getLists() {
  return http.get("/listas");
}

export function getListsTx() {
  return http.get("/listas/tx");
}

export function getListsValues() {
  return http.get("/listas/values/table");
}

export function getListsInactive() {
  return http.get("/listas/val/inactive/lista");
}

export function getListsInactiveTx() {
  return http.get("/listas/val/inactive/lista/tx");
}

export function exportLists() {
  return http.get("/listas/val/all/lista/ex/export");
}

export function modifyGeneralList(body) {
  return http.post("/listas", body);
}

export function modifyClientList(body) {
  return http.post("/listas/cliente", body);
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoColor = (props) => {
  return (
    <div
      className={
        "px-2 pt-2 flex flex-wrap gap-4 justify-center " +
        (props.view === "grid" ? "" : "md:hidden")
      }
    >
      {props.colors.map((e, i) => (
        <div key={i} className="text-sm px-1">
          <span>
            <FontAwesomeIcon
              className={"mr-1 text-" + e.color + "-200"}
              icon={["fas", "square"]}
            />
          </span>{" "}
          {e.label}
        </div>
      ))}
    </div>
  );
};

export default InfoColor;

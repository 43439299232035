import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import Swal from "sweetalert2";
//Importar Servicios
import { postUser, updateUser, getUsers } from "./../../services/userService";

class UserHandler extends FormRules {
  state = {
    data: {
      usuario: "",
      usuariodd: "",
      password: "",
      password2: "",
      tipoU: "",
      action: "Add",
    },
    errors: {},
    dw: { label: "2/6", input: "4/6" },
    users: [],
  };

  schema = {
    usuario: Joi.optional(),
    usuariodd: Joi.optional(),
    password: Joi.optional(),
    password2: Joi.optional(),
    tipoU: Joi.optional(),
    action: Joi.optional(),
  };

  componentDidMount = async () => {
    try {
      const res = await getUsers();
      if (res.status === 200) {
        const users = res.data;
        this.setState({ users });
      }
    } catch (err) {}
  };

  setAction = (action) => {
    const data = { ...this.state.data };
    data["action"] = action;
    this.setState({ data });
  };

  validRules = (data) => {
    let valid = false;

    if (data.action === "Add") {
      if (
        data.usuario.trim() !== "" &&
        data.usuario.trim().length >= 5 &&
        data.tipo !== ""
      ) {
        if (
          data.password === data.password2 &&
          data.password !== "" &&
          data.password2 !== "" &&
          data.password.trim().length >= 5
        ) {
          valid = true;
          //¿Queremos parametros para las contraseñas?
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Las contraseñas no coinciden o no cumplen los parametros",
            showConfirmButton: false,
            timer: 1800,
            toast: true,
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title:
            "Completa correctamente los campos o no cumples los parametros",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } else {
      if (data.usuariodd === "") {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Completa correctamente los campos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      } else {
        valid = true;
      }
    }
    return valid;
  };

  doSubmit = () => {
    const data = { ...this.state.data };
    const valid = this.validRules(data);

    if (valid) {
      Swal.fire({
        title:
          "¿Quieres " +
          (data.action === "Add" ? "agregar" : "eliminar") +
          " el usuario?",
        text: "Se registrará en la base de datos",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#10B981",
        cancelButtonColor: "#EF4444",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
      }).then(async (result) => {
        try {
          if (result.isConfirmed) {
            const body =
              data.action === "Add"
                ? {
                    usuario: data.usuario.trim(),
                    tipo: data.tipoU,
                    password: data.password.trim(),
                  }
                : {
                    usuario: data.usuariodd,
                  };
            const res =
              data.action === "Add"
                ? await postUser(body)
                : await updateUser(body);
            if (res.status === 200) {
              this.props.onUsers();
              Swal.fire({
                position: "top-end",
                icon: "success",
                title:
                  "El usuario fue " +
                  (data.action === "Add" ? "agregado" : "eliminado") +
                  " exitosamente",
                showConfirmButton: false,
                timer: 1800,
                toast: true,
              });
            }
          }
        } catch (err) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title:
              data.action === "Add"
                ? "El usuario ya existe en la base de datos"
                : "Ocurrio un error en el proceso",
            showConfirmButton: false,
            timer: 1800,
            toast: true,
          });
        }
      });
    }
  };

  render() {
    const { dw, data, users } = this.state;
    return (
      <React.Fragment>
        <div className="modal-back z-30">
          <div className="px-2">
            <div className="modal-box md:w-5/12 top-20">
              <span className="modal-title">Manejo de Usuarios</span>
              <div className="flex flex-wrap w-full py-2 justify-center">
                <div className="w-full md:w-1/3 px-3">
                  <button
                    className={
                      "w-full rounded py-1 " +
                      (data.action === "Add" ? "active-view" : "deactive-view")
                    }
                    onClick={() => this.setAction("Add")}
                  >
                    Agregar
                  </button>
                </div>
                <div className="w-full md:w-1/3 px-3">
                  <button
                    className={
                      "w-full rounded py-1 " +
                      (data.action === "Del" ? "active-view" : "deactive-view")
                    }
                    onClick={() => this.setAction("Del")}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
              <form className="flex flex-wrap space-y-3 pt-2">
                {data.action === "" ? null : data.action === "Add" ? (
                  <React.Fragment>
                    {this.renderInputH(
                      "usuario",
                      "Nuevo Usuario",
                      "text",
                      "",
                      dw.label,
                      dw.input
                    )}
                    {this.renderSelect(
                      "tipoU",
                      "Tipo Usuario",
                      "text",
                      [
                        { val: "Regular", vis: "Regular" },
                        { val: "Admin", vis: "Admin" },
                        { val: "Paramedico", vis: "Paramedico" },
                      ],
                      dw.label,
                      dw.input
                    )}
                    {this.renderInputH(
                      "password",
                      "Contraseña",
                      "password",
                      "",
                      dw.label,
                      dw.input
                    )}
                    {this.renderInputH(
                      "password2",
                      "Confirmar Contraseña",
                      "password",
                      "",
                      dw.label,
                      dw.input
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    {this.renderSelect(
                      "usuariodd",
                      "Usuario:",
                      "text",
                      users,
                      dw.label,
                      dw.input
                    )}
                  </React.Fragment>
                )}
                {data.action === "Add" && (
                  <small className="w-full text-center text-xs italic">
                    *Usar al menos 5 caracteres para usuarios y contraseñas
                  </small>
                )}

                {data.action !== "" ? (
                  <React.Fragment>
                    {" "}
                    <div className="flex flex-wrap w-full py-2 justify-center">
                      <div className="w-full md:w-1/3 px-3">
                        <button
                          className="btn-success w-full"
                          onClick={this.handleSubmit}
                        >
                          Aceptar
                        </button>
                      </div>
                      <div className="w-full md:w-1/3 px-3">
                        <button
                          className="btn-red w-full"
                          onClick={() => this.props.onUsers()}
                        >
                          Salir
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserHandler;

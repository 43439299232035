import React, { Component, Fragment } from "react";
import Swal from "sweetalert2";
import _ from "lodash";
import * as XLSX from "xlsx";
import auth from "../../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from "react-promise-tracker";
//Importar Componentes
import NavBar from "./navBar";
import LoadingComp from "../tools/loadingComp";
import InfoTable from "../views/infoTable";
import InfoGrid from "../views/infoGrid";
import InfoColor from "../views/infoColor";
import InfoOptions from "../views/infoOptions";
import NewFrap from "../forms/newFrap";
//Importar Servicios
import {
  getFraps,
  validateFraps,
  exportFraps,
} from "./../../services/frapService";

class Fraps extends Component {
  state = {
    data: [],
    dataSumm: [],
    view: "table",
    newModal: false,
    selectedFrap: {},
    infoType: "n",
    filters: {
      registros: 20,
      busqueda: "",
    },
    user: {},
    validationMode: false,
    validationArray: [],
    layout: [
      {
        name: "idFrap",
        label: "Frap: ",
      },
      {
        name: "Fecha_Frap",
        label: "",
      },
      { name: "NombreFinal", label: "Cliente: " },
      { name: "ValidadoTx", label: "Validado: " },
      { name: "Fecha_Admin", label: "Admin: " },
      { name: "id_Llamada", label: "Llamada: " },
    ],
    headers: [
      {
        name: "idFrap",
        head: "Frap",
        sort: "desc",
      },
      {
        name: "id_Llamada",
        head: "Llamada",
        sort: "desc",
      },
      {
        name: "Fecha_Frap",
        head: "Fecha",
        sort: "desc",
      },
      {
        name: "NombreFinal",
        head: "Cliente",
        sort: "desc",
      },
      {
        name: "Paciente",
        head: "Paciente",
        sort: "desc",
      },
      {
        name: "Tipo_Cliente_Nombre",
        head: "Tipo Cliente",
        sort: "desc",
      },
      {
        name: "Status_Pago",
        head: "Pago",
        sort: "desc",
        content: (frap) => (
          <span
            className={
              frap.Status_Pago === "PAGADO"
                ? "label-activo"
                : frap.Status_Pago === "PTE PAGO"
                ? "label-cancelado"
                : "label-cerrado"
            }
          >
            {frap.Status_Pago}
          </span>
        ),
      },
      {
        name: "Validado",
        head: "Validado",
        sort: "desc",
        content: (frap) => (
          <span
            className={frap.Validado === 1 ? "label-activo" : "label-cancelado"}
          >
            {frap.Validado === 1 ? "COMPLETO" : "INCOMPLETO"}
          </span>
        ),
      },
    ],
    sortBy: "",
  };

  componentDidMount = async () => {
    try {
      const { status, data } = await trackPromise(getFraps());
      const user = auth.getCurrentUser();
      if (status === 200) {
        const dataSumm = data.slice(0, this.state.filters.registros);
        let layout = [...this.state.layout];
        let headers = [...this.state.headers];
        layout[3] = {
          name: user.tipo === "Gerencia" ? "ValidadoTxGer" : "ValidadoTx",
          label: "Validado: ",
        };
        headers[7] = {
          name: "Validado",
          head: "Validado",
          sort: "desc",
          content: (frap) => (
            <span
              className={
                frap[user.tipo === "Gerencia" ? "validadoger" : "Validado"] ===
                1
                  ? "label-activo"
                  : "label-cancelado"
              }
            >
              {frap[user.tipo === "Gerencia" ? "validadoger" : "Validado"] === 1
                ? "COMPLETO"
                : "INCOMPLETO"}
            </span>
          ),
        };
        this.setState({ data, dataSumm, user, layout, headers });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Verifica tu conexión a internet e intenta de nuevo",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  changeView = (view) => {
    this.setState({ view });
  };

  sortColumn = (name, sort, key) => {
    const { data, headers, filters } = this.state;
    const registros = data.slice(0, filters.registros);
    const order = sort === "desc" ? "asc" : "desc";
    const dataOrder = _.orderBy(registros, name, order);
    const updateHeader = [...headers];
    updateHeader[key].sort = order;
    this.setState({ dataSumm: dataOrder, headers: updateHeader });
  };

  changeFilter = ({ currentTarget: input }) => {
    const { data } = this.state;
    const filters = { ...this.state.filters };
    filters[input.name] = input.value;
    const dataSumm = filters.busqueda
      ? data.filter(
          (w) =>
            w.NombreFinal.toLowerCase().includes(
              filters.busqueda.toLowerCase()
            ) || w.idFrap?.toString().startsWith(filters.busqueda)
        )
      : data
      ? data.slice(0, filters.registros)
      : [];

    this.setState({ dataSumm, filters });
  };

  showModal = () => {
    const newModal = !this.state.newModal;
    const infoType = newModal ? this.state.infoType : "n";
    const dataSumm =
      newModal === false
        ? this.state.data.slice(0, this.state.filters.registros)
        : this.state.dataSumm;
    const filters = { ...this.state.filters };
    filters["busqueda"] = newModal === false ? "" : filters["busqueda"];
    this.setState({ newModal, infoType, dataSumm, filters });
  };

  insertFrap = (frap) => {
    const { data, filters } = this.state;
    data.unshift(frap);

    const dataSumm = data.slice(0, filters.registros);

    this.setState({
      data,
      dataSumm,
      infoType: "n",
      newModal: false,
    });
  };

  updateInfo = (info) => {
    const { data, filters } = this.state;
    const id = info.idTransaction;
    const i = _.findIndex(data, { idTransaction: id });

    if (info.Tipo === "PARTICULAR" && info.Paciente !== "") {
      info.NombreFinal = info.Paciente;
    }

    data[i] = info;

    const dataSumm = data.slice(0, filters.registros);
    filters["busqueda"] = "";

    this.setState({ data, dataSumm, infoType: "n", newModal: false, filters });
  };

  loadInfo = (data) => {
    if (this.state.validationMode) {
      //agregamos al arreglo el id
      const validationArray = this.state.validationArray;
      const id = data.idTransaction;
      const findElement = validationArray.indexOf(id);
      const pagos =
        data["tblpagos"] === "" || data["tblpagos"] === null
          ? [
              {
                fecha: "",
                monto: 0,
                movimiento: "",
                forma: "",
              },
              {
                fecha: "",
                monto: 0,
                movimiento: "",
                forma: "",
              },
              {
                fecha: "",
                monto: 0,
                movimiento: "",
                forma: "",
              },
              {
                fecha: "",
                monto: 0,
                movimiento: "",
                forma: "",
              },
            ]
          : JSON.parse(data["tblpagos"]);
      console.log(pagos);
      const totalPagado =
        (pagos[0]["monto"] === "" ? 0 : parseInt(pagos[0]["monto"])) +
        (pagos[1]["monto"] === "" ? 0 : parseInt(pagos[1]["monto"])) +
        (pagos[2]["monto"] === "" ? 0 : parseInt(pagos[2]["monto"])) +
        (pagos[3]["monto"] === "" ? 0 : parseInt(pagos[3]["monto"]));

      if (findElement === -1) {
        if (
          data.Status_Pago === "PTE PAGO" ||
          data.Status_Pago === "" ||
          data.Status_Factura === "POR FACTURAR" ||
          data.Status_Factura === "" ||
          (data.Status_Pago === "PAGADO" && totalPagado <= 0) ||
          (data.Validado === 1 && data.validadoger === 1)
        ) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "No cumple los criterios para validación",
            showConfirmButton: false,
            timer: 1800,
            toast: true,
          });
        } else {
          // lo agregamos a la lista
          validationArray.push(id);
        }
      } else {
        //lo sacamos de la selección
        validationArray.splice(findElement, 1);
      }

      this.setState({ validationArray });
    } else {
      this.showModal();
      const infoType = "u";
      const selectedFrap = { ...data };
      delete selectedFrap.Registro;
      delete selectedFrap.Status;
      delete selectedFrap.Manual;
      delete selectedFrap.ValidadoTx;
      delete selectedFrap.ValidadoTxGer;
      delete selectedFrap.id_Llamada;
      this.setState({ selectedFrap, infoType });
    }
  };

  setValidation = () => {
    const validationMode = !this.state.validationMode;
    const headers = this.state.headers;
    if (validationMode) {
      const selectCol = {
        name: "checkVal",
        head: "Selecciona",
        sort: "desc",
        content: (frap) =>
          frap.Status_Pago === "PTE PAGO" ||
          frap.Status_Pago === "" ||
          frap.Status_Factura === "POR FACTURAR" ||
          frap.Status_Factura === "" ||
          (frap.Validado === 1 && frap.validadoger === 1) ? null : (
            <div className="text-center">
              <FontAwesomeIcon
                className={
                  this.state.validationArray.indexOf(frap.idTransaction) === -1
                    ? ""
                    : "text-blue-500"
                }
                icon={[
                  "far",
                  this.state.validationArray.indexOf(frap.idTransaction) === -1
                    ? "circle"
                    : "check-circle",
                ]}
              />
            </div>
          ),
      };

      headers.push(selectCol);
    } else {
      headers.pop();
    }
    this.setState({ headers, validationMode });
  };

  handleValidation = () => {
    const arr = this.state.validationArray;
    if (arr.length > 0) {
      Swal.fire({
        title: "¿Quieres validar los FRAPs seleccionados?",
        text: "Se actualizará en la base de datos",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#10B981",
        cancelButtonColor: "#EF4444",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //send to validation
          try {
            const body = {
              ids: arr.toString(),
            };
            const res = await trackPromise(validateFraps(body));
            if (res.status === 200) {
              const { data, headers } = this.state;
              for (let i = 0; i < arr.length; i++) {
                const index = _.findIndex(data, { idTransaction: arr[i] });
                data[index]["Validado"] = 1;
                if (this.state.user.tipo === "Gerencia") {
                  data[index]["validadoger"] = 1;
                }
              }
              headers.pop();

              this.setState({
                data,
                headers,
                validationArray: [],
                validationMode: false,
              });

              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Validación de FRAPs completa",
                showConfirmButton: false,
                timer: 1800,
                toast: true,
              });
            }
          } catch (err) {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Ocurrió un error cargando los datos",
              showConfirmButton: false,
              timer: 1800,
              toast: true,
            });
          }
        }
      });
    } else {
      // don't do nothing
    }
  };

  handleExport = async () => {
    try {
      if (this.state.user.tipo !== "Paramedico") {
        const { status, data } = await trackPromise(exportFraps());
        if (status === 200) {
          let wb = XLSX.utils.book_new();
          let ws = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(wb, ws, "Fraps");
          XLSX.writeFile(wb, "Fraps.csv");
        }
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const {
      dataSumm,
      headers,
      view,
      layout,
      filters,
      newModal,
      infoType,
      selectedFrap,
      validationMode,
      validationArray,
      user,
    } = this.state;

    return (
      <Fragment>
        {newModal && (
          <NewFrap
            onCancel={this.showModal}
            onInsert={this.insertFrap}
            onUpdate={this.updateInfo}
            data={selectedFrap}
            type={infoType}
            user={user}
          />
        )}
        <LoadingComp />
        <NavBar page={this.props.match.path} />
        <div className="p-5 h-screen">
          <h1 className="block text-center text-3xl text-red-700">
            Módulo de Fraps
          </h1>
          <InfoColor
            view={view}
            colors={[
              { color: "green", label: "PAGADO" },
              { color: "yellow", label: "SIN COSTO" },
              { color: "red", label: "PTE PAGO" },
            ]}
          />
          <InfoOptions
            view={view}
            filters={filters}
            pholder={"Frap o Cliente"}
            btnText={"Nuevo Frap"}
            btnColor={"btn-red"}
            validation={true}
            showModal={this.showModal}
            changeFilter={this.changeFilter}
            changeView={this.changeView}
            setValidation={this.setValidation}
            validationMode={validationMode}
            handleValidation={this.handleValidation}
            handleExport={this.handleExport}
            user={user}
          />

          <InfoTable
            headColor={"bg-red-200"}
            headers={headers}
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            sortColumn={this.sortColumn}
            view={view}
          />

          <InfoGrid
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            layout={layout}
            view={view}
            validationMode={validationMode}
            validationArray={validationArray}
          />
        </div>
      </Fragment>
    );
  }
}

export default Fraps;

import http from "./httpService";

export function getTabExport() {
  return http.get("/tabulador/tab/export");
}

export function getTab() {
  return http.get("/tabulador/tab");
}

export function getPrices() {
  return http.get("/tabulador");
}

export function createTab(body) {
  return http.post("/tabulador", body);
}

export function updateTab(body) {
  return http.put("/tabulador", body);
}

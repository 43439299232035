import React from "react";
import _ from "lodash";

const InfoTable = (props) => {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.name);
  };

  return (
    <React.Fragment>
      <div
        className={
          "py-5 h-full hidden " + (props.view === "table" ? "md:block" : "")
        }
      >
        <div className="shadow overflow-auto border-b border-gray-100 rounded-md h-2/3">
          <table className="table-fixed w-full divide-y divide-gray-100">
            <thead className={props.headColor + " font-normal text-left"}>
              <tr>
                {props.headers.map((e, i) => (
                  <th className="py-1 w-32" key={i}>
                    <span
                      className="px-2 cursor-pointer"
                      onClick={() => props.sortColumn(e.name, e.sort, i)}
                      name={e.name}
                    >
                      {e.head}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {props.dataSumm.map((item, i) => (
                <tr
                  className={"hover:bg-blue-50 cursor-pointer "}
                  key={i}
                  onClick={() => props.loadInfo(item)}
                >
                  {props.headers.map((column, i) => (
                    <td key={i + "/" + column.name} className="py-3 px-1">
                      {renderCell(item, column)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InfoTable;

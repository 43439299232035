import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from "react-promise-tracker";
import fileDownload from "js-file-download";
//Importar Servicios
import {
  postCotizaciones,
  updateCotizaciones,
} from "./../../services/cotizacionService";
import { getPdfE, createPdfE } from "../../services/createPdf";

class NewCotizacion extends FormRules {
  state = {
    data: {
      Nombre: "",
      Status: "",
      Dirigido: "",
      Empresa: "",
      Vigencia: "",
      Tipo: "",
      //Campos para eventos
      Factura: 0,
      Costo: 0,
      Costo_Extra: 0,
      Lugar: "",
      Contacto: "",
      Telefono: "",
      //Ciclo para diferentes recurrencias
      fecha1: "",
      horai1: "",
      horaf1: "",
      wks1: 1,
      fecha2: "",
      horai2: "",
      horaf2: "",
      wks2: 0,
      fecha3: "",
      horai3: "",
      horaf3: "",
      wks3: 0,
      fecha4: "",
      horai4: "",
      horaf4: "",
      wks4: 0,
    },
    errors: {},
    dw: { label: "1/4", input: "3/4" },
    loInfo: {
      id: 0,
      fechas: [],
      totalHrs: 0,
    },
  };

  schema = {
    Nombre: Joi.string().required(),
    Status: Joi.optional(),
    Dirigido: Joi.string().required(),
    Empresa: Joi.string().required(),
    Vigencia: Joi.string().required(),
    Tipo: Joi.optional(),
    //Campos para eventos
    Factura: Joi.optional(),
    Costo: Joi.number().required(),
    Costo_Extra: Joi.number().optional(),
    Lugar: Joi.string().required(),
    Contacto: Joi.string().required(),
    Telefono: Joi.optional(),
    //Ciclo para diferentes recurrencias
    fecha1: Joi.optional(),
    horai1: Joi.optional(),
    horaf1: Joi.optional(),
    wks1: Joi.optional(),
    fecha2: Joi.optional(),
    horai2: Joi.optional(),
    horaf2: Joi.optional(),
    wks2: Joi.optional(),
    fecha3: Joi.optional(),
    horai3: Joi.optional(),
    horaf3: Joi.optional(),
    wks3: Joi.optional(),
    fecha4: Joi.optional(),
    horai4: Joi.optional(),
    horaf4: Joi.optional(),
    wks4: Joi.optional(),
  };

  componentDidMount = () => {
    if (this.props.type === "u") {
      const data = { ...this.props.data };
      data["Empresa"] = data["FinalEmpresa"];
      data["fecha1"] = data["fecha_evento"]
        ? data["fecha_evento"]
        : data["fecha1"];
      data["Vigencia"] = moment(data["Vigencia"], "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let totalHrs = 0;
      for (let i = 0; i < data["fechas"].length; i++) {
        totalHrs += parseFloat(data["fechas"][i].HourDiff);
      }
      const loInfo = {
        id: data["idCotizacion"],
        fechas: data["fechas"],
        totalHrs: totalHrs,
      };
      if (data["reg"]) {
        delete data["reg"];
      }
      delete data["FinalEmpresa"];
      delete data["fecha_evento"];
      delete data["idCotizacion"];
      delete data["fechas"];
      this.setState({ data, loInfo });
    }
  };

  calculateHours = () => {
    const data = { ...this.state.data };
    let hrs = 0;
    for (let i = 1; i <= 4; i++) {
      let diff = 0;
      let mins = 0;
      if (
        moment(data["fecha" + i], "YYYY-MM-DD").isValid() &&
        moment(data["horai" + i], "HH:mm").isValid() &&
        moment(data["horaf" + i], "HH:mm").isValid()
      ) {
        diff = moment(data["horaf" + i], "HH:mm").diff(
          moment(data["horai" + i], "HH:mm"),
          "hours"
        );
        mins =
          Math.round(
            ((moment(data["horaf" + i], "HH:mm").diff(
              moment(data["horai" + i], "HH:mm"),
              "minutes"
            ) %
              60) /
              60) *
              100
          ) / 100;
        //Set weeks to 1 if it's the first entry
        if (data["wks" + i] === 0) {
          data["wks" + i] = 1;
          this.setState({ data });
        }
      }
      //Case for after 00:00 hours
      diff =
        diff < 0
          ? 24 -
            moment(data["horai" + i], "HH:mm").diff(
              moment(data["horaf" + i], "HH:mm"),
              "hours"
            ) +
            mins
          : diff + mins;
      diff *= data["wks" + i];
      hrs += diff;
    }
    return hrs;
  };

  formatDate = (fecha) => {
    return (
      fecha.substr(8, 2) + "/" + fecha.substr(5, 2) + "/" + fecha.substr(0, 4)
    );
  };

  handleSubmitPdf = async () => {
    try {
      let body = { ...this.state.data };
      body["total"] = (
        this.state.loInfo.totalHrs * body["Costo"] +
        body["Costo_Extra"]
      ).toFixed(2);
      body["totalIVA"] = (body["total"] * 1.16).toFixed(2);
      body["fechas"] = this.state.loInfo.fechas;
      body["idCotizacion"] = this.state.loInfo.id;
      const res = await trackPromise(createPdfE(body));
      if (res.status === 200) {
        const resFile = await trackPromise(getPdfE());
        if (res.status === 200) {
          //console.log(resFile.data);
          const pdfBlob = new Blob([resFile.data], {
            type: "application/pdf;charset=utf-8",
          });
          fileDownload(pdfBlob, "cotizacion.pdf");
        }
      }
    } catch (e) {
      console.log("ERROR AL DESCARGAR PDF");
    }
  };

  doSubmit = () => {
    const { Costo, Costo_Extra } = this.state.data;
    const totalDiff = this.props.type === "n" ? this.calculateHours() : 0;
    const totalVal =
      this.props.type === "n" ? totalDiff * Costo + Costo_Extra : 1;
    if (
      (totalVal >= 0 && !isNaN(totalVal) && this.props.type === "n") ||
      this.props.type === "u"
    ) {
      Swal.fire({
        title:
          this.props.type === "u"
            ? "¿Quieres actualizar la cotización?"
            : "¿Quieres registrar la cotización?",
        text: "Se actualizará en la base de datos",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: this.props.type === "n" ? "#10B981" : "#8B5CF6",
        cancelButtonColor: "#EF4444",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const data = { ...this.state.data };
            if (this.props.type === "n") {
              let reg = 4; //How many lines are we going to put in eventos
              for (let i = 0; i <= 4; i++) {
                if (data["wks" + i] === 0) {
                  delete data["fecha" + i];
                  delete data["horai" + i];
                  delete data["horaf" + i];
                  delete data["wks" + i];
                  reg -= 1;
                }
              } //End of For Loop
              data["reg"] = reg;
              data["Tipo"] = "EVENTO";
              data["Status"] = "PENDIENTE";
              const res = await postCotizaciones(data);
              if (res.status === 200) {
                data["idCotizacion"] = res.data.insertId;
                data["fecha_evento"] = this.formatDate(data.fecha1);
                data["FinalEmpresa"] = data.Empresa.toUpperCase();
                data["Nombre"] = data["Nombre"].toUpperCase();
                this.props.onInsert(data);
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Cotización agregada con éxito",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
              } else {
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Ocurrió un error cargando los datos",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
              }
            } else {
              const { id } = this.state.loInfo;
              data["idCotizacion"] = id;
              const res = await updateCotizaciones(data);
              if (res.status === 200) {
                data["fecha_evento"] = data.fecha1;
                data["FinalEmpresa"] = data.Empresa.toUpperCase();
                data["Nombre"] = data["Nombre"].toUpperCase();
                this.props.onUpdate(data);
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Cotización actualizada con éxito",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
              } else {
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Ocurrió un error cargando los datos",
                  showConfirmButton: false,
                  timer: 1800,
                  toast: true,
                });
              }
            }
          } catch (err) {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Ocurrió un error cargando los datos",
              showConfirmButton: false,
              timer: 1800,
              toast: true,
            });
          }
        }
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error al calcular horas de la cotización",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const { dw, loInfo } = this.state;
    const { Costo, Costo_Extra } = this.state.data;
    const totalDiff = this.calculateHours();
    const totalVal = totalDiff * Costo + Costo_Extra;

    return (
      <React.Fragment>
        <div className="modal-back z-20">
          <div className="px-2">
            <div className="modal-box md:w-6/12 top-5 mb-5">
              <span className="modal-title">
                {this.props.type === "n" ? null : (
                  <FontAwesomeIcon
                    className={"mr-2 cursor-pointer text-yellow-500"}
                    icon={["far", "file-pdf"]}
                    onClick={this.handleSubmitPdf}
                  />
                )}
                Captura de Evento{" "}
                <FontAwesomeIcon
                  className="ml-1 text-red-500 cursor-pointer"
                  icon={["far", "times-circle"]}
                  onClick={() => this.props.onCancel()}
                />
              </span>
              <form className="flex flex-wrap space-y-3 pt-3">
                {this.renderInputH(
                  "Dirigido",
                  "Dirigido a",
                  "text",
                  "",
                  dw.label,
                  dw.input
                )}
                {this.renderInputH(
                  "Empresa",
                  "Empresa",
                  "text",
                  "",
                  dw.label,
                  dw.input
                )}
                {this.renderInputH(
                  "Nombre",
                  "Nombre de Evento",
                  "text",
                  "",
                  dw.label,
                  dw.input
                )}
                {this.renderInputH(
                  "Lugar",
                  "Lugar del Evento",
                  "text",
                  "",
                  dw.label,
                  dw.input
                )}
                <div className="flex flex-wrap w-full gap-y-3">
                  <div className="w-full md:w-1/2">
                    {this.renderInputH(
                      "Contacto",
                      "Correo",
                      "text",
                      "",
                      dw.label,
                      dw.input
                    )}
                  </div>
                  <div className="w-full md:w-1/2 md:pl-3">
                    {this.renderInputH(
                      "Telefono",
                      "Teléfono",
                      "text",
                      "",
                      dw.label,
                      dw.input
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap w-full gap-y-3">
                  <div className="w-full md:w-2/3">
                    {this.renderInputH(
                      "Vigencia",
                      "Vigencia",
                      "date",
                      "",
                      dw.label,
                      dw.input
                    )}
                  </div>
                  <div className="w-full md:w-1/3 md:pl-4 md:pt-1">
                    {this.renderToggle("Factura", "Necesita Factura")}
                  </div>
                </div>
                {this.props.type === "u" ? (
                  <React.Fragment>
                    {this.renderSelect(
                      "Status",
                      "Estatus Cotización",
                      "text",
                      [
                        {
                          vis: "PENDIENTE",
                          val: "PENDIENTE",
                        },
                        {
                          vis: "APROBADO",
                          val: "APROBADO",
                        },
                        {
                          vis: "CANCELADO",
                          val: "CANCELADO",
                        },
                        {
                          vis: "RECHAZADO",
                          val: "RECHAZADO",
                        },
                      ],
                      dw.label,
                      dw.input
                    )}
                    <div className="flex flex-wrap w-full text-sm">
                      <span className="w-full text-center md:w-1/4">
                        {"Horas: " + loInfo.totalHrs}
                      </span>
                      <span className="w-full text-center md:w-1/4">
                        {"Costo Hora: " + Costo}
                      </span>
                      <span className="w-full text-center md:w-1/4">
                        {"Costo Extra: " + Costo_Extra}
                      </span>
                      <span>
                        {"Total: $" + (loInfo.totalHrs * Costo + Costo_Extra)}
                      </span>
                    </div>
                    <div className="flex flex-wrap w-full text-sm bg-gray-200 border border-gray-500 rounded">
                      <span className="w-full text-center font-semibold">
                        {" "}
                        Resumen de Fechas
                      </span>
                      {loInfo["fechas"].map((item, key) => (
                        <span
                          className="w-full text-center md:w-1/3 p-2"
                          key={key}
                        >
                          {item["fecha_evento"] +
                            " " +
                            item["horai"] +
                            " - " +
                            item["horaf"]}
                        </span>
                      ))}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="flex flex-wrap w-full gap-y-3">
                      <div className="w-full md:w-1/4">
                        {this.renderInputH(
                          "Costo",
                          "Costo Hora",
                          "number",
                          "",
                          "7/12",
                          "5/12"
                        )}
                      </div>
                      <div className="w-full md:w-1/4 md:pl-3">
                        {this.renderInputH(
                          "Costo_Extra",
                          "Costo Extra",
                          "number",
                          "",
                          "7/12",
                          "5/12"
                        )}
                      </div>
                      <div className="w-full md:w-1/4 text-center">
                        <span className="text-sm">{"Horas: " + totalDiff}</span>
                      </div>
                      <div className="w-full md:w-1/4 text-center">
                        <span className="text-sm font-semibold">
                          {"Total: $" + totalVal}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-wrap w-full border-2 border-gray-100 rounded">
                      <div className="w-full text-center">
                        <span className="text-sm font-semibold">
                          Recurrencia
                        </span>
                      </div>
                      <div className="w-full flex flex-wrap space-x-2 px-2 pt-1 pb-2 justify-around">
                        <div className="w-full md:w-1/3 text-center">
                          {this.renderInputH(
                            "fecha1",
                            "Fecha",
                            "date",
                            "",
                            "full",
                            "full"
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horai1",
                            "Hora Inicio",
                            "full",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horaf1",
                            "Hora Fin",
                            "full",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-2/12 text-center">
                          {this.renderInputH(
                            "wks1",
                            "Semanas",
                            "number",
                            "",
                            "full",
                            "full"
                          )}
                        </div>
                      </div>
                      <div className="w-full flex flex-wrap space-x-2 px-2 pt-1 pb-2 justify-around">
                        <div className="w-full md:w-1/3 text-center">
                          {this.renderInputH(
                            "fecha2",
                            "",
                            "date",
                            "",
                            "0",
                            "full"
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horai2",
                            "",
                            "0",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horaf2",
                            "",
                            "0",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-2/12 text-center">
                          {this.renderInputH(
                            "wks2",
                            "",
                            "number",
                            "",
                            "0",
                            "full"
                          )}
                        </div>
                      </div>
                      <div className="w-full flex flex-wrap space-x-2 px-2 pt-1 pb-2 justify-around">
                        <div className="w-full md:w-1/3 text-center">
                          {this.renderInputH(
                            "fecha3",
                            "",
                            "date",
                            "",
                            "0",
                            "full"
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horai3",
                            "",
                            "0",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horaf3",
                            "",
                            "0",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-2/12 text-center">
                          {this.renderInputH(
                            "wks3",
                            "",
                            "number",
                            "",
                            "0",
                            "full"
                          )}
                        </div>
                      </div>
                      <div className="w-full flex flex-wrap space-x-2 px-2 pt-1 pb-2 justify-around">
                        <div className="w-full md:w-1/3 text-center">
                          {this.renderInputH(
                            "fecha4",
                            "",
                            "date",
                            "",
                            "0",
                            "full"
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horai4",
                            "",
                            "0",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-1/5 text-center">
                          {this.renderTimePicker(
                            "horaf4",
                            "",
                            "0",
                            "full",
                            false
                          )}
                        </div>
                        <div className="w-full md:w-2/12 text-center">
                          {this.renderInputH(
                            "wks4",
                            "",
                            "number",
                            "",
                            "0",
                            "full"
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className="flex space-x-4 justify-center w-full pt-3">
                  <div className="w-1/2 md:w-1/6">
                    {this.props.type === "n" ? (
                      <button
                        className="btn-success w-full"
                        type={"button"}
                        onClick={this.handleSubmit}
                      >
                        Guardar
                      </button>
                    ) : (
                      <button
                        className="btn-purple w-full"
                        type={"button"}
                        onClick={this.handleSubmit}
                      >
                        Actualizar
                      </button>
                    )}
                  </div>
                  <div className="w-1/2 md:w-1/6">
                    <button
                      className="btn-red w-full"
                      type="button"
                      onClick={() => this.props.onCancel()}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewCotizacion;

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Importar Componentes
import NavBar from "./navBar";
import ListHandler from "../forms/listHandler";
import UserHandler from "../forms/userHandler";
//Importar Iconos
import mobile from "./../../assets/img/menu_rapido/mobile_phone.png";
import fraps from "./../../assets/img/menu_rapido/fraps.png";
import cotizacion from "./../../assets/img/menu_rapido/cotizacion.png";
import reportes from "./../../assets/img/menu_rapido/reportes.png";
import logo from "./../../assets/img/logo.png";

class Index extends Component {
  state = {
    settings: false,
    users: false,
  };

  handleSettings = () => {
    const settings = !this.state.settings;
    this.setState({ settings });
  };

  handleUsers = () => {
    const users = !this.state.users;
    this.setState({ users });
  };

  render() {
    const { settings, users } = this.state;
    return (
      <React.Fragment>
        {users ? <UserHandler onUsers={this.handleUsers} /> : null}
        {settings ? <ListHandler onSettings={this.handleSettings} /> : null}
        <NavBar
          page={this.props.match.path}
          onSettings={this.handleSettings}
          onUsers={this.handleUsers}
        />
        <div className="bg-gradient-to-r from-red-100 to-blue-100 h-screen">
          <div className="pt-4 px-5">
            <h1 className="flex justify-center text-3xl">
              {/*Bienvenido a Life Support*/}
              <img src={logo} alt="Logo Life Support" className="w-1/3" />
            </h1>
            <h4 className="block text-center text-md py-2">
              <FontAwesomeIcon className="mr-1" icon={["fas", "user"]} />
              {this.props?.user?.usuario}
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4 pt-5">
              <a href="/llamadas">
                <div className="box-quickacc">
                  <div className="header-quickacc">
                    <span>Llamadas</span>
                  </div>
                  <div className="body-quickacc">
                    <img
                      src={mobile}
                      alt="Mobile Phone"
                      className="image-quickacc"
                    />
                  </div>
                </div>
              </a>
              <a href="/fraps">
                <div className="box-quickacc">
                  <div className="header-quickacc">
                    <span>Fraps</span>
                  </div>
                  <div className="body-quickacc">
                    <img
                      src={fraps}
                      alt="Mobile Phone"
                      className="image-quickacc"
                    />
                  </div>
                </div>
              </a>
              <a href="/cotizaciones">
                <div className="box-quickacc">
                  <div className="header-quickacc">
                    <span>Cotizaciones</span>
                  </div>
                  <div className="body-quickacc">
                    <img
                      src={cotizacion}
                      alt="Mobile Phone"
                      className="image-quickacc"
                    />
                  </div>
                </div>
              </a>

              <div className="box-quickacc">
                <div className="header-quickacc">
                  <span>Reportes</span>
                </div>
                <div className="body-quickacc">
                  <img
                    src={reportes}
                    alt="Mobile Phone"
                    className="image-quickacc"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Index;

import React, { Component } from "react";
import Swal from "sweetalert2";
import _ from "lodash";
import { trackPromise } from "react-promise-tracker";
//Importar Componentes
import NavBar from "./navBar";
import LoadingComp from "../tools/loadingComp";
import InfoTable from "../views/infoTable";
import InfoGrid from "../views/infoGrid";
import InfoColor from "../views/infoColor";
import InfoOptions from "../views/infoOptions";
import NewCotizacion from "../forms/newCotizacion";
//Importar Servicios
import {
  getCotizaciones,
  getCotizacionUpt,
} from "./../../services/cotizacionService";

class Cotizaciones extends Component {
  state = {
    data: [],
    dataSumm: [],
    view: "table",
    newModal: false,
    infoType: "n",
    selectedCoti: {},
    filters: {
      registros: 20,
      busqueda: "",
    },
    layout: [
      {
        name: "idCotizacion",
        label: "Cotización: #",
      },
      {
        name: "fecha_evento",
        label: "",
      },
      { name: "FinalEmpresa", label: "Cliente: " },
      { name: "Nombre", label: "Dirigido: " },
      { name: "Tipo", label: "Tipo: " },
      { name: "Status", label: "" },
    ],
    headers: [
      {
        name: "idCotizacion",
        head: "Id",
        sort: "desc",
      },
      {
        name: "Nombre",
        head: "Evento",
        sort: "desc",
      },
      {
        name: "FinalEmpresa",
        head: "Cliente",
        sort: "desc",
      },
      {
        name: "Tipo",
        head: "Tipo",
        sort: "desc",
      },
      {
        name: "fecha_evento",
        head: "Fecha",
        sort: "desc",
      },
      {
        name: "Status",
        head: "Status",
        sort: "desc",
        content: (cotizacion) => (
          <span
            className={
              cotizacion.Status === "APROBADO"
                ? "label-activo"
                : cotizacion.Status === "CANCELADO"
                ? "label-cancelado"
                : cotizacion.Status === "PENDIENTE"
                ? "label-cerrado"
                : "label-rechazado"
            }
          >
            {cotizacion.Status}
          </span>
        ),
      },
    ],
    sortBy: "",
  };

  componentDidMount = async () => {
    try {
      const { status, data } = await trackPromise(getCotizaciones());
      if (status === 200) {
        const dataSumm = data.slice(0, this.state.filters.registros);
        this.setState({ data, dataSumm });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Verifica tu conexión a internet e intenta de nuevo",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  sortColumn = (name, sort, key) => {
    const { data, headers, filters } = this.state;
    const registros = data.slice(0, filters.registros);
    const order = sort === "desc" ? "asc" : "desc";
    const dataOrder = _.orderBy(registros, name, order);
    const updateHeader = [...headers];
    updateHeader[key].sort = order;
    this.setState({ dataSumm: dataOrder, headers: updateHeader });
  };

  changeFilter = ({ currentTarget: input }) => {
    const { data } = this.state;
    const filters = { ...this.state.filters };
    filters[input.name] = input.value;
    const dataSumm = filters.busqueda
      ? data.filter(
          (w) =>
            w.FinalEmpresa.toLowerCase().includes(
              filters.busqueda.toLowerCase()
            ) || w.idCotizacion?.toString().startsWith(filters.busqueda)
        )
      : data
      ? data.slice(0, filters.registros)
      : [];

    this.setState({ dataSumm, filters });
  };

  changeView = (view) => {
    this.setState({ view });
  };

  loadInfo = async (data) => {
    try {
      const res = await trackPromise(getCotizacionUpt(data.idCotizacion));
      if (res.status === 200) {
        const infoType = "u";
        const { data: fechas } = res;
        const selectedCoti = { ...data };
        selectedCoti["fechas"] = fechas;
        this.setState({ selectedCoti, infoType });
        this.showModal();
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  showModal = () => {
    const newModal = !this.state.newModal;
    const infoType = newModal ? this.state.infoType : "n";
    this.setState({ newModal, infoType });
  };

  insertCotizacion = (cot) => {
    const { data, filters } = this.state;
    data.unshift(cot);

    const dataSumm = data.slice(0, filters.registros);

    this.setState({
      data,
      dataSumm,
      infoType: "n",
      newModal: false,
    });
  };

  updateInfo = (info) => {
    const { data, filters } = this.state;
    const id = info.idCotizacion;
    const i = _.findIndex(data, { idCotizacion: id });
    data[i] = info;
    const dataSumm = data.slice(0, filters.registros);
    this.setState({ data, dataSumm, infoType: "n", newModal: false });
  };

  handleExport = async () => {
    try {
      /*const { status, data } = await trackPromise(exportFraps());
      if (status === 200) {
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.json_to_sheet(data);

        XLSX.utils.book_append_sheet(wb, ws, "Llamadas");
        XLSX.writeFile(wb, "Llamadas.csv");
      }*/
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const {
      dataSumm,
      headers,
      view,
      layout,
      filters,
      newModal,
      infoType,
      selectedCoti,
    } = this.state;
    return (
      <React.Fragment>
        {newModal && (
          <NewCotizacion
            onCancel={this.showModal}
            onInsert={this.insertCotizacion}
            onUpdate={this.updateInfo}
            data={selectedCoti}
            type={infoType}
          />
        )}
        <LoadingComp />
        <NavBar page={this.props.match.path} />
        <div className="p-5 h-screen">
          <h1 className="block text-center text-3xl text-green-700">
            Módulo de Cotizaciones
          </h1>
          <InfoColor
            view={view}
            colors={[
              { color: "green", label: "APROBADO" },
              { color: "yellow", label: "PENDIENTE" },
              { color: "red", label: "CANCELADO" },
              { color: "purple", label: "RECHAZADO" },
            ]}
          />
          <InfoOptions
            view={view}
            filters={filters}
            pholder={"Cotización o Cliente"}
            btnText={"Nuevo Evento"}
            btnColor={"btn-secondary"}
            validation={false}
            showModal={this.showModal}
            changeFilter={this.changeFilter}
            changeView={this.changeView}
            handleExport={this.handleExport}
          />
          <InfoTable
            headColor={"bg-green-200"}
            headers={headers}
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            sortColumn={this.sortColumn}
            view={view}
          />
          <InfoGrid
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            layout={layout}
            view={view}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Cotizaciones;

import http from "./httpService";

export function getPdf() {
  return http.get("/createpdf", { responseType: "blob" });
}

export function createPdf(body) {
  return http.post("/createpdf", body);
}

export function getPdfE() {
  return http.get("/createpdfe", { responseType: "blob" });
}

export function createPdfE(body) {
  return http.post("/createpdfe", body);
}

import http from "./httpService";

export function getEvents() {
  return http.get("/eventos");
}

export function updateEvento(body) {
  return http.put("/eventos", body);
}

export function exportEventos() {
  return http.get("/eventos/export");
}

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Footer from "./components/main/footer";
import reportWebVitals from "./reportWebVitals";

//Import styles
import "./assets/css/main.css";
// import your fontawesome library
import "./fontawesome";
// import tippy library
import "react-tippy/dist/tippy.css";

ReactDOM.render(
  <React.Fragment>
    <div className="page-container">
      <div className="content-wrap">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

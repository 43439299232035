import React from "react";
import FormRules from "./formRules";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import _ from "lodash";
//Importar Componentes
import LoadingComp from "../tools/loadingComp";
//Importar Servicios
import { getLists, getListsTx } from "./../../services/listaService";
import { updateCall } from "../../services/llamadaService";
import { getPrices, getTab } from "../../services/tabulador";
//ScreenShot
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

class InfoCall extends FormRules {
  state = {
    data: {
      Caller: "",
      Tipo_Servicio: "",
      Paciente: "",
      Costo: 0,
      EdadP: 0,
      Peso: 0,
      TelefonoC: "",
      Diagnostico: "",
      Lugar_Origen: "",
      Lugar_Destino: "",
      Equipo: "",
      Description: "",
      Suceso: "",
      Status: "",
      idLlamadas: "",
      lugar_destino_otro: "",
      Area_Reporte: "",
      Area_Llegada: "",
    },
    errors: {},
    listas: [],
    listasTx: [],
    precios: [],
    tabulador: [],
    capture: false,
    diss: false,
    dw: { label: "1/4", input: "3/4" },
    prevInfo: {
      NombreFinal: "",
      Tipo: "",
      idCliente: "",
      idFrap: "",
    },
  };

  schema = {
    Caller: Joi.optional().label("Caller"),
    Tipo_Servicio: Joi.string().required().label("Servicio"),
    Paciente: Joi.optional().label("Paciente"),
    Costo: Joi.number().optional().label("Costo"),
    EdadP: Joi.number().optional().label("Edad"),
    Peso: Joi.number().optional().label("Peso"),
    TelefonoC: Joi.optional().label("Telefono"),
    Diagnostico: Joi.optional().label("Diagnostico"),
    Lugar_Origen: Joi.optional().label("Lugar_Origen"),
    Lugar_Destino: Joi.optional().label("Lugar_Destino"),
    Equipo: Joi.optional().label("Equipo"),
    Description: Joi.optional().label("Description"),
    Suceso: Joi.optional().label("Suceso"),
    Status: Joi.string().required().label("Status"),
    idLlamadas: Joi.number().optional().label("Id"),
    lugar_destino_otro: Joi.optional(),
    Area_Reporte: Joi.optional(),
    Area_Llegada: Joi.optional(),
  };

  componentDidMount = async () => {
    try {
      const { status, data: listas } = await trackPromise(getLists());
      const { status: statusTx, data: listasTx } = await getListsTx();
      const { status: statusPrice, data: precios } = await trackPromise(
        getPrices()
      );
      const { status: statusTab, data: tabulador } = await trackPromise(
        getTab()
      );
      if (
        status === 200 &&
        statusTx === 200 &&
        statusPrice === 200 &&
        statusTab === 200
      ) {
        const data = { ...this.props.info };
        const prevInfo = (({ NombreFinal, Tipo, idFrap, idCliente }) => ({
          NombreFinal,
          Tipo,
          idFrap,
          idCliente,
        }))(data);
        delete data.NombreFinal;
        delete data.Tipo;
        delete data.idFrap;
        delete data.idCliente;
        const servicioUrgencia = [
          "AREA PROTEGIDA",
          "EVENTO",
          "AREA PROTEGIDA POR EVENTO",
          "MEMBRESIAS FAMILIARES",
        ];

        if (this.props.type === "u") {
          if (
            prevInfo.Tipo === "SEGUROS ATLAS" &&
            data["Tipo_Servicio"] === ""
          ) {
            data["Tipo_Servicio"] = "URGENCIA ESCOLAR";
          }
          if (
            servicioUrgencia.includes(prevInfo.Tipo) &&
            data["Tipo_Servicio"] === ""
          ) {
            data["Tipo_Servicio"] = "URGENCIA";
          }
          const tipoAllAcc = ["Administracion", "Paramedico"];
          const diss = tipoAllAcc.includes(this.props.user?.tipo)
            ? true
            : false;
          this.setState({
            data,
            prevInfo,
            listas,
            listasTx,
            precios,
            tabulador,
            diss,
          });
        } //Si estamos abriendo un registro desde la tabla o el grid
        else {
          const { data: currentCall } = this.state;
          currentCall["idLlamadas"] = data.idLlamadas;
          currentCall["Status"] = data.Status;
          if (prevInfo.Tipo === "SEGUROS ATLAS") {
            currentCall["Tipo_Servicio"] = "URGENCIA ESCOLAR";
            const precio = this.handleSearchLevels(
              "SEGUROS ATLAS",
              prevInfo.NombreFinal,
              "URGENCIA ESCOLAR",
              tabulador
            );
            currentCall["Costo"] =
              precio.length > 0 ? Math.ceil(precio[0].fix) : 0;
          }

          if (servicioUrgencia.includes(prevInfo.Tipo)) {
            currentCall["Tipo_Servicio"] = "URGENCIA";
            const precio = this.handleSearchLevels(
              prevInfo.Tipo,
              prevInfo.NombreFinal,
              "URGENCIA",
              tabulador
            );
            currentCall["Costo"] =
              precio.length > 0 ? Math.ceil(precio[0].fix) : 0;
          }

          this.setState({
            data: currentCall,
            prevInfo,
            listas,
            listasTx,
            precios,
            tabulador,
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (err) {}
  };

  closeModal = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  pullFrap = (e) => {
    e.preventDefault();
    const data = { ...this.props.info };
    const frap = (({
      idLlamadas,
      Lugar_Origen,
      Area_Llegada,
      Lugar_Destino,
      Tipo_Servicio,
      Costo,
      Paciente,
      Diagnostico,
      Suceso,
      EdadP,
    }) => ({
      idLlamadas,
      Lugar_Origen,
      Area_Llegada,
      Lugar_Destino,
      Tipo_Servicio,
      Costo,
      Paciente,
      Diagnostico,
      Suceso,
      EdadP,
    }))(data);

    this.props.onFrap(frap);
  };

  doSubmit = async () => {
    try {
      const { data, prevInfo } = this.state;
      const res = await trackPromise(updateCall(data));
      if (res.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Actualización Completa",
          showConfirmButton: false,
          timer: 2200,
          toast: true,
        });

        const obj = { ...data, ...prevInfo };
        this.props.onUpdate(obj);
      }
    } catch (err) {}
  };

  onCapture = (id) => {
    this.setState({ capture: true });
    const { data, prevInfo } = this.state;
    const now = new Date();
    const fecha =
      (String(now.getDate()).length === 1
        ? "0" + now.getDate()
        : now.getDate()) +
      (String(now.getMonth()).length === 1
        ? "0" + now.getMonth()
        : now.getMonth()) +
      String(now.getFullYear());
    const fileName =
      data.idLlamadas + "_" + prevInfo.NombreFinal + "_" + fecha + ".png";
    const options =
      window.innerWidth <= 500
        ? { backgroundColor: "#d8d2d2", height: 1120 }
        : { backgroundColor: "#d8d2d2" };
    htmlToImage
      .toPng(document.getElementById(id), options)
      .then(function (dataUrl) {
        download(dataUrl, fileName);
      });
    this.returnCapture();
  };

  returnCapture = () => {
    setTimeout(() => {
      this.setState({ capture: false });
      this.doSubmit();
    }, 3000);
  };

  render() {
    const { dw, listasTx, prevInfo, capture, diss } = this.state;

    return (
      <React.Fragment>
        <div className="modal-back z-20">
          <LoadingComp />
          {prevInfo.Tipo === "" || capture === true ? (
            <LoadingComp disp={true} />
          ) : null}
          <div className="px-2">
            <div className="modal-box top-2 mb-5">
              <span className="modal-title">
                <FontAwesomeIcon
                  className="mr-2 text-green-500 cursor-pointer"
                  icon={["far", "save"]}
                  onClick={diss ? null : this.doSubmit}
                />
                Información Llamada{" "}
                <FontAwesomeIcon
                  className="mx-1 text-blue-500 cursor-pointer"
                  icon={["far", "file-image"]}
                  onClick={() => this.onCapture("infoCallForm")}
                />
                <FontAwesomeIcon
                  className="ml-1 text-red-500 cursor-pointer"
                  icon={["far", "times-circle"]}
                  onClick={this.closeModal}
                />
              </span>
              <div
                className={
                  "px-3 " +
                  (window.innerWidth <= 500 && capture ? "paddingCapture" : "")
                }
                id="infoCallForm"
              >
                <span className="modal-title text-sm border-opacity-0 py-2 pb-0">
                  {`${prevInfo.NombreFinal.toUpperCase()} (${prevInfo.Tipo})`}
                </span>
                <span className="modal-title text-sm border-opacity-0 py-2 pb-0">
                  {`Id: ${this.props.info?.idLlamadas}`}
                </span>
                <form className="pt-3 space-y-3">
                  {this.renderInputH(
                    "Caller",
                    "¿Quién Llama?",
                    "text",
                    "",
                    dw.label,
                    dw.input
                  )}
                  {prevInfo.Tipo === "AREA PROTEGIDA" ||
                  prevInfo.Tipo === "AREA PROTEGIDA POR EVENTO" ||
                  prevInfo.Tipo === "SEGUROS ATLAS"
                    ? this.renderInputH(
                        "Lugar_Origen",
                        "Área de reporte",
                        "text",
                        "",
                        dw.label,
                        dw.input
                      )
                    : null}
                  {capture
                    ? this.renderInputH(
                        "Tipo_Servicio",
                        "Tipo de Servicio",
                        "text",
                        "",
                        dw.label,
                        dw.input
                      )
                    : this.renderSelect(
                        "Tipo_Servicio",
                        "Tipo de Servicio",
                        "text",
                        _.orderBy(
                          _.filter(listasTx, { lista: "tipo_servicio" }),
                          "val"
                        ),
                        dw.label,
                        dw.input
                      )}
                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS"
                    ? this.renderInputH(
                        "Paciente",
                        "Paciente",
                        "text",
                        "",
                        dw.label,
                        dw.input
                      )
                    : null}

                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS" ? (
                    <div className="md:flex w-full md:space-x-2 md:justify-between pt-1">
                      <div className="w-full md:w-4/12 pb-2 ">
                        {this.renderInputH(
                          "EdadP",
                          "Edad",
                          "number",
                          "",
                          "2/5",
                          "3/5"
                        )}
                      </div>
                      <div className="w-full md:w-4/12 pb-2 pl-1">
                        {this.renderInputH(
                          "Peso",
                          "Peso Aprox.",
                          "number",
                          "",
                          "3/5",
                          "2/5"
                        )}
                      </div>
                      <div className="w-full md:w-4/12 pb-2 pl-1">
                        {this.renderInputH(
                          "Costo",
                          "Costo",
                          "number",
                          "",
                          "2/5",
                          "3/5"
                        )}
                      </div>
                    </div>
                  ) : (
                    this.renderInputH(
                      "Costo",
                      "Costo",
                      "number",
                      "",
                      dw.label,
                      dw.input
                    )
                  )}
                  {prevInfo.Tipo === "SEGUROS ATLAS"
                    ? this.renderTextArea(
                        "Description",
                        "Comentario",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        "4"
                      )
                    : null}
                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS"
                    ? this.renderTextArea(
                        "Diagnostico",
                        "Diagnóstico",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        "2"
                      )
                    : null}
                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS"
                    ? this.renderTextArea(
                        "Lugar_Origen",
                        "Lugar Atención",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        window.innerWidth <= 500 ? "4" : "2"
                      )
                    : null}
                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS"
                    ? capture
                      ? this.renderInputH(
                          "Lugar_Destino",
                          "Lugar Destino",
                          "text",
                          "",
                          dw.label,
                          dw.input
                        )
                      : this.renderSelect(
                          "Lugar_Destino",
                          "Lugar Destino",
                          "text",
                          _.orderBy(
                            _.filter(listasTx, { lista: "lugar_destino" }),
                            "val",
                            "asc"
                          ),
                          dw.label,
                          dw.input
                        )
                    : null}
                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS"
                    ? this.renderTextArea(
                        "lugar_destino_otro",
                        "Detalle Destino: ",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        "2"
                      )
                    : null}
                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS"
                    ? this.renderTextArea(
                        "Equipo",
                        "Equipo",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        "2"
                      )
                    : null}
                  {prevInfo.Tipo !== "AREA PROTEGIDA" &&
                  prevInfo.Tipo !== "AREA PROTEGIDA POR EVENTO" &&
                  prevInfo.Tipo !== "SEGUROS ATLAS" ? (
                    this.renderTextArea(
                      "Description",
                      "Comentario",
                      "text",
                      "",
                      dw.label,
                      dw.input,
                      "2"
                    )
                  ) : (
                    <React.Fragment>
                      {this.renderTextArea(
                        "Area_Llegada",
                        "Área de Llegada",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        "4"
                      )}
                      {this.renderTextArea(
                        "Suceso",
                        "¿Qué ocurrió?",
                        "text",
                        "",
                        dw.label,
                        dw.input,
                        "4"
                      )}
                    </React.Fragment>
                  )}
                  {this.renderInputH(
                    "TelefonoC",
                    "Teléfono",
                    "text",
                    "",
                    dw.label,
                    dw.input
                  )}

                  {prevInfo.idFrap === ""
                    ? capture
                      ? this.renderInputH(
                          "Status",
                          "Status",
                          "text",
                          "",
                          dw.label,
                          dw.input
                        )
                      : this.renderSelect(
                          "Status",
                          "Status",
                          "text",
                          [
                            { val: "ACTIVO", vis: "ACTIVO" },
                            { val: "CERRADO", vis: "CERRADO" },
                            { val: "CANCELADO", vis: "CANCELADO" },
                          ],
                          dw.label,
                          dw.input,
                          false,
                          ["Facturacion"].includes(this.props.user?.tipo) ||
                            diss
                            ? true
                            : false
                        )
                    : null}
                  {capture ? null : (
                    <div
                      className={
                        "flex flex-wrap w-full pt-3 md:space-x-2 justify-center"
                      }
                    >
                      <button
                        className="btn-success md:w-1/4 w-full my-2"
                        onClick={this.handleSubmit}
                        disabled={diss}
                      >
                        Guardar
                      </button>
                      <button
                        className="btn-secondary md:hidden w-full my-2"
                        type="button"
                        onClick={() => this.onCapture("infoCallForm")}
                      >
                        Imagen
                      </button>
                      {prevInfo.idFrap === "" &&
                      this.state.data.Status === "ACTIVO" &&
                      [
                        "Gerencia",
                        "Coordinador",
                        "Paramedico",
                        "Radioperaciones",
                      ].includes(this.props.user?.tipo) ? (
                        <button
                          className="btn-purple md:w-1/4 w-full my-2"
                          onClick={this.pullFrap}
                        >
                          Tomar Llamada
                        </button>
                      ) : null}
                      <button
                        className="btn-red md:w-1/4 w-full my-2"
                        onClick={this.closeModal}
                      >
                        Cerrar
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InfoCall;

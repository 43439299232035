import React, { Component, Fragment } from "react";
import _ from "lodash";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import auth from "../../services/authService";
import { trackPromise } from "react-promise-tracker";
//Importar Componentes
import NavBar from "./navBar";
import LoadingComp from "../tools/loadingComp";
import InfoTable from "../views/infoTable";
import InfoGrid from "../views/infoGrid";
import InfoOptions from "../views/infoOptions";
import Assignation from "../forms/assignation";
//Importar Servicios
import { getEvents, exportEventos } from "../../services/eventosService";

class Pagos extends Component {
  state = {
    data: [],
    dataSumm: [],
    arrYr: [],
    arrWeek: [],
    weekNo: 1,
    yr: 2022,
    view: "table",
    user: {},
    filters: {
      registros: 20,
      busqueda: "",
    },
    infoModal: false,
    selectedEvent: {
      idEventos: 0,
      Unidad: 0,
      Emp1: 0,
      Emp2: 0,
      Comentarios: "",
      Inicio_G: "",
      Fin_G: "",
    },
    layout: [
      {
        name: "idEventos",
        label: "",
      },
      {
        name: "fecha",
        label: "",
      },
      { name: "Nombre", label: "Cliente: " },
      { name: "Nombre_Evento", label: "Evento: " },
      { name: "Hora_Inicio", label: "Inicio: " },
      { name: "Hora_Final", label: "Fin: " },
    ],
    headers: [
      {
        name: "idEventos",
        head: "Id",
        sort: "desc",
      },
      {
        name: "Nombre",
        head: "Cliente",
        sort: "desc",
      },
      {
        name: "Nombre_Evento",
        head: "Evento",
        sort: "desc",
      },
      {
        name: "Lugar",
        head: "Lugar",
        sort: "desc",
      },
      {
        name: "fecha",
        head: "Fecha",
        sort: "desc",
      },
      {
        name: "Hora_Inicio",
        head: "Inicio",
        sort: "desc",
      },
      {
        name: "Hora_Final",
        head: "Fin",
        sort: "desc",
      },
      {
        name: "HourDiff",
        head: "Horas",
        sort: "desc",
      },
      {
        name: "Unidad",
        head: "Unidad",
        sort: "desc",
      },
      {
        name: "Emp1",
        head: "Operador",
        sort: "desc",
      },
      {
        name: "Emp2",
        head: "Paramédico",
        sort: "desc",
      },
      {
        name: "Telefono",
        head: "Telefono",
        sort: "desc",
      },
      {
        name: "Comentarios",
        head: "Observación",
        sort: "desc",
      },
      {
        name: "Inicio_G",
        head: "Inicio GPS",
        sort: "desc",
        content: (evento) => (
          <span
            className={
              "label-" +
              (evento.DiferenciaMin >= 1
                ? "cancelado"
                : evento.Inicio_G !== ""
                ? "activo"
                : "")
            }
          >
            {evento.Inicio_G}
          </span>
        ),
      },
      {
        name: "Fin_G",
        head: "Fin GPS",
        sort: "desc",
        content: (evento) => (
          <span
            className={
              "label-" +
              (evento.DiferenciaMinF >= 1
                ? "cancelado"
                : evento.Fin_G !== ""
                ? "activo"
                : "")
            }
          >
            {evento.Fin_G}
          </span>
        ),
      },
      {
        name: "Comentario_G",
        head: "Comentario GPS",
        sort: "desc",
      },
    ],
  };

  componentDidMount = async () => {
    try {
      const { status, data } = await trackPromise(getEvents());
      const user = auth.getCurrentUser();
      if (status === 200) {
        let yr = parseInt(new Date().getFullYear());
        yr -= 2;
        const arrYr = [...Array(10).keys()].map((x) => x + yr);
        const arrWeek = [...Array(54).keys()].map((x) => x + 1);
        /************* Get Week Number *****************/
        let d = new Date();
        d.setDate(d.getDate() - 4);
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        let weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7) + 2;
        // Return array of year and week number
        /************* Get Week Number *****************/
        const dataFilter = data.filter(
          (e) => e.semana === weekNo && e.yr === arrYr[1]
        );
        const dataSumm = dataFilter.slice(0, this.state.filters.registros);
        this.setState({
          data,
          dataSumm,
          user,
          arrYr,
          arrWeek,
          weekNo,
          yr: arrYr[1],
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Ocurrió un error cargando los datos",
          showConfirmButton: false,
          timer: 1800,
          toast: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Verifica tu conexión a internet e intenta de nuevo",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  changeDate = ({ currentTarget: input }) => {
    let { yr, weekNo, data } = this.state;
    yr = input.name === "yrFilter" ? parseInt(input.value) : yr;
    weekNo = input.name === "weekFilter" ? parseInt(input.value) : weekNo;
    const dataFilter = data.filter((e) => e.semana === weekNo && e.yr === yr);
    const dataSumm = dataFilter.slice(0, this.state.filters.registros);
    this.setState({ yr, weekNo, dataSumm });
  };

  sortColumn = (name, sort, key) => {
    const { data, headers, filters, weekNo, yr } = this.state;
    const registros = data.slice(0, filters.registros);
    const dataFilter = registros.filter(
      (e) => e.semana === weekNo && e.yr === yr
    );
    const order = sort === "desc" ? "asc" : "desc";
    const dataOrder = _.orderBy(dataFilter, name, order);
    const updateHeader = [...headers];
    updateHeader[key].sort = order;
    this.setState({ dataSumm: dataOrder, headers: updateHeader });
  };

  changeFilter = ({ currentTarget: input }) => {
    const { data, weekNo, yr } = this.state;
    const filters = { ...this.state.filters };
    filters[input.name] = input.value;
    const dataSumm = filters.busqueda
      ? data.filter(
          (w) =>
            w.semana === weekNo &&
            w.yr === yr &&
            (w.Nombre.toLowerCase().includes(filters.busqueda.toLowerCase()) ||
              w.idEventos.toString().startsWith(filters.busqueda) ||
              w.Nombre_Evento.toString().startsWith(filters.busqueda))
        )
      : data
      ? data
          .slice(0, filters.registros)
          .filter((w) => w.semana === weekNo && w.yr === yr)
      : [];

    this.setState({ dataSumm, filters });
  };

  showModal = () => {
    const infoModal = !this.state.infoModal;
    this.setState({ infoModal });
  };

  loadInfo = (data) => {
    this.showModal();
    const selectedEvent = (({
      idEventos,
      Unidad,
      Emp1ID,
      Emp2ID,
      Comentarios,
      Inicio_G,
      Fin_G,
      Comentario_G,
    }) => ({
      idEventos,
      Unidad,
      Emp1ID,
      Emp2ID,
      Comentarios,
      Inicio_G,
      Fin_G,
      Comentario_G,
    }))(data);
    selectedEvent["Emp1"] = selectedEvent["Emp1ID"];
    selectedEvent["Emp2"] = selectedEvent["Emp2ID"];
    selectedEvent["Unidad"] = parseInt(selectedEvent["Unidad"]);
    delete selectedEvent["Emp1ID"];
    delete selectedEvent["Emp2ID"];
    //console.log(selectedEvent);
    this.setState({ selectedEvent });
  };

  updateInfo = (info) => {
    const { data, filters, weekNo, yr } = this.state;
    const id = info.id;
    const i = _.findIndex(data, { idEventos: id });
    data[i].Unidad = info["Unidad"];
    data[i].Emp1 = info["Emp1N"];
    data[i].Emp2 = info["Emp2N"];
    data[i].Emp1ID = info["Emp1"];
    data[i].Emp2ID = info["Emp2"];
    data[i].Comentarios = info["Comentarios"];
    data[i].Inicio_G = info["Inicio_G"];
    data[i].Fin_G = info["Fin_G"];
    data[i].Comentario_G = info["Comentario_G"];

    const dataFilter = data.filter((e) => e.semana === weekNo && e.yr === yr);
    const dataSumm = dataFilter.slice(0, filters.registros);

    this.setState({ data, dataSumm });
    this.showModal();
  };

  handleExport = async () => {
    try {
      if (this.state.user.tipo !== "Paramedico") {
        const { status, data } = await trackPromise(exportEventos());
        if (status === 200) {
          let wb = XLSX.utils.book_new();
          let ws = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(wb, ws, "Eventos");
          XLSX.writeFile(wb, "Eventos.csv");
        }
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const {
      headers,
      dataSumm,
      view,
      layout,
      filters,
      arrWeek,
      arrYr,
      weekNo,
      yr,
      infoModal,
      selectedEvent,
      user,
    } = this.state;

    return (
      <Fragment>
        {infoModal ? (
          <Assignation
            data={selectedEvent}
            onUpdate={this.updateInfo}
            closeModal={this.showModal}
            user={user?.tipo}
          />
        ) : null}
        <LoadingComp />
        <NavBar page={this.props.match.path} />
        <div className="p-5 h-screen">
          <h1 className="block text-center text-3xl text-green-700">
            Módulo de Eventos
          </h1>
          <InfoOptions
            view={view}
            filters={filters}
            pholder={"Evento"}
            btnText={"Evento"}
            arrYr={arrYr}
            arrWeek={arrWeek}
            weekNo={weekNo}
            yr={yr}
            btnColor={"btn-secondary"}
            changeFilter={this.changeFilter}
            handleExport={this.handleExport}
            changeDate={this.changeDate}
          />
          <InfoTable
            headColor={"bg-green-100"}
            evento={true}
            headers={headers}
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            sortColumn={this.sortColumn}
            view={view}
          />
          <InfoGrid
            dataSumm={dataSumm}
            loadInfo={this.loadInfo}
            layout={layout}
            view={view}
          />
        </div>
      </Fragment>
    );
  }
}

export default Pagos;

import React, { Component } from "react";
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

//Protected Route
import ProtectedRoute from "./components/tools/protectedRoute";
import auth from "./services/authService";

//Import Main Components
import Index from "./components/main/index";
import Llamadas from "./components/main/llamadas";
import Fraps from "./components/main/fraps";
import Calendario from "./components/main/calendario";
import Pagos from "./components/main/pagos";
import Cotizaciones from "./components/main/cotizaciones";
import PdfFile from "./components/views/pdfFile";
import Login from "./components/forms/login";
import Logout from "./components/main/logout";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <ProtectedRoute path="/index" component={Index} user={user} />
            <ProtectedRoute path="/llamadas" component={Llamadas} user={user} />
            <ProtectedRoute
              path="/fraps/pdf/:id"
              component={PdfFile}
              user={user}
            />
            <ProtectedRoute path="/fraps" component={Fraps} user={user} />
            <ProtectedRoute
              path="/calendario"
              component={Calendario}
              user={user}
            />
            <ProtectedRoute path="/pagos" component={Pagos} user={user} />
            <ProtectedRoute
              path="/cotizaciones"
              component={Cotizaciones}
              user={user}
            />
            <Redirect to="/index" />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;

import http from "./httpService";

export function getCalls() {
  return http.get("/llamadas");
}

export function exportCalls() {
  return http.get("/llamadas/excel");
}

export function postCall(data) {
  const body = { ...data };
  delete body.tipo;
  body["Status"] = "ACTIVO";
  return http.post("/llamadas", body);
}

export function updateCall(body) {
  return http.put("/llamadas", body);
}

import React from "react";
import { Tooltip } from "react-tippy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoOptions = (props) => {
  const changeFilter = (e) => {
    props.changeFilter(e);
  };

  return (
    <React.Fragment>
      <div className="flex flex-wrap gap-y-3 pt-4 justify-between">
        <div className="w-full h-full md:w-1/6 px-2 flex space-x-2 pb-1">
          {(["Administracion", "Facturacion"].includes(props.user?.tipo) &&
            props.btnText === "Nuevo Frap") ||
          props.btnText === "Evento" ? null : (
            <button
              className={props.btnColor + " w-full md:w-1/3 text-center"}
              onClick={() => props.showModal()}
            >
              <Tooltip title={props.btnText} position="top">
                <FontAwesomeIcon icon={["fas", "plus-circle"]} size="lg" />
              </Tooltip>
            </button>
          )}
          {props.validation &&
          (props.user?.tipo === "Administracion" ||
            props.user?.tipo === "Gerencia") ? (
            <button
              className={
                "w-full md:w-1/3 text-center " +
                (props.validationMode ? "btn-purple" : "btn-success")
              }
              onClick={() => props.setValidation()}
            >
              <Tooltip
                title={props.validationMode ? "Regresar" : "Seleccionar"}
                position="top"
              >
                <FontAwesomeIcon
                  icon={[
                    "fas",
                    props.validationMode ? "arrow-alt-circle-left" : "tasks",
                  ]}
                  size="lg"
                />
              </Tooltip>
            </button>
          ) : null}
          {props.validationMode ? (
            <button
              className="btn-success w-full md:w-1/3 text-center"
              onClick={() => props.handleValidation()}
            >
              <Tooltip title="Validar" position="top">
                <FontAwesomeIcon icon={["fas", "user-check"]} size="lg" />
              </Tooltip>
            </button>
          ) : null}
          <button
            className="btn-orange w-full md:w-1/3 text-center"
            onClick={() => props.handleExport()}
          >
            <Tooltip title={"Exportar"} position="top">
              <FontAwesomeIcon icon={["fas", "download"]} size="lg" />
            </Tooltip>
          </button>
        </div>
        {props.btnText === "Evento" ? (
          <div className="w-full md:w-3/12 flex md:block place-content-center">
            <select
              name="yrFilter"
              value={props.yr}
              className="w-5/12 text-xs text-input mr-1"
              onChange={props.changeDate}
            >
              {props.arrYr.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
            <select
              name="weekFilter"
              value={props.weekNo}
              className="w-5/12 text-xs text-input"
              onChange={props.changeDate}
            >
              {props.arrWeek.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="w-full md:w-1/12 self-center hidden md:block">
            <button
              className={
                "button-view rounded-l " +
                (props.view === "table" ? "active-view" : "deactive-view")
              }
              onClick={() => props.changeView("table")}
            >
              <Tooltip title="Tabla" position="top">
                <FontAwesomeIcon icon={["fas", "border-all"]} />
              </Tooltip>
            </button>
            <button
              className={
                "button-view rounded-r " +
                (props.view === "table" ? "deactive-view" : "active-view")
              }
              onClick={() => props.changeView("grid")}
            >
              <Tooltip title="Cuadrícula" position="top">
                <FontAwesomeIcon icon={["fas", "th"]} />
              </Tooltip>
            </button>
          </div>
        )}
        <div className="w-full md:w-1/3">
          <input
            type="text"
            className="text-input rounded-full text-center"
            placeholder={props.pholder}
            name="busqueda"
            value={props.filters.busqueda}
            onChange={changeFilter}
          />
          <span className="z-0 -ml-6 mt-1">
            <FontAwesomeIcon
              className="text-blue-700"
              icon={["fas", "search"]}
            />
          </span>
        </div>
        <div className="w-full md:w-1/6 md:text-right">
          <label htmlFor="">Registros: </label>
          <select
            name="registros"
            className="md:w-1/3 text-center text-input text-sm"
            value={props.filters.registros}
            onChange={changeFilter}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InfoOptions;

import React from "react";
import FormRules from "./formRules";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class HistClinica extends FormRules {
  constructor(props) {
    super(props);
    this.state = {
      data: props.prevDetails
        ? props.prevDetails
        : {
            Impresion: "",
            Manejo1: "",
            Indicaciones1: "",
            alergias: "",
            enfermedadesprev: "",
            medicamentos: "",
            uingesta: "",
            descripcionev: "",
          },
      errors: {},
      //listasTx: props.listasTx ? props.listasTx : [],
      //*Agregar lista en caso de que sea necesario desde la BD.
    };
  }

  schema = {
    Impresion: Joi.optional().label("Impresión"),
    Manejo1: Joi.optional().label("Manejo #1"),
    Indicaciones1: Joi.optional().label("Indicaciones #1"),
    alergias: Joi.optional(),
    enfermedadesprev: Joi.optional(),
    medicamentos: Joi.optional(),
    uingesta: Joi.optional(),
    descripcionev: Joi.optional(),
  };

  sendData = () => {
    const data = { ...this.state.data };
    this.props.onSubmit(data);
    this.props.onClose();
  };

  render() {
    const lw2 = "3/12"; //Label width for two in same row
    const iw2 = "9/12"; //Input width for two in same row
    return (
      <div className="modal-back z-30">
        <div className="px-2">
          <div className="modal-box md:w-10/12 top-10">
            <span className="modal-title">
              <FontAwesomeIcon
                className={"mr-2 cursor-pointer text-green-500"}
                icon={["far", "save"]}
                onClick={this.sendData}
              />
              Historia Clínica
              <FontAwesomeIcon
                className="ml-2 text-red-500 cursor-pointer"
                icon={["far", "times-circle"]}
                onClick={() => this.props.onClose()}
              />
            </span>
            <form className="pt-3 flex flex-wrap">
              <div className="w-full md:w-1/2 py-1 px-3 space-y-3">
                {this.renderTextArea(
                  "alergias",
                  "Alergias",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "2"
                )}
                {this.renderTextArea(
                  "enfermedadesprev",
                  "Enfermedades previas",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "2"
                )}
                {this.renderTextArea(
                  "medicamentos",
                  "Medicamentos",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "2"
                )}
                {this.renderTextArea(
                  "uingesta",
                  "Última ingesta",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "2"
                )}
                {this.renderTextArea(
                  "descripcionev",
                  "Descripción del evento",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "4"
                )}
              </div>
              <div className="w-full md:w-1/2 py-1 px-3 space-y-3">
                {this.renderTextArea(
                  "Impresion",
                  "Impresión diagnóstica",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "3"
                )}
                {this.renderTextArea(
                  "Manejo1",
                  "Manejo",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "6"
                )}
                {this.renderTextArea(
                  "Indicaciones1",
                  "Indicaciones",
                  "text",
                  "",
                  lw2,
                  iw2,
                  "6"
                )}
              </div>
              <div className="flex space-x-4 justify-center w-full pt-3">
                <div className="w-1/2 md:w-2/12">
                  <button
                    className="btn-success w-full"
                    type="button"
                    onClick={this.sendData}
                  >
                    Guardar
                  </button>
                </div>
                <div className="w-1/2 md:w-2/12">
                  <button
                    className="btn-red w-full"
                    type="button"
                    onClick={() => this.props.onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default HistClinica;

import React from "react";

const InfoGrid = (props) => {
  return (
    <React.Fragment>
      <div
        className={
          "py-5 h-full block " + (props.view === "grid" ? "" : "md:hidden")
        }
      >
        <div className="shadow overflow-auto border border-gray-100 rounded-md h-2/3 flex flex-wrap p-1">
          {props.dataSumm.map((e, i) => (
            <div
              className="w-full h-1/3 md:w-1/4 p-0.5"
              key={i}
              onClick={() => props.loadInfo(e)}
            >
              <div
                className={
                  "shadow rounded h-full px-2 text-xs 2xl:text-sm flex cursor-pointer label-mobile-" +
                  e.Status.toLowerCase() +
                  " " +
                  (props.validationMode
                    ? props.validationArray.indexOf(e.idTransaction) === -1
                      ? ""
                      : "border-blue-500"
                    : "")
                }
              >
                <div className="w-full self-center">
                  <div className="block flex justify-between">
                    <span className="text-left ">
                      {props.layout[0].label + e[props.layout[0].name]}
                    </span>
                    <span className="text-right ">
                      {props.layout[1].label + e[props.layout[1].name]}
                    </span>
                  </div>
                  <div className="block mt-1 text-center">
                    <span className="">
                      {props.layout[2].label + e[props.layout[2].name]}
                    </span>
                  </div>
                  <div className="block mt-1 text-center">
                    <span className="">
                      {props.layout[3].label + e[props.layout[3].name]}
                    </span>
                  </div>
                  <div className="block mt-1 flex  justify-between">
                    <span className="">
                      {props.layout[4].label +
                        (e[props.layout[4].name] === null
                          ? ""
                          : e[props.layout[4].name])}
                    </span>
                    <span className="text-right ">
                      {props.layout[5].label + e[props.layout[5].name]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InfoGrid;

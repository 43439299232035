import React from "react";
import FormRules from "./formRules";
import _ from "lodash";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
//Importar Componentes
import LoadingComp from "../tools/loadingComp";
//Importar Servicios
import { getListsTx } from "./../../services/listaService";
import { updateEvento } from "./../../services/eventosService";

class PagoUpdate extends FormRules {
  state = {
    data: {
      Costo: 0,
      Costo_Extra: 0,
      HourDiff: 0,
      Status_Pago: "",
      Status_Factura: "",
      Num_Factura: "",
      Comentarios: "",
      tblpagos: [
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
        {
          fecha: "",
          monto: 0,
          movimiento: "",
          forma: "",
        },
      ],
      Validado: 0,
      validadoger: 0,
      updateId: 0,
      user: "",
    },
    errors: {},
    dw: { label: "1/4", input: "3/4" },
    listasTx: [],
  };

  schema = {
    Costo: Joi.number().optional().label("Costo"),
    Costo_Extra: Joi.number().optional().label("Costo Extra"),
    HourDiff: Joi.number().optional().label("Horas"),
    Status_Pago: Joi.string().required().label("Status Pago"),
    Status_Factura: Joi.optional(),
    Num_Factura: Joi.optional(),
    Comentarios: Joi.optional(),
    tblpagos: Joi.optional(),
    Validado: Joi.optional(),
    validadoger: Joi.optional(),
    totalFinal: Joi.optional(),
    totalFinalIVA: Joi.optional(),
    Fecha_Admin: Joi.optional(),
    fecha_gerencia: Joi.optional(),
  };

  componentDidMount = async () => {
    try {
      const { status: statusTx, data: listasTx } = await trackPromise(
        getListsTx()
      );
      if (statusTx === 200) {
        const data = { ...this.props.data };
        const updateId = data["idEventos"];
        delete data.idEventos;
        const totalFinal =
          data["Costo"] * data["HourDiff"] + data["Costo_Extra"];
        data["tblpagos"] =
          data["tblpagos"] === "" || data["tblpagos"] === null
            ? [
                {
                  fecha: "",
                  monto: 0,
                  movimiento: "",
                  forma: "",
                },
                {
                  fecha: "",
                  monto: 0,
                  movimiento: "",
                  forma: "",
                },
                {
                  fecha: "",
                  monto: 0,
                  movimiento: "",
                  forma: "",
                },
                {
                  fecha: "",
                  monto: 0,
                  movimiento: "",
                  forma: "",
                },
              ]
            : JSON.parse(data["tblpagos"]);
        data["totalFinal"] = totalFinal.toFixed(1);
        data["totalFinalIVA"] = (totalFinal * 1.16).toFixed(1);
        this.setState({
          data,
          listasTx,
          updateId,
          user: this.props.user?.tipo,
        });
      }
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  handleTableChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    let name = input.name;
    const index = parseInt(name.charAt(name.length - 1));
    name = name.slice(0, -1);
    data["tblpagos"][index][name] = input.value;
    this.setState({ data });
  };

  doSubmit = async () => {
    try {
      const { data, updateId } = this.state;
      const body = {
        id: updateId,
        Status_Pago: data["Status_Pago"],
        tblpagos: JSON.stringify(data["tblpagos"]),
        Validado: data["Validado"],
        validadoger: data["validadoger"],
        Status_Factura: data["Status_Factura"],
        Num_Factura: data["Num_Factura"],
        Comentarios: data["Comentarios"],
      };
      const res = await trackPromise(updateEvento(body));
      if (res.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Actualización Completa",
          showConfirmButton: false,
          timer: 2200,
          toast: true,
        });
        this.props.onUpdate(body);
      }
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ocurrió un error cargando los datos",
        showConfirmButton: false,
        timer: 1800,
        toast: true,
      });
    }
  };

  render() {
    const { data, listasTx } = this.state;
    const formaPago = _.orderBy(
      _.filter(listasTx, { lista: "forma_pago" }),
      "val",
      "asc"
    ); //Para sacar todas las formas de pago
    const tblArr = [...Array(4).keys()]; //Para la tabla de pagos
    const tblP = _.keys(data.tblpagos[0]); //Sacamos las llaves para crear la tabla
    return (
      <React.Fragment>
        <div className="modal-back z-20">
          <LoadingComp />
          <div className="px-2">
            <div className="modal-box top-2 mb-5">
              <span className="modal-title">Pagos del Evento</span>
              <div className="flex flex-wrap w-full gap-y-3 py-2">
                <div className="w-full md:w-2/6">
                  {this.renderInputH(
                    data["Status_Factura"] === "SIN FACTURA"
                      ? "totalFinal"
                      : "totalFinalIVA",
                    "Total",
                    "text",
                    "",
                    "3/6",
                    "3/6",
                    false,
                    true
                  )}
                </div>
                <div className="w-full md:w-4/6 px-2">
                  {this.renderSelect(
                    "Status_Pago",
                    "Estatus Pago",
                    "text",
                    _.orderBy(
                      _.filter(listasTx, { lista: "estatus_pago" }),
                      "val",
                      "asc"
                    ),
                    "4/12",
                    "8/12",
                    true
                  )}
                </div>
              </div>
              <div className="flex flex-wrap w-full gap-y-3 py-2">
                <div className="w-full md:w-4/6">
                  {this.renderSelect(
                    "Status_Factura",
                    "Estatus Factura",
                    "text",
                    [
                      {
                        vis: "FACTURADO",
                        val: "FACTURADO",
                      },
                      {
                        vis: "POR FACTURAR",
                        val: "POR FACTURAR",
                      },
                      {
                        vis: "SIN FACTURA",
                        val: "SIN FACTURA",
                      },
                    ],
                    "4/12",
                    "8/12"
                  )}
                </div>
                <div className="w-full md:w-2/6 px-2">
                  {this.renderInputH(
                    "Num_Factura",
                    "Factura",
                    "text",
                    "",
                    "3/6",
                    "3/6"
                  )}
                </div>
              </div>
              <div className="w-full flex py-1">
                <table className="table-fixed text-xs shadow-md w-full">
                  <thead>
                    <tr>
                      <th className="w-3/12 border p-1">Fecha Pago</th>
                      <th className="w-3/12 border p-1">Monto</th>
                      <th className="w-3/12 border p-1">Recibo/Mov</th>
                      <th className="w-3/12 border p-1">Forma Pago</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tblArr.map((index, i) => (
                      <tr key={i}>
                        {tblP.map((name, i) => (
                          <td className="border p-1" key={name + i}>
                            <div className="flex flex-wrap w-full">
                              {name === "forma" ? (
                                <select
                                  className="w-full text-xs text-input"
                                  name={name + index}
                                  value={data["tblpagos"][index][name]}
                                  onChange={this.handleTableChange}
                                  disabled={
                                    data["Validado"] === 1
                                      ? true
                                      : this.props.user?.tipo ===
                                        "Administracion"
                                      ? true
                                      : false
                                  }
                                >
                                  <option value=""></option>
                                  {formaPago.map((forma, i) => (
                                    <option
                                      key={forma.val + i}
                                      value={forma.val}
                                    >
                                      {forma.vis}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  className="w-full text-xs text-input"
                                  type={
                                    name === "movimiento"
                                      ? "text"
                                      : name === "monto"
                                      ? "number"
                                      : "date"
                                  }
                                  name={name + index}
                                  value={
                                    name === "monto" &&
                                    data["tblpagos"][index][name] === 0
                                      ? ""
                                      : data["tblpagos"][index][name]
                                  }
                                  onChange={this.handleTableChange}
                                  disabled={
                                    data["Validado"] === 1
                                      ? true
                                      : this.props.user?.tipo ===
                                        "Administracion"
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr className="bg-gray-100">
                      <td
                        className="border p-1 text-center font-semibold"
                        colSpan="4"
                      >
                        {"Total Pagado: $" +
                          ((data["tblpagos"][0]["monto"] === ""
                            ? 0
                            : parseInt(data["tblpagos"][0]["monto"])) +
                            (data["tblpagos"][1]["monto"] === ""
                              ? 0
                              : parseInt(data["tblpagos"][1]["monto"])) +
                            (data["tblpagos"][2]["monto"] === ""
                              ? 0
                              : parseInt(data["tblpagos"][2]["monto"])) +
                            (data["tblpagos"][3]["monto"] === ""
                              ? 0
                              : parseInt(data["tblpagos"][3]["monto"])))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex py-2 w-full">
                {this.renderTextArea(
                  "Comentarios",
                  "Comentarios",
                  "text",
                  "",
                  "1/5",
                  "4/5",
                  "2"
                )}
              </div>

              <div className="flex py-2 w-full">
                <div className="w-full md:w-1/2">
                  {this.renderToggle("Validado", "Validación Administración")}
                </div>
                <div className="w-full md:w-1/2">
                  {this.renderToggle("validadoger", "Validación Gerencia")}
                </div>
              </div>

              <div className="flex space-x-4 justify-center w-full pt-3">
                <div className="w-1/2 md:w-1/6">
                  <button
                    className="btn-success w-full"
                    type={"button"}
                    onClick={this.handleSubmit}
                  >
                    Guardar
                  </button>
                </div>
                <div className="w-1/2 md:w-1/6">
                  <button
                    className="btn-red w-full"
                    type="button"
                    onClick={() => this.props.closeModal()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PagoUpdate;

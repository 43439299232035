import React, { useState } from "react";
//import { HamburgerSpring } from "react-animated-burgers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import auth from "../../services/authService";
import logo from "./../../assets/img/logo.png";

const NavBar = (props) => {
  const [menu, setMenu] = useState(false);
  const [user, setUser] = useState({});

  React.useEffect(() => {
    const user = auth.getCurrentUser();
    setUser(user);
  }, []);

  return (
    <React.Fragment>
      <nav className="bg-gray-200 text-black shadow-lg">
        <div className="max-w-8xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex space-x-7">
              <div className="pt-4">
                <a href="/">
                  {
                    <img
                      src={logo}
                      alt="Logo LifeSupport"
                      style={{ height: "30px", width: "110px" }}
                    />
                  }
                  {/*<span className="flex items-center py-4 px-2">
                    <FontAwesomeIcon
                      className="social-network-mini text-blue-700"
                      icon={["fas", "ambulance"]}
                      size="lg"
                    />
  </span>*/}
                </a>
              </div>
              {/*<!-- Primary Navbar items -->*/}
              <div className="hidden md:flex items-center space-x-10">
                <a
                  className={
                    "nav-item " +
                    (props.page === "/llamadas" ? "text-blue-500" : null)
                  }
                  href="/llamadas"
                >
                  Llamadas
                </a>
                <a
                  className={
                    "nav-item " +
                    (props.page === "/fraps" ? "text-blue-500" : null)
                  }
                  href="/fraps"
                >
                  Fraps
                </a>
                <a
                  className={
                    "nav-item " +
                    (props.page === "/cotizaciones" ? "text-blue-500" : null)
                  }
                  href="/cotizaciones"
                >
                  Cotizaciones
                </a>
                {user?.tipo !== "Paramedico" ? (
                  <React.Fragment>
                    <a
                      className={
                        "nav-item " +
                        (props.page === "/calendario" ? "text-blue-500" : null)
                      }
                      href="/calendario"
                    >
                      Eventos
                    </a>
                  </React.Fragment>
                ) : null}
                {user?.tipo !== "Paramedico" ? (
                  <React.Fragment>
                    <a
                      className={
                        "nav-item " +
                        (props.page === "/pagos" ? "text-blue-500" : null)
                      }
                      href="/pagos"
                    >
                      Pagos
                    </a>
                  </React.Fragment>
                ) : null}
                {user?.tipo === "Admin" && (
                  <React.Fragment>
                    <span className="nav-item" onClick={() => props.onUsers()}>
                      Usuarios
                    </span>
                  </React.Fragment>
                )}
                <a
                  className="nav-item text-red-600 hover:text-red-700"
                  href="/logout"
                >
                  Salir
                </a>
              </div>
            </div>
            {/*<!-- Secondary Navbar items -->*/}
            <div className="hidden md:flex items-center space-x-3 ">
              {props.page === "/index" &&
              (user?.tipo === "Admin" || user?.tipo === "Gerencia") ? (
                <span
                  className="py-2 px-2 font-medium text-gray-500 rounded hover:text-blue-700 transition duration-300 cursor-pointer"
                  onClick={() => props.onSettings()}
                >
                  <FontAwesomeIcon
                    className="social-network-mini"
                    icon={["fas", "sliders-h"]}
                    size="lg"
                  />
                </span>
              ) : null}
            </div>
            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <span
                className="outline-none mobile-menu-button nav-item"
                onClick={() => setMenu(!menu)}
              >
                <FontAwesomeIcon
                  className="social-network-mini"
                  icon={["fas", "plus"]}
                  size="lg"
                />
              </span>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          className={
            (menu ? null : "hidden") +
            " py-1 bg-gray-200 border-t-2 border-blue-500 divide-y divide-blue-500"
          }
        >
          <div>
            <a href="/llamadas" className="w-full">
              <span
                className={
                  "nav-item-mobile block " +
                  (props.page === "/llamadas" ? "text-blue-500" : null)
                }
              >
                Llamadas
              </span>
            </a>
          </div>

          <div>
            <a href="/fraps" className="w-full">
              <span
                className={
                  "nav-item-mobile block " +
                  (props.page === "/fraps" ? "text-blue-500" : null)
                }
              >
                Fraps
              </span>
            </a>
          </div>
          <div>
            <a href="/cotizaciones">
              <span className="nav-item-mobile block">Cotizaciones</span>
            </a>
          </div>
          {user?.tipo !== "Paramedico" ? (
            <div>
              <a href="/calendario">
                <span
                  className={
                    "nav-item-mobile block " +
                    (props.page === "/pagos" ? "text-blue-500" : null)
                  }
                >
                  Eventos
                </span>
              </a>
            </div>
          ) : null}
          {user?.tipo !== "Paramedico" ? (
            <div>
              <a href="/pagos">
                <span
                  className={
                    "nav-item-mobile block " +
                    (props.page === "/pagos" ? "text-blue-500" : null)
                  }
                >
                  Pagos
                </span>
              </a>
            </div>
          ) : null}
          {props.page === "/index" &&
          (user?.tipo === "Administracion" || user?.tipo === "Gerencia") ? (
            <span
              className="nav-item-mobile block"
              onClick={() => props.onSettings()}
            >
              Catálogos
            </span>
          ) : null}
          <a
            className="nav-item block text-red-600 hover:text-red-700"
            href="/logout"
          >
            Salir
          </a>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;

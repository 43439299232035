import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { usePromiseTracker } from "react-promise-tracker";

const LoadingComp = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <div
      className={
        (promiseInProgress || props.disp ? "flex" : "hidden") +
        " absolute z-30 h-full w-full bg-gray-300 bg-opacity-75 rounded"
      }
    >
      <div className="m-auto">
        <Player
          autoplay
          loop
          src={
            "https://assets2.lottiefiles.com/private_files/lf30_e1eyyb7a.json"
          }
          style={{ height: "13rem", width: "13rem" }}
        />
      </div>
    </div>
  );
};

export default LoadingComp;
